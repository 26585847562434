import React from "react";
import { Input } from "reactstrap";

import { LANGUAGE_CONSTANTS } from "shared/language-constants";
import { getLocaleMessage } from "utility/Utils";

const { RESPONSES_PLACEHOLDER_TEXT } = LANGUAGE_CONSTANTS;

const FreeTextResponse = ({ intl, value }) => {
  return (
    <div className="response-field">
      <div className="form-group">
        <Input
          id="freeText"
          name="freeText"
          rows="3"
          type="textarea"
          value={value || ""}
          placeholder={getLocaleMessage(
            intl,
            RESPONSES_PLACEHOLDER_TEXT.WRITE_TEXT_RESPONSE
          )}
          disabled
          onChange={() => {}}
        />
      </div>
    </div>
  );
};

export default FreeTextResponse;
