import { LANGUAGE_CONSTANTS } from "shared/language-constants"

const { ACTIVITIES } = LANGUAGE_CONSTANTS

export const activityDetailCardConfig = {
  col1: {
    md: 6,
    columnConfig: [
      {
        labelLocaleId: ACTIVITIES.RISK_LEVEL,
        keyName: "riskLevel",
        isShowNA: true
      },
      {
        labelLocaleId: ACTIVITIES.ASSEMBLY,
        keyName: "assembly",
        isShowNA: true
      },
      {
        labelLocaleId: ACTIVITIES.ASSEMBLY_TYPE,
        keyName: "assemblyType",
        isShowNA: true
      },
      {
        labelLocaleId: ACTIVITIES.MAIN_COMPONENT,
        keyName: "component",
        isShowNA: true
      }
    ]
  },
  col2: {
    md: 6,
    columnConfig: [
      {
        labelLocaleId: ACTIVITIES.SUB_COMPONENT,
        keyName: "subComponent",
        isShowNA: true
      },
      {
        labelLocaleId: ACTIVITIES.MANUFACTURER,
        keyName: 'manufacturer',
        isShowNA: true
      },
      {
        labelLocaleId: ACTIVITIES.BRAND,
        keyName: 'brand',
        isShowNA: true
      }
    ]
  }
}
