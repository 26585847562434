import React, { useState } from 'react'
import { ChevronDown, ChevronUp } from "react-feather"

import { LANGUAGE_CONSTANTS } from "shared/language-constants"
import { getFormattedDate } from "utility/Utils"
import { LocaleMessage } from 'views/components/locale-message'

const { ACTIONS_PAGE } = LANGUAGE_CONSTANTS

const CustomNotes = ({ item: {
    note,
    noteInsertedByName,
    noteInsertedDate
} }) => {
    const [isExpanded, setExpanded] = useState(true)

    return (
        <div className='primary-notes'>
            <div className='additional-note-header'>
                <h5 className='additional-note-title'>
                    <LocaleMessage id={ACTIONS_PAGE.PRIMARY_NOTE} />
                </h5>
                <span onClick={() => setExpanded(!isExpanded)}>
                    {isExpanded ? <ChevronUp /> : <ChevronDown />}
                </span>
            </div>

            {isExpanded && <div className='additional-note mb-md-2'>
                <h5 className='title'>
                    <LocaleMessage id={ACTIONS_PAGE.ATTACHED_NOTE} />
                    <span>{!!noteInsertedDate && `${getFormattedDate(noteInsertedDate)} - `} {!!noteInsertedByName && noteInsertedByName} </span>
                </h5>
                <p className='note-details'>{note}</p>
            </div>}
        </div>
    )
}

export default CustomNotes
