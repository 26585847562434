import { getDataApi } from "services/api"
import config from "../../config"

/**
 * @method getTradeContactToReassign
 *
 */
export const getTradeContactToReassign = (data) => {
  const { url } = config.getTradeContactToReassign
  return getDataApi(url(data))
}

export default getTradeContactToReassign
