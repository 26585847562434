import React, { useCallback, useEffect, useState } from "react"
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Container } from 'reactstrap'

import { ROUTES } from "shared/constants"
import * as actions from 'store/actions'
import localeMessageWrapper from "views/components/locale-message"
import DetailView from "./DetailView"
import Header from "./Header"
import { getActionDetailsSearchParams, getOverdueTime } from "./helpers"
import { ACTION_STATUS_VALUE_TYPES } from "../config"
import logo from "../../../../assets/images/logo.svg"

const ActionDetails = ({
  intl
}) => {
  const history = useHistory()

  const [isShowDetailView, setShowDetailView] = useState(false)
  const dispatch = useDispatch()

  const [actionCardDetail, setActionCardDetail] = useState(null)
  const [isFetching, setFetching] = useState(true)
  const [overdueTime, setOverdueTime] = useState("")

  const handleGoToActionList = useCallback(() => {
    history.replace(ROUTES.actionsOverview)
  }, [])

  const handleFetchActionDetails = useCallback((actionId) => {
    if (!actionId) {
      return
    }

    dispatch(actions.getActionDetailRequest({ actionId }, (res) => {
      if (res) {
        if (res.dueDate && res.actionStatus !== ACTION_STATUS_VALUE_TYPES.COMPLETED) {
          const dueTime = getOverdueTime(res.dueDate, intl)
          setOverdueTime(dueTime)
        }
        setActionCardDetail({ actionId, ...res })
      } else {
        handleGoToActionList()
      }
      setFetching(false)
    }))

  }, [handleGoToActionList])

  useEffect(() => {
    const {
      isViewDetail
    } = getActionDetailsSearchParams()
    const queryParams = new URLSearchParams(window.location.search)
    const actionId = queryParams.get("actionId")
    if (isViewDetail || actionId) {
      handleFetchActionDetails(actionId)
      setShowDetailView(true)
    } else {
      handleGoToActionList()
    }
  }, [window.location.search])

  return (
    <>
      <div className="header-logo">
        <Container>
          <img src={logo} alt="logo" />
        </Container>
      </div>
      <Container>
        <Header taskOrActivityName={actionCardDetail?.taskOrActivityName} />
      </Container>
      {isShowDetailView && (
        <DetailView
          isSubGoBackRequired //Todo: check props
          actionCardDetail={actionCardDetail}
          isFetching={isFetching}
          overdueTime={overdueTime}
          onGoBack={handleGoToActionList}
        />
      )}
    </>
  )
}

export default localeMessageWrapper(ActionDetails)
