import { getSearchQuery } from '../../../config'

const config = {
  getChecklistChildrenList: {
    method: 'GET',
    url: ({
      actionId = 0,
      onlyFailedActivity = false,
      pageNumber,
      pageSize,
    }) => {
      let searchString = `${getSearchQuery("WFActionId", actionId, !!actionId)}${getSearchQuery("OnlyFailedActivity", onlyFailedActivity)}${getSearchQuery("PageSize", pageSize)}${getSearchQuery("PageNumber", pageNumber)}`
      return `api/FSPublic/GetCheckListChildren${searchString}`
    }
  },
  getTemplateTrainingActivityChildDetail: {
    method: 'GET',
    url: ({ checkpointId, checkpointVersionId = 1 }) => `api/FSPublic/${checkpointId}/${checkpointVersionId}/Trainingdetail`
  },
  getCheckpointChildDetail: {
    method: 'GET',
    url: ({ checkpointId, checkpointVersionId = 1 }) => `api/FSPublic/${checkpointId}/${checkpointVersionId}/childdetail`
  },
}

export default config
