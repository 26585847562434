import React, { useCallback, useState } from "react"
import { useDispatch } from "react-redux"

import * as actions from "store/actions"
import { toDataURL } from "utility/Utils"
import { ResourceCenterUploader } from "views/components"
import ActionsView from "./ActionsView"
import Details from "./Details"
import { ACTION_TYPES, VERIFY_BY_SUPERINTENDENT_RESPONSE_TYPES } from "../../../config"

const VerifyBySuperindendent = ({
  item,
  isOverviewCardView,
  onViewMore = () => { },
  onRefreshActionList = () => { }
}) => {
  const dispatch = useDispatch()

  const [selectedActionType, setSelectedActionType] = useState("")
  const [isShowSubmitActionView, setShowSubmitActionView] = useState(false)
  const [isShowAddNoteView, setShowAddNoteView] = useState(false)
  const [noteDetail, setNoteDetail] = useState("")
  const [selectedPhotos, setSelectedPhotos] = useState([])
  const [selectedPhotosDetail, setSelectedPhotosDetail] = useState([])
  const [isShowUploader, setShowUploader] = useState(false)
  const [isShowFullActivityName, setShowFullActivityName] = useState(false)
  const [isShowFullNotes, setShowFullNotes] = useState(false)

  const handleResetState = useCallback(() => {
    setSelectedActionType("")
    setShowSubmitActionView(false)
    setShowAddNoteView(false)
    setNoteDetail("")
    setSelectedPhotos([])
    setSelectedPhotosDetail([])
  }, [])

  const handleUpdateVerificationStatus = useCallback((data) => {
    const payload = {
      wfActionId: item.actionId,
      ...data
    }
    dispatch(
      actions.updateVerifyBySuperintendentRequest(payload, (res) => {
        if (res) {
          onRefreshActionList()
          handleResetState()
        }
      })
    )
  }, [
    item,
    handleResetState,
    onRefreshActionList
  ])

  const handleShowConfirmActionView = useCallback((value) => {
    setSelectedActionType(value || "")
  }, [])

  const handleViewMore = useCallback(() => {
    onViewMore(item.actionId)
  }, [item, onViewMore])

  const handleConfirmAction = useCallback(() => {
    setShowSubmitActionView(true)
  }, [selectedActionType, handleUpdateVerificationStatus])

  const handleToggleAddNote = useCallback(() => {
    setShowAddNoteView(!isShowAddNoteView)

    //Todo: On hide note view , earlier note needs to be kept or not?
    if (isShowAddNoteView) {
      setNoteDetail("")
    }
  }, [isShowAddNoteView])

  const handleChangeNoteDetail = useCallback((event) => {
    setNoteDetail(event.target.value)
  }, [])

  const handleToggleAddPhoto = useCallback(() => {
    setShowUploader(!isShowUploader)
  }, [isShowUploader])

  const handleSetSelectedFiles = useCallback(
    ({ fileList, customFileDetailList }) => {
      setSelectedPhotos(fileList)
      setSelectedPhotosDetail(customFileDetailList)
    },
    []
  )

  const handleSubmitAndClose = useCallback(async () => {
    if (selectedActionType === ACTION_TYPES.VERIFY_BY_SUPERINTENDENT.APPROVE) {
      handleUpdateVerificationStatus({
        verifyBySuperintendent: VERIFY_BY_SUPERINTENDENT_RESPONSE_TYPES.ACCEPT,
        note: "",
        images: []
      })
    } else {
      const prevPhotos = []
      for (let i = 0; i < selectedPhotos.length; i++) {
        const result = await toDataURL(URL.createObjectURL(selectedPhotos[i]))
        const { fileName, extension, fileDescription } = selectedPhotosDetail[i]
        prevPhotos.push({
          fileName: `${fileName}${extension}`,
          imageContent: result.split(',').pop(),
          imageId: 0,
          fileDescription,
          isDeleted: false
        })
      }
      const payload = {
        verifyBySuperintendent: VERIFY_BY_SUPERINTENDENT_RESPONSE_TYPES.NOT_ACCEPT,
        note: noteDetail,
        images: prevPhotos
      }
      handleUpdateVerificationStatus(payload)
    }
  }, [noteDetail, selectedPhotos, selectedPhotosDetail, handleUpdateVerificationStatus])

  const handleShowFullActivityName = useCallback(() => {
    if (isShowFullActivityName) {
      return
    }
    setShowFullActivityName(true)
  }, [isShowFullActivityName])

  const handleShowFullNotes = useCallback(() => {
    if (isShowFullNotes) {
      return
    }
    setShowFullNotes(true)
  }, [isShowFullNotes])

  return (
    <div className="action-card">
      <Details item={item}
        isShowFullActivityName={isShowFullActivityName}
        isShowFullNotes={isShowFullNotes}
        onShowFullActivityName={handleShowFullActivityName}
        onShowFullNotes={handleShowFullNotes}
      />
      <ActionsView
        actionId={item.actionId}
        actionStatus={item.actionStatus}
        selectedActionType={selectedActionType}
        hasPhotos={item.hasPhotos}
        isOverviewCardView={isOverviewCardView}
        isShowAddNoteView={isShowAddNoteView}
        isShowSubmitActionView={isShowSubmitActionView}
        noteDetail={noteDetail}
        selectedPhotos={selectedPhotos}
        onChangeNoteDetail={handleChangeNoteDetail}
        onConfirmAction={handleConfirmAction}
        onShowConfirmActionView={handleShowConfirmActionView}
        onSubmitAndClose={handleSubmitAndClose}
        onToggleAddNote={handleToggleAddNote}
        onToggleAddPhoto={handleToggleAddPhoto}
        onViewMore={handleViewMore}
      />
      {isShowUploader && (
        <ResourceCenterUploader
          isAllowImageTypeFilesOnly
          initialFileList={selectedPhotos}
          initialCustomFileDetailList={selectedPhotosDetail}
          onSetSelectedFiles={handleSetSelectedFiles}
          onClose={handleToggleAddPhoto}
        />
      )}
    </div>
  )
}

export default VerifyBySuperindendent
