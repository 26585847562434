import React from 'react'
import PropTypes from 'prop-types'

const ErrorMessage = ({ isShow, message, className }) => {
  if (!isShow) {
    return null
  }

  return (
    <p className={`error-msg ${className}`}>
      <span className='text-danger'>
        {message}
      </span>
    </p>
  )
}

ErrorMessage.propTypes = {
  isShow: PropTypes.bool,
  message: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  className: PropTypes.string
}

ErrorMessage.defaultProps = {
  className: ''
}

export default ErrorMessage
