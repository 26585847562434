import React from "react";

import { ACTIVITY_COLLECTION_RESPONSE_TYPES } from "shared/constants";
import localeMessageWrapper from "views/components/locale-message";
import BooleanResponse from "./BooleanResponse";
import ButtonResponse from "./ButtonResponse";
import ChoiceResponse from "./ChoiceResponse";
import DateResponse from "./DateResponse";
import DDLResponse from "./DDLResponse";
import FreeTextResponse from "./FreeTextResponse";
import ForesiteStandard from "./ForesiteStandardResponse";
import NumericResponse from "./NumericResponse";
import PhotoResponse from "./PhotoResponse";
import { getResponseValue } from "../Helpers";

const getComponent = (typeId) => {
  switch (typeId) {
    case ACTIVITY_COLLECTION_RESPONSE_TYPES.BOOLEAN:
      return BooleanResponse;
    case ACTIVITY_COLLECTION_RESPONSE_TYPES.CHOICE_SINGLE_SELECT:
    case ACTIVITY_COLLECTION_RESPONSE_TYPES.CHOICE_MULTI_SELECT:
      return ChoiceResponse;
    case ACTIVITY_COLLECTION_RESPONSE_TYPES.DATE:
      return DateResponse;
    case ACTIVITY_COLLECTION_RESPONSE_TYPES.FREE_TEXT:
      return FreeTextResponse;
    case ACTIVITY_COLLECTION_RESPONSE_TYPES.NUMERIC:
      return NumericResponse;
    case ACTIVITY_COLLECTION_RESPONSE_TYPES.PHOTO:
      return PhotoResponse;
    case ACTIVITY_COLLECTION_RESPONSE_TYPES.DDL_SINGLE_SELECT:
    case ACTIVITY_COLLECTION_RESPONSE_TYPES.DDL_MULTI_SELECT:
      return DDLResponse;
    case ACTIVITY_COLLECTION_RESPONSE_TYPES.FORESITE_STANDARD:
      return ForesiteStandard;
    case ACTIVITY_COLLECTION_RESPONSE_TYPES.BUTTON:
      return ButtonResponse;
    default:
      return null;
  }
};

const ResponseControls = ({
  intl,
  responseQuestion,
  value,
  responseClassName = "",
}) => {
  const Component = getComponent(responseQuestion.responseTypeId);

  if (!Component) {
    return null;
  }

  const responseValue = getResponseValue({
    value,
    responseTypeId: responseQuestion.responseTypeId,
    responseNumericTypeId: responseQuestion.responseNumericTypeId,
  });

  return (
    <Component
      {...responseQuestion}
      intl={intl}
      responseClassName={responseClassName}
      value={responseValue}
      otherOption={!!value ? value.otherOption || "" : ""}
    />
  );
};

export default localeMessageWrapper(ResponseControls);
