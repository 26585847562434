import cx from "classnames"
import React from "react"

import SectionTab from "./SectionTab"

const OverviewSectionTabs = ({
  activeTabId = 0,
  tabsList = [],
  onChangeTab = () => { },
  isBorderBottomRequired = true,
  count = {}
}) => {
  return (
    <div
      className={cx("section-tabs", {
        "border-bottom": isBorderBottomRequired
      })}
    >
      {tabsList.map((tab) => {
        return (
          <SectionTab
            key={`section-tab-${tab.name}`}
            isActiveTab={activeTabId === tab.id}
            tab={tab}
            onChangeTab={onChangeTab}
            count={count[tab.countId]}
          />
        )
      })}
    </div>
  )
}

export default OverviewSectionTabs
