import { takeLatest, call } from "redux-saga/effects"

import * as apiServices from "services/api/audit-trail"
import * as actions from "../../actions"

/** getAuditTrailModal API **/
function* getAuditTrailModalData(action) {
  try {
    const response = yield call(apiServices.getAuditTrailModal, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

export function* auditTrailSagas() {
  yield takeLatest(actions.GET_AUDIT_TRAIL_MODAL_DATA, getAuditTrailModalData)
}

export default auditTrailSagas