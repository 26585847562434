

import cx from 'classnames'
import React, { useCallback, useState } from "react"
import { Container } from "reactstrap"
import { ChevronUp, ChevronDown } from 'react-feather'
import { useDispatch, useSelector } from "react-redux"

import { LANGUAGE_CONSTANTS } from "shared/language-constants"
import * as actions from "store/actions"
import { getLocaleMessage, toDataURL } from "utility/Utils"
import { OverviewSectionTabs, ObservationDetails } from "views/components"
import localeMessageWrapper from "views/components/locale-message"
import ActionsView from "./ActionsView"
import Details from "../ActionDetails"
import { SECTION_TAB_TYPES, failedInspectionCorrectionDetailSectionTabs } from '../config'
import CustomNotes from "../custom-notes"
import { ActivityDetails, AuditTrial } from "../../components"
import { ACTION_TYPES } from "../../config"

const { ACTIONS_PAGE, ACTIONS_DESCRIPTION } = LANGUAGE_CONSTANTS

const getActionsDetailSectionComponent = (activeSectionTabId) => {
  switch (activeSectionTabId) {
    case SECTION_TAB_TYPES.OBSERVATION_DETAILS:
      return ObservationDetails
    case SECTION_TAB_TYPES.AUDIT_TRAIL:
      return AuditTrial
    case SECTION_TAB_TYPES.ACTIVITY_DETAILS:
      return ActivityDetails
    default:
      return null
  }
}

const FailedInspectionCorrection = ({
  intl,
  item,
  isToggleDetails,
  onToggleDetails = () => { },
  onGoBack = () => { }
}) => {
  const dispatch = useDispatch()
  const { isMobileView } = useSelector((state) => state.appConfig)

  const [selectedActionType, setSelectedActionType] = useState("")
  const [isShowSubmitActionView, setShowSubmitActionView] = useState(false)
  const [isShowAddNoteView, setShowAddNoteView] = useState(false)
  const [activeSectionTabId, setActiveSectionTabId] = useState(SECTION_TAB_TYPES.OBSERVATION_DETAILS)
  const [noteDetail, setNoteDetail] = useState("")
  const [selectedPhotos, setSelectedPhotos] = useState([])
  const [selectedPhotosDetail, setSelectedPhotosDetail] = useState([])
  const [isShowAssignContactView, setShowAssignContactView] = useState(false)
  const [assignedContact, setAssignedContact] = useState({})

  const handleGoToOnSubmittingAction = useCallback(() => {
    onGoBack()
  }, [onGoBack])

  const handleShowConfirmActionView = useCallback((value) => {
    setSelectedActionType(value || "")
  }, [])

  const handleResetState = useCallback(() => {
    setSelectedActionType("")
    setShowSubmitActionView(false)
    setShowAddNoteView(false)
    setNoteDetail("")
    setSelectedPhotos([])
    setSelectedPhotosDetail([])
    setShowAssignContactView(false)
    setAssignedContact({})
  }, [])

  const handleConfirmAction = useCallback(() => {
    setShowSubmitActionView(true)
    if (selectedActionType === ACTION_TYPES.FAILED_INSPECTION_CORRECTION.REASSIGN) {
      setShowAssignContactView(true)
    }
  }, [
    selectedActionType,
    handleGoToOnSubmittingAction,
    handleResetState
  ])

  const handleToggleAddNote = useCallback(() => {
    setShowAddNoteView((prevState) => !prevState)

    //Todo: On hide note view , earlier note needs to be kept or not?
    if (isShowAddNoteView) {
      setNoteDetail("")
    }
  }, [isShowAddNoteView])

  const handleSetSelectedFiles = useCallback(({ fileList, customFileDetailList }) => {
    setSelectedPhotos(fileList)
    setSelectedPhotosDetail(customFileDetailList)
  }, [])

  const handleChangeAssignedContact = useCallback((value) => {
    setAssignedContact(value)
  }, [])

  const handleSubmitAndCloseCorrection = useCallback(async () => {
    const prevPhotos = []
    for (let i = 0; i < selectedPhotos.length; i++) {
      const result = await toDataURL(URL.createObjectURL(selectedPhotos[i]))
      const { fileName, fileDescription, extension } = selectedPhotosDetail[i]
      prevPhotos.push({
        fileName: `${fileName}${extension}`,
        imageContent: result.split(',').pop(),
        imageId: 0,
        fileDescription,
        isDeleted: false
      })
    }
    const payload = {
      wfActionId: item.actionId,
      note: noteDetail,
      images: prevPhotos
    }
    dispatch(
      actions.updateAcceptByTradeRequest(payload, (res) => {
        if (res) {
          handleGoToOnSubmittingAction()
          handleResetState()
        }
      })
    )
  }, [
    item,
    noteDetail,
    selectedPhotos,
    selectedPhotosDetail,
    handleGoToOnSubmittingAction,
    handleResetState
  ])

  const handleSubmitAndCloseReassign = useCallback((actionId) => {
    if (actionId) {
      dispatch(actions.updateTradeActionReassignRequest({ actionId, contactId: assignedContact.contactId, roleId: assignedContact.roleId }, () => {
        handleGoToOnSubmittingAction()
        handleResetState()
      }))
    }
  }, [assignedContact, handleGoToOnSubmittingAction, handleResetState])

  const handleSubmitAndClose = useCallback(async () => {
    if (isShowAssignContactView) {
      handleSubmitAndCloseReassign(item.actionId)
    } else {
      handleSubmitAndCloseCorrection()
    }
  }, [
    item.actionId,
    isShowAssignContactView,
    handleSubmitAndCloseCorrection,
    handleSubmitAndCloseReassign
  ])

  const handleChangeNoteDetail = useCallback((event) => {
    setNoteDetail(event.target.value)
  }, [])

  const handleSelectTab = useCallback((activeTabId) => {
    setActiveSectionTabId(activeTabId)
  }, [])

  const Component = getActionsDetailSectionComponent(activeSectionTabId)

  return (
    <div className={cx("action-detail-outer", {
      "mobile-view-detail-outer": isMobileView
    })}>
      <Container>
        <div className="details-view">
          <div className="action-detail-list">
            <div
              className={cx("", {
                "action-header": isMobileView
              })}>

              <h6 className="action-detail-title">
                {item.taskOrActivityName}
              </h6>

              {isMobileView && <button
                type="button"
                className="advanced-btn"
                onClick={onToggleDetails}
              >
                {isToggleDetails ? <ChevronUp /> : <ChevronDown />}
              </button>}
            </div>

            <p className="action-detail-sub-title">
              {getLocaleMessage(intl, ACTIONS_PAGE.FAILED_INSPECTION_CORRECTION)}
            </p>
            <p>
              {getLocaleMessage(intl, ACTIONS_DESCRIPTION)}
            </p>
          </div>

          {isMobileView ? isToggleDetails ? <Details item={item} /> : "" : <Details item={item} />}

          <ActionsView
            item={item}
            isMobileView={isMobileView}
            actionId={item.actionId}
            selectedActionType={selectedActionType}
            isShowSubmitActionView={isShowSubmitActionView}
            selectedPhotos={selectedPhotos}
            selectedPhotosDetail={selectedPhotosDetail}
            isShowAddNoteView={isShowAddNoteView}
            noteDetail={noteDetail}
            actionStatus={item.actionStatus}
            assignedContact={assignedContact}
            isShowAssignContactView={isShowAssignContactView}
            onChangeAssignedContact={handleChangeAssignedContact}
            onConfirmAction={handleConfirmAction}
            onSetSelectedFiles={handleSetSelectedFiles}
            onShowConfirmActionView={handleShowConfirmActionView}
            onSubmitAndClose={handleSubmitAndClose}
            onToggleAddNote={handleToggleAddNote}
            onChangeNoteDetail={handleChangeNoteDetail}
          />
        </div>
      </Container>
      <div className="section-tabs-border">
        <Container>
          {item.note && !isMobileView && <div className="custom-notes-content"><CustomNotes item={item} /></div>}
          <div className="mb-1 mobile-tabs-scroll">
            <OverviewSectionTabs
              activeTabId={activeSectionTabId}
              tabsList={failedInspectionCorrectionDetailSectionTabs}
              isBorderBottomRequired={false}
              onChangeTab={handleSelectTab}
            />
          </div>
          {!!Component && <Component
            actionId={item.actionId}
            observationTaskId={item.observationTaskId}
          />
          }
        </Container>
      </div>
    </div>
  )
}

export default localeMessageWrapper(FailedInspectionCorrection)
