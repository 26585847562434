// ** Redux, Root Saga & Root Reducer Imports
import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import logger from 'redux-logger'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import rootReducers from './root-reducer'
import rootSagas from './root-saga'

const persistConfig = {
  debug: false,
  key: 'root',
  keyPrefix: 'v.1',
  storage,
  whiteList: ['authDetail']
}

// ** init middleware
const sagaMiddleware = createSagaMiddleware()
const persistedReducer = persistReducer(persistConfig, rootReducers)

// ** Create store
const store = createStore(
  persistedReducer,
  compose(applyMiddleware(sagaMiddleware, logger))
)

export const persistor = persistStore(store)

sagaMiddleware.run(rootSagas)

export { store }
