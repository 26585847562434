import cx from "classnames"
import React, { useMemo } from "react"
import { Col, Row } from "reactstrap"
import { useForm } from "react-hook-form"

import { LANGUAGE_CONSTANTS } from "shared/language-constants"
import { getLocaleMessage } from "utility/Utils"
import { DatePicker, ReactSelect } from "views/components"
import localeMessageWrapper from "views/components/locale-message"
import { ACTION_STATUS_VALUE_TYPES, ACTION_TYPES } from "../../config"
import ActionButton from "../../ActionButton"

const { ACTIONS_PAGE, BACK } = LANGUAGE_CONSTANTS

const ActionsView = ({
  intl,
  selectedActionType,
  isShowSubmitActionView,
  confirmSiteReadyDate,
  actionStatus,
  assignedStartTime,
  timeOptions,
  onChangeDetails = () => { },
  onConfirmAction = () => { },
  onShowConfirmActionView = () => { },
  onSubmitAndClose = () => { }
}) => {
  const { control } = useForm()

  const isDisabledView = useMemo(() => {
    return !(assignedStartTime || confirmSiteReadyDate)
  }, [assignedStartTime, confirmSiteReadyDate])

  if (!isShowSubmitActionView) {
    return (
      <div className="d-flex align-items-center card-action-btn">
        {!selectedActionType ? (
          <>
            {
              actionStatus !== ACTION_STATUS_VALUE_TYPES.COMPLETED && <>
                <ActionButton
                  color="success"
                  className="mr-1 text-capitalize"
                  labelLocaleMessageId={ACTIONS_PAGE.CONFIRM}
                  type={ACTION_TYPES.CONFIRM_SITE_VISIT.CONFIRM}
                  onClick={onShowConfirmActionView}
                />
                <ActionButton
                  color="danger"
                  className="mr-1"
                  labelLocaleMessageId={ACTIONS_PAGE.NOT_READY}
                  type={ACTION_TYPES.CONFIRM_SITE_VISIT.NOT_READY}
                  onClick={onShowConfirmActionView}
                />
              </>
            }
            <div className="ml-1">
            </div>

          </>
        ) : (
          <>
            {selectedActionType === ACTION_TYPES.CONFIRM_SITE_VISIT.CONFIRM ? (
              <ActionButton
                color="success"
                className="mr-1 to-capitalized"
                isConfirmAction
                labelLocaleMessageId={ACTIONS_PAGE.YES_ITS_READY}
                onClick={onConfirmAction}
              />
            ) : (
              <ActionButton
                color="danger"
                className="mr-1 to-capitalized"
                labelLocaleMessageId={ACTIONS_PAGE.NO_ITS_NOT_READY}
                isConfirmAction
                onClick={onConfirmAction}
              />
            )}
            <ActionButton
              className="secondary-solid text-capitalize"
              labelLocaleMessageId={BACK}
              onClick={onShowConfirmActionView}
            />
          </>
        )}
      </div>
    )
  }

  return (
    <Row>
      <Col md={4} lg={3}>
        <DatePicker
          id="confirmSiteDate"
          placeholder={getLocaleMessage(intl, ACTIONS_PAGE.READY_DATE)}
          value={confirmSiteReadyDate}
          onChange={(value) => onChangeDetails("confirmSiteDate", value)}
          isClearable={!!confirmSiteReadyDate}
        />
      </Col>
      <Col md={4} lg={3}>
        <ReactSelect
          isClearable
          id="confirm_site_time"
          name="confirm_site_time"
          placeHolder={getLocaleMessage(intl, ACTIONS_PAGE.READY_TIME)}
          control={control}
          value={assignedStartTime}
          options={timeOptions}
          onSelect={(selected) => onChangeDetails("confirmSiteTime", selected ? selected.value : "")
          }
        />
      </Col>
      <Col md={4} lg={3}>
        <ActionButton
          className={cx("w-100", { "grey-solid": isDisabledView, "common-button": !isDisabledView })}
          isDisabled={isDisabledView}
          isSubmitAction
          labelLocaleMessageId={ACTIONS_PAGE.SUBMIT_AND_CLOSE}
          onClick={onSubmitAndClose}
        />
      </Col>
    </Row>
  )
}

export default localeMessageWrapper(ActionsView)
