import cx from "classnames";
import React, { useEffect, useState } from "react";
import { Input } from "reactstrap";

import { ACTIVITY_COLLECTION_RESPONSE_TYPES } from "shared/constants";
import { LANGUAGE_CONSTANTS } from "shared/language-constants";
import { getLocaleMessage } from "utility/Utils";
import { CustomReactSelect } from "views/components";
import { responseControlsConfig } from "../config";

const { RESPONSES_PLACEHOLDER_TEXT } = LANGUAGE_CONSTANTS;

const DDLResponse = ({
  intl,
  isPrimary = false,
  responseTypeId = "",
  value,
  otherOption,
  responseOptions = [],
  responseClassName,
}) => {
  const [options, setOptions] = useState([]);
  const [isMultiSelect, setMultiSelect] = useState(
    responseTypeId === ACTIVITY_COLLECTION_RESPONSE_TYPES.DDL_MULTI_SELECT
  );
  const [isOtherOptionSelected, setOtherOptionSelected] = useState(false);

  useEffect(() => {
    setMultiSelect(
      responseTypeId === ACTIVITY_COLLECTION_RESPONSE_TYPES.DDL_MULTI_SELECT
    );
  }, [responseTypeId]);

  useEffect(() => {
    //Todo: set based on actual language and actual api json
    if (responseOptions.length) {
      const formattedOptions = responseOptions.map((op) => ({
        text: op.options,
        value: op.responseOptionId,
      }));
      setOptions(formattedOptions);
    }
  }, [responseOptions]);

  useEffect(() => {
    const isOtherOption = options.findIndex((option) => {
      if (option.text.includes("Other")) {
        if (isMultiSelect) {
          const isValueExists = Array.isArray(value)
            ? value.some(
                (val) => val.value === option.value || val === option.value
              )
            : "";
          return isValueExists;
        } else return option.value === value;
      }
    });
    setOtherOptionSelected(isOtherOption !== -1);
  }, [options, isMultiSelect, value]);

  return (
    <>
      <div className={cx({ "ddl-select": isPrimary })}>
        <CustomReactSelect
          {...responseControlsConfig}
          id="ddlResponse"
          isMulti={isMultiSelect}
          name={"ddlResponse"}
          isDisabled
          placeholder={getLocaleMessage(
            intl,
            RESPONSES_PLACEHOLDER_TEXT.SELECT_RESPONSE
          )}
          inputFieldClassName={responseClassName}
          options={options}
          defaultOptions={options}
          value={isMultiSelect ? value || [] : value}
          onSelect={() => {}}
        />
        {isOtherOptionSelected && (
          <Input
            type="textarea"
            name="otherOption"
            className={cx("response-textarea", {
              "is-primary": isPrimary,
              "is-additional": !isPrimary,
            })}
            placeholder={getLocaleMessage(
              intl,
              RESPONSES_PLACEHOLDER_TEXT.WRITE_TEXT_RESPONSE
            )}
            value={otherOption}
            onChange={(e) => {}}
          />
        )}
      </div>
    </>
  );
};

export default DDLResponse;
