import { putDataApi } from "services/api"
import config from "../../config"

/**
 * @method updateTradeActionReassign
 * This api is for updating contact to reassign
 * [Failed inspection correction]
 * 
 * @param {object} data 
 * 
 * {
 * roleId: 0
 *  contactId: 0,
 *  actionId: 0
 * }
 * 
 */
export const updateTradeActionReassign = async (data) => {
  const { url } = config.updateTradeActionReassign
  return await putDataApi(url({ actionId: data.actionId, roleId: data.roleId, contactId: data.contactId }), data)
}

export default updateTradeActionReassign
