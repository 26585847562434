import React, { useEffect, useState } from "react";
import { CustomInput, Input } from "reactstrap";

import { ACTIVITY_COLLECTION_RESPONSE_TYPES } from "shared/constants";
import { LANGUAGE_CONSTANTS } from "shared/language-constants";
import { getLocaleMessage } from "utility/Utils";

const { RESPONSES_PLACEHOLDER_TEXT } = LANGUAGE_CONSTANTS;

const ChoiceResponse = ({
  intl,
  responseTypeId = "",
  responseId,
  value,
  otherOption,
  responseOptions = [],
}) => {
  const [options, setOptions] = useState([]);
  const [isMultiChoice, setMultiChoice] = useState(
    responseTypeId === ACTIVITY_COLLECTION_RESPONSE_TYPES.CHOICE_MULTI_SELECT
  );
  const [isOtherOptionSelected, setOtherOptionSelected] = useState(false);

  useEffect(() => {
    setMultiChoice(
      responseTypeId === ACTIVITY_COLLECTION_RESPONSE_TYPES.CHOICE_MULTI_SELECT
    );
  }, [responseTypeId]);

  useEffect(() => {
    //Todo: set based on actual language and actual api json
    if (responseOptions.length) {
      const formattedOptions = responseOptions.map((op) => ({
        text: op.options,
        value: op.responseOptionId,
      }));
      setOptions(formattedOptions);
    }
  }, [responseOptions]);

  useEffect(() => {
    const isOtherOption = options.findIndex((option) => {
      if (option.text.includes("Other")) {
        if (isMultiChoice) {
          const isValueExists = Array.isArray(value)
            ? value.some(
                (val) => val.value === option.value || val === option.value
              )
            : "";
          return isValueExists;
        } else return option.value === value;
      }
    });
    setOtherOptionSelected(isOtherOption !== -1);
  }, [options, isMultiChoice, value]);

  if (!isMultiChoice) {
    return (
      <>
        <div className="mx-1 mb-1 mb-md-0 mx-md-0">
          {options.map((option, index) => {
            return (
              <div
                key={`choiceResponseInput_${option.value}_${index}`}
                className={"response-input response-disabled"}
              >
                <CustomInput
                  type="radio"
                  id={`choiceResponse_${option.value}_${index}`}
                  name={`choiceResponse_${responseId}`}
                  label={option.text}
                  disabled
                  checked={value === option.value}
                  onChange={() => {}}
                />
              </div>
            );
          })}
          {isOtherOptionSelected && (
            <Input
              type="textarea"
              name="otherOption"
              placeholder={getLocaleMessage(intl, RESPONSES_PLACEHOLDER_TEXT.WRITE_TEXT_RESPONSE)}
              value={otherOption}
              className="mb-2"
              onChange={() => {}}
            />
          )}
        </div>
      </>
    );
  }

  return (
    <>
      <div className="mx-1 mb-1 mb-md-0 mx-md-0">
        {options.map((option, index) => {
          const selectedIndex =
            !!value && value.findIndex((val) => val === option.value);
          const isSelected = !!value && selectedIndex !== -1;
          return (
            <div
              key={`choiceResponseInput_${option.value}_${index}`}
              className={"response-input response-disabled"}
            >
              <CustomInput
                type="checkbox"
                id={`choiceResponse_${option.value}_${index}`}
                label={option.text}
                disabled
                checked={isSelected}
                onChange={() => {}}
              />
            </div>
          );
        })}
        {isOtherOptionSelected && (
          <Input
            type="textarea"
            name="otherOption"
            placeholder={getLocaleMessage(intl, RESPONSES_PLACEHOLDER_TEXT.WRITE_TEXT_RESPONSE)}
            value={otherOption}
            onChange={() => {}}
          />
        )}
      </div>
    </>
  );
};

export default ChoiceResponse;
