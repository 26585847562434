import React, { useCallback, useState, useMemo } from "react"
import { Col } from 'reactstrap'

import AccordionComponent from '../accordion'
import UnitList from '../unit-list'

const SiteList = ({ intl }) => {
  const [accordianExpanded, setAccordianExpanded] = useState([0])

  const siteDetails = useMemo(() => [{ title: "site 1" }, { title: "site 2" }, { title: "site 3" }], [])
  const unitDetails = useMemo(() => [{ title: "unit 1", id: "1" }, { title: "unit 2", id: "2" }, { title: "unit 3", id: "3" }], [])

  const handleAccordianExpanded = useCallback((id) => {
    let updatedAccordian = [...accordianExpanded]
    if (updatedAccordian.includes(id)) {
      updatedAccordian = updatedAccordian.filter((item) => item !== id)
    } else {
      updatedAccordian.push(id)
    }
    setAccordianExpanded(updatedAccordian)
  }, [accordianExpanded])

  return (
    <div className="setup-accordion-outer">
      <div className="setup-accordion activity-details-accordion">
        {siteDetails.map((item, index) => (
          <div className="setup-accordion-header" key={`view-unit-details-${index}`}> <AccordionComponent index={index} intl={intl} title={item.title} accordianExpanded={accordianExpanded} onAccordianExpanded={handleAccordianExpanded}>
            <UnitList intl={intl} unitDetails={unitDetails} />
          </AccordionComponent></div>
        ))
        }
      </div>
    </div>
  )
}

export default SiteList
