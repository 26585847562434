export const UPDATE_LIST_PAGE_SEARCH_HISTORY = "UPDATE_LIST_PAGE_SEARCH_HISTORY"
export const RESET_SEARCH_HISTORY = "RESET_SEARCH_HISTORY"

/****************************************************/
/** UPDATE_LIST_PAGE_SEARCH_HISTORY **/
/****************************************************/

/**
 * @method updateListPageSearchHistoryRequest : To save/update generic and advance search filters history, that needs to be used when user goes back to that page later on.
 * @param payload : { keyName, searchValues }
 */
export const updateListPageSearchHistoryRequest = (payload) => {
  return {
    type: UPDATE_LIST_PAGE_SEARCH_HISTORY,
    payload
  }
}