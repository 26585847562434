import React, { useMemo } from "react"

import { getLocaleMessage } from "utility/Utils"

const ActivityDetailItem = ({ intl, item = {}, data = {} }) => {
  const dataValue = useMemo(() => data[item.keyName], [data, item])

  return (
    <p className="activity-detail-item" >
      <span className="activity-detail-item-name">{getLocaleMessage(intl, item.labelLocaleId)}{": "}</span>
      {!!dataValue ? dataValue : "N/A"}
    </p>
  )
}

export default ActivityDetailItem
