import {
  SET_AUTH_TOKEN,
  SET_ACCESS_DENIED,
  RESET_ACCESS_DENIED,
} from "../../actions";

// ** Initial State
const initialState = {
  accessToken: "",
  isAccessDenied: true,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_AUTH_TOKEN:
      return { ...state, accessToken: action.payload };

    /** SET_ACCESS_DENIED **/
    case SET_ACCESS_DENIED:
      return { ...state, isAccessDenied: true };

    /** RESET_ACCESS_DENIED **/
    case RESET_ACCESS_DENIED:
      return { ...state, isAccessDenied: false };

    default:
      return state;
  }
};

export default authReducer;
