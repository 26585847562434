export const ACTION_TYPE = {
  UPLOAD: 'UPLOAD'
}

export const FILE_UPLOAD_ERROR_TYPES = {
  FILE_TOO_LARGE: 'file-too-large'
}

export const ACCEPTED_FILE_TYPES = "image/jpeg, image/png, .pdf, .txt, .ppt, .pptx, .doc, .docx, xls, .xlsx"

export const ACCEPTED_IMAGE_FILE_TYPES = "image/jpeg, image/png"

export const MAX_NAME_LENGTH = 255

export const MAX_FILE_SIZE = 4 //4MB
