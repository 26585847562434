import React from "react";
import { CardTitle } from "reactstrap";
import { ChevronRight } from "react-feather";
import { Link } from "react-router-dom";

import { ROUTES } from "shared/constants";
import { LANGUAGE_CONSTANTS } from "shared/language-constants";
import { getLocaleMessage } from "utility/Utils.js";
import localeMessageWrapper from "views/components/locale-message";

const { ACTIONS } = LANGUAGE_CONSTANTS;

const ActionsHeader = ({ intl, taskOrActivityName = "" }) => {
  return (
    <div className="content-header">
      <div className="d-flex align-items-center flex-wrap">
        <CardTitle tag="h1" className="mb-0">
          <Link className="nav-item" to={ROUTES.actionsOverview}>
            {getLocaleMessage(intl, ACTIONS)}
          </Link>
        </CardTitle>
        {!!taskOrActivityName && (
          <>
            <ChevronRight className="right-arrow" size={20} />
            <CardTitle tag="h1" className={"mb-0 border-b"}>
              {taskOrActivityName}
            </CardTitle>
          </>
        )}
      </div>
    </div>
  );
};

export default localeMessageWrapper(ActionsHeader);
