import cx from "classnames"
import React, { useMemo } from "react"
import { Col, Row } from "reactstrap"

import { LANGUAGE_CONSTANTS } from "shared/language-constants"
import { getLocaleMessage } from "utility/Utils"
import localeMessageWrapper from "views/components/locale-message"
import ActionsTradeContactSelect from "views/pages/actions/actions-trade-contact-select"
import ActionButton from "../../../ActionButton"
import { ACTION_STATUS_VALUE_TYPES } from "../../../config"

const { ACTIONS_PAGE } = LANGUAGE_CONSTANTS

const ActionsView = ({
  intl,
  actionId,
  actionStatus,
  assignedContact,
  onChangeDetails = () => { },
  onSubmitAndClose = () => { },
  onViewMore = () => { }
}) => {

  const isDisabledView = useMemo(() => {
    return !(Object.keys(assignedContact).length)
  }, [assignedContact])

  return (
    <div className="correct-btns card-action-btn">
      {actionStatus !== ACTION_STATUS_VALUE_TYPES.COMPLETED && (
        <div className="card-action-btn">
          <Col xs={12} sm={12} md={12} lg={12} xl={12} className="time-btn px-0">
            <ActionsTradeContactSelect isReassignFIC actionId={actionId} placeholder={getLocaleMessage(intl, ACTIONS_PAGE.CONTACT_TO_ASSIGN_CORRECTION)} value={assignedContact.contactId} onSelect={onChangeDetails} />
          </Col>
        </div>
      )}
      <Row>
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className="submit-close-btn d-flex align-items-center justify-content-between mt-1"
        >
          {actionStatus !== ACTION_STATUS_VALUE_TYPES.COMPLETED && (
            <ActionButton
              className={cx("btn-block mr-1 w-100", { "grey-solid": isDisabledView, "common-button": !isDisabledView })}
              labelLocaleMessageId={ACTIONS_PAGE.SUBMIT_AND_CLOSE}
              isSubmitAction
              isDisabled={isDisabledView}
              onClick={onSubmitAndClose}
            />
          )}
          <ActionButton
            className="secondary-solid outline-button w-100"
            labelLocaleMessageId={ACTIONS_PAGE.VIEW_MORE}
            isViewAction
            onClick={onViewMore}
          />
        </Col>
      </Row>
    </div>
  )
}

export default localeMessageWrapper(ActionsView)
