import React, { useCallback, useState } from "react"
import { Input } from "reactstrap"

import { LANGUAGE_CONSTANTS } from "shared/language-constants"
import { getLocaleMessage } from "utility/Utils"
import { ResourceCenterUploader } from "views/components"
import localeMessageWrapper from "views/components/locale-message"
import ActionButton from "../../ActionButton"
import { ACTION_STATUS_VALUE_TYPES, ACTION_TYPES } from "../../config"
import CustomNotes from "../../mobile-view/action-detail-view/custom-notes"

const { ACTIONS_PAGE, BACK } = LANGUAGE_CONSTANTS

const ActionsView = ({
  intl,
  selectedActionType,
  isShowAddNoteView,
  noteDetail,
  isShowSubmitActionView,
  selectedPhotos,
  actionStatus,
  selectedPhotosDetail,
  isMobileView,
  item,
  onToggleAddNote = () => { },
  onConfirmAction = () => { },
  onSetSelectedFiles = () => { },
  onShowConfirmActionView = () => { },
  onSubmitAndClose = () => { },
  onChangeNoteDetail = () => { }
}) => {
  const [isShowUploader, setShowUploader] = useState(false)
  const [isShowNote, setShowNote] = useState(false)

  const handleToggleAddPhoto = useCallback(() => {
    setShowUploader(!isShowUploader)
  }, [isShowUploader])

  if (!isShowSubmitActionView) {
    return (
      <>
        <div className="d-flex align-items-center card-action-btn">
          {!selectedActionType ? (
            <>
              {
                actionStatus !== ACTION_STATUS_VALUE_TYPES.COMPLETED && <>
                  <ActionButton
                    color="success"
                    className="mr-1 text-capitalize"
                    labelLocaleMessageId={ACTIONS_PAGE.APPROVE}
                    type={ACTION_TYPES.VERIFY_BY_SUPERINTENDENT.APPROVE}
                    onClick={onShowConfirmActionView}
                  />
                  <ActionButton
                    color="danger"
                    className="mr-1 text-capitalize"
                    labelLocaleMessageId={ACTIONS_PAGE.REJECT}
                    type={ACTION_TYPES.VERIFY_BY_SUPERINTENDENT.REJECT}
                    onClick={onShowConfirmActionView}
                  />
                  {isMobileView && item.note && <ActionButton
                    className="secondary-solid"
                    labelLocaleMessageId={
                      isShowNote ? ACTIONS_PAGE.HIDE_NOTE : ACTIONS_PAGE.VIEW_NOTE
                    }
                    onClick={() => setShowNote(!isShowNote)}
                  />}
                </>
              }
            </>
          ) : (
            <>
              {selectedActionType === ACTION_TYPES.VERIFY_BY_SUPERINTENDENT.APPROVE ? (
                <ActionButton
                  color="success"
                  className="mr-1 text-capitalize"
                  isConfirmAction
                  labelLocaleMessageId={ACTIONS_PAGE.APPROVE_ACTION}
                  onClick={onConfirmAction}
                />
              ) : (
                <ActionButton
                  color="danger"
                  className="mr-1 text-capitalize"
                  isConfirmAction
                  labelLocaleMessageId={ACTIONS_PAGE.REJECT_ACTION}
                  onClick={onConfirmAction}
                />
              )}
              <ActionButton
                className="secondary-solid text-capitalize"
                labelLocaleMessageId={BACK}
                onClick={onShowConfirmActionView}
              />
            </>
          )}
        </div>
        {isMobileView && item.note && <CustomNotes item={item} isShow={isShowNote} />}
      </>
    )
  }

  return (
    <>
      {isShowAddNoteView && (
        <div className="mt-1 mb-1">
          <Input
            type="textarea"
            placeholder={getLocaleMessage(
              intl,
              ACTIONS_PAGE.ADD_NOTE_PLACEHOLDER
            )}
            value={noteDetail}
            onChange={onChangeNoteDetail}
          />
        </div>
      )}
      <div className="d-flex align-items-center add-note-photo card-action-btn">
        <div className="mr-1 add-note-buttons">
          <ActionButton
            className="outline-grey-button mr-1"
            labelLocaleMessageId={
              isShowAddNoteView ? ACTIONS_PAGE.HIDE_NOTE : ACTIONS_PAGE.ADD_NOTE
            }
            onClick={onToggleAddNote}
          />
          <ActionButton
            className="outline-grey-button"
            localeMessageValues={
              !!selectedPhotos?.length ? { count: selectedPhotos.length } : {}
            }
            labelLocaleMessageId={
              !!selectedPhotos?.length
                ? ACTIONS_PAGE.ATTACHED_PHOTOS_LABEL
                : ACTIONS_PAGE.ADD_PHOTO
            }
            onClick={handleToggleAddPhoto}
          />
        </div>
        <ActionButton
          isSubmitAction
          className="common-button"
          labelLocaleMessageId={ACTIONS_PAGE.SUBMIT_AND_CLOSE}
          onClick={onSubmitAndClose}
        />
        {isMobileView && item.note && <ActionButton
          className="w-100 secondary-solid my-2"
          labelLocaleMessageId={
            isShowNote ? ACTIONS_PAGE.HIDE_NOTE : ACTIONS_PAGE.VIEW_NOTE
          }
          onClick={() => setShowNote(!isShowNote)}
        />}

        {isMobileView && item.note && <CustomNotes item={item} isShow={isShowNote} />}

        {isShowUploader && (
          <ResourceCenterUploader
            isAllowImageTypeFilesOnly
            initialFileList={selectedPhotos}
            initialCustomFileDetailList={selectedPhotosDetail}
            onSetSelectedFiles={onSetSelectedFiles}
            onClose={handleToggleAddPhoto}
          />
        )}
      </div>
    </>
  )
}

export default localeMessageWrapper(ActionsView)
