import cx from 'classnames'
import React, { useCallback, useState, useMemo } from "react"
import { Container } from "reactstrap"
import { ChevronUp, ChevronDown } from 'react-feather'
import { useDispatch, useSelector } from "react-redux"

import { LANGUAGE_CONSTANTS } from "shared/language-constants"
import * as actions from "store/actions"
import { getLocaleMessage } from "utility/Utils"
import { OverviewSectionTabs, ObservationDetails } from "views/components"
import localeMessageWrapper from "views/components/locale-message"
import Details from "../ActionDetails"
import ActionsView from "./ActionsView"
import { REASSIGN_SECTION_TAB_TYPES, reassignFailedInspectionCorrectionDetailSectionTabs } from '../config'
import CustomNotes from "../custom-notes"
import { ActivityDetails, AuditTrial } from "../../components"

const { ACTIONS_PAGE, ACTIONS_DESCRIPTION } = LANGUAGE_CONSTANTS

const contactOptions = [
  {
    text: "Contact 1",
    value: "1"
  },
  {
    text: "Contact 2",
    value: "2"
  }
]

const getActionsDetailSectionComponent = (activeSectionTabId) => {
  switch (activeSectionTabId) {
    case REASSIGN_SECTION_TAB_TYPES.ACTIVITY_DETAILS:
      return ActivityDetails
    case REASSIGN_SECTION_TAB_TYPES.OBSERVATION_DETAILS:
      return ObservationDetails
    case REASSIGN_SECTION_TAB_TYPES.AUDIT_TRAIL:
      return AuditTrial
    default:
      return AuditTrial
  }
}

const ReassignFailedInspectionCorrection = ({
  intl,
  item,
  isToggleDetails,
  onGoBack = () => { },
  onToggleDetails = () => { }
}) => {
  const dispatch = useDispatch()
  const { isMobileView } = useSelector((state) => state.appConfig)

  const [assignedContact, setAssignedContact] = useState({})
  const [activeSectionTabId, setActiveSectionTabId] = useState(REASSIGN_SECTION_TAB_TYPES.ACTIVITY_DETAILS)

  const handleResetState = useCallback(() => {
    setAssignedContact({})
  }, [])

  const handleGoToOnSubmittingAction = useCallback(() => {
    onGoBack()
  }, [onGoBack])

  const handleSubmitAndClose = useCallback((actionId = item.actionId) => {
    if (actionId) {
      dispatch(actions.updateReassignFailedInspectionRequest({ actionId, contactId: assignedContact.contactId, roleId: assignedContact.roleId }, () => {
        handleResetState()
        handleGoToOnSubmittingAction()
      }))
    }
  }, [assignedContact, item, handleResetState, handleGoToOnSubmittingAction])

  const handleChangeDetails = useCallback((value) => {
    setAssignedContact(value)
  }, [])

  const handleSelectTab = useCallback((activeTabId) => {
    setActiveSectionTabId(activeTabId)
  }, [])

  const Component = getActionsDetailSectionComponent(activeSectionTabId)

  const tabList = useMemo(() => {
    return [...reassignFailedInspectionCorrectionDetailSectionTabs]
  }, [])

  return (
    <div className={cx("action-detail-outer", {
      "mobile-view-detail-outer": isMobileView
    })}>
      <Container>
        <div className="details-view">
          <div className={cx("action-detail-list", {
            "action-detail-toggle": isMobileView
          })}>
            <div className='action-header'>

              <h6 className="action-detail-title">
                {item.taskOrActivityName}
              </h6>

              {isMobileView && <button
                type="button"
                className="advanced-btn"
                onClick={onToggleDetails}
              >
                {isToggleDetails ? <ChevronUp /> : <ChevronDown />}
              </button>}
            </div>
            <p className="action-detail-sub-title">
              {getLocaleMessage(intl, ACTIONS_PAGE.REASSIGN_FAILED_INSPECTION_CORRECTION)}
            </p>
            <p>
              {getLocaleMessage(intl, ACTIONS_DESCRIPTION)}
            </p>
          </div>

          {isMobileView ? isToggleDetails ? <Details item={item} /> : "" : <Details item={item} />}

          <ActionsView
            item={item}
            assignedContact={assignedContact}
            contactOptions={contactOptions}
            isMobileView={isMobileView}
            actionId={item.actionId}
            actionStatus={item.actionStatus}
            onChangeDetails={handleChangeDetails}
            onSubmitAndClose={handleSubmitAndClose}
          />
        </div>
      </Container>
      <div className="section-tabs-border">
        <Container>
          {item.note && !isMobileView && <div className="custom-notes-content"><CustomNotes item={item} /></div>}

          <div className="mb-1 mobile-tabs-scroll">
            <OverviewSectionTabs
              activeTabId={activeSectionTabId}
              tabsList={tabList}
              isBorderBottomRequired={false}
              onChangeTab={handleSelectTab}
            />
          </div>
          {!!Component && <Component
            actionId={item.actionId}
            observationTaskId={item.observationTaskId}
          />}
        </Container>
      </div>
    </div>
  )
}

export default localeMessageWrapper(ReassignFailedInspectionCorrection)
