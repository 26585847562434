import cx from "classnames"
import moment from "moment"
import React, { useMemo, useState, useCallback, useEffect } from "react"
import { useDispatch } from "react-redux"

import { LANGUAGE_CONSTANTS } from "shared/language-constants"
import * as actions from "store/actions"
import { getLocaleMessage } from "utility/Utils"
import { MessageCard } from "views/components"
import localeMessageWrapper from "views/components/locale-message"
import AuditTrailItem from "./audit-trail-item"
import { getBlockTimelineColor } from "./helpers"

const {
  ACTIONS_PAGE: { AUDIT_TRAIL },
  LOADER
} = LANGUAGE_CONSTANTS

const ActionsAuditTrial = ({ intl, actionId, observationTaskId }) => {
  const [auditTrailDetails, setAuditTrailDetails] = useState([])
  const [isFetching, setFetching] = useState(true)

  const dispatch = useDispatch()

  const colorCode = useMemo(() => getBlockTimelineColor(), [])

  const handleFetchAuditTrail = useCallback(() => {
    if (actionId) {
      dispatch(
        actions.getAuditTrailModalData(
          { observationTaskId, wfActionId: actionId },
          (res) => {
            if (res) {
              setAuditTrailDetails(res)
            }
            setFetching(false)
          }
        )
      )
    } else {
      setFetching(false)
    }
  }, [observationTaskId, actionId])

  useEffect(() => {
    handleFetchAuditTrail()
  }, [])

  if (!actionId) {
    return null
  }

  const getFormattedDate = useCallback((date) => !!date ? moment(date).format('MMMM D, YYYY') : '', [])

  return (
    <div>
      {isFetching && (
        <div className="mt-1">
          <MessageCard messageText={getLocaleMessage(intl, LOADER.LOADING)} />
        </div>
      )}
      <ul className={cx("timeline")}>
        {auditTrailDetails.map((item, index) => {
          return (
            <li
              key={`audit-trail-${item.auditTrailId}-${item.status}`}
              className="stepline-name timeline-item"
            >
              <div className="audit-trail-header">
                <h3>
                  {getLocaleMessage(intl, AUDIT_TRAIL)}:
                  <span className="ml-1">{item.status}</span>
                </h3>
                <div className="audit-trail-details">
                  <span className="submitter">{item.contactName}</span>
                  <span className="creater">{getFormattedDate(item.insertedDate)}</span>
                </div>
              </div>
              <AuditTrailItem intl={intl} data={item} index={index} />
              <span
                className={cx("timeline-point timeline-point-indicator", {
                  [`timeline-point-${colorCode}`]: colorCode
                })}
              />
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default localeMessageWrapper(ActionsAuditTrial)
