import React, { useCallback, useState, useEffect } from "react";
import { Lightbox } from "react-modal-image";
import { useRouteMatch } from "react-router-dom";

const PhotoResponse = ({ value }) => {
  const match = useRouteMatch();

  const [zoomImageUrl, setZoomImageUrl] = useState(null);

  useEffect(() => {
    const { taskId } = match?.params || {};
    if (taskId) {
      setObservationTaskId(taskId);
    }
  }, []);

  const handleZoomImage = useCallback((fileURL) => {
    setZoomImageUrl(fileURL);
  }, []);

  const handleCloseImageModal = useCallback(() => {
    setZoomImageUrl(null);
  }, []);

  return (
    <>
      <div className="d-flex">
        <div className="upload-box task-data-upload">
          {!!value?.length && (
            <div className={"accident-place-img"}>
              {value.map((img, index) => {
                if (img.isDeleted) {
                  return null;
                }
                const fileUrl = img.externalFileUrl
                  ? img.externalFileUrl
                  : img.imageFile;
                return (
                  <div
                    key={`photo_response_${index}`}
                    className="photo-acc-img"
                  >
                    <img
                      src={fileUrl}
                      onClick={() => handleZoomImage(fileUrl)}
                    />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
      {zoomImageUrl && (
        <Lightbox
          small={zoomImageUrl}
          large={zoomImageUrl}
          hideDownload={true}
          onClose={handleCloseImageModal}
        />
      )}
    </>
  );
};

export default PhotoResponse;
