import { putDataApi } from "services/api"
import config from "../../config"

/**
 * @method updateVerifyBySuperintendent
 * This api is called when the superintendent accept/reject the verification for some activity
 * [Verify By Superintendent] 
 * 
 * @param {object} data 
 * 
 * {
 *  assignmentActivityId: 0,
 *  note: "",
 *  images: [],
 *  observationTaskId: 0,
 *  verifyBySuperintendent: 0
 * }
 * 
 */
export const updateVerifyBySuperintendent = async (data) => {
  const { url } = config.updateVerifyBySuperintendent
  return await putDataApi(url(data), data)
}

export default updateVerifyBySuperintendent
