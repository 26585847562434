import logo from "assets/images/logo.svg";
import notAuthImg from "assets/images/not-authorized.svg";
import { LANGUAGE_CONSTANTS } from "shared/language-constants";
import { getLocaleMessage } from "utility/Utils";
import localeMessageWrapper from "views/components/locale-message";

const { YOU_ARE_NOT_AUTHORIZED } = LANGUAGE_CONSTANTS;

const NotAuthorized = ({ intl }) => {
  return (
    <div className="misc-wrapper">
      <a className="brand-logo d-none d-md-block" href="/">
        <img src={logo} alt="logo" />
      </a>
      <div className="misc-inner p-2 p-sm-3">
        <div className="w-100 text-center">
          <h2 className="mb-1">
            {getLocaleMessage(intl, YOU_ARE_NOT_AUTHORIZED)} 🔐
          </h2>
          <img
            className="img-fluid"
            src={notAuthImg}
            alt="Not authorized page"
          />
        </div>
      </div>
    </div>
  );
};

export default localeMessageWrapper(NotAuthorized);
