import { getDataApi } from "services/api"
import config from "../../config"

/**
 * @method getActionSiteListDDL
 *
 */
export const getActionSiteListDDL = (data) => {
  const { url } = config.getActionSiteListDDL
  return getDataApi(url(data))
}

export default getActionSiteListDDL
