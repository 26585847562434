const config = {
  getCorrectionDetails: {
    method: 'GET',
    url: ({
      actionId,
      language
    }) => `api/FSPublic/CorrectionDetails/${actionId}?Langauge=${language}`
  },
}

export default config
