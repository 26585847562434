import cx from "classnames"
import React, { useMemo, useState } from "react"
import { Col, Row } from "reactstrap"

import { LANGUAGE_CONSTANTS } from "shared/language-constants"
import { getLocaleMessage } from "utility/Utils"
import localeMessageWrapper from "views/components/locale-message"
import ActionsTradeContactSelect from "views/pages/actions/actions-trade-contact-select";
import ActionButton from "../../ActionButton"
import CustomNotes from "../../mobile-view/action-detail-view/custom-notes"

const { ACTIONS_PAGE } = LANGUAGE_CONSTANTS

const ActionsView = ({
  intl,
  assignedContact,
  actionId,
  item,
  isMobileView,
  onChangeDetails = () => { },
  onSubmitAndClose = () => { }
}) => {

  const [isShowNote, setShowNote] = useState(false)

  const isDisabledView = useMemo(() => {
    return !(Object.keys(assignedContact).length)
  }, [assignedContact])

  return (
    <div className="mb-1 assign-contact-dropdown card-action-btn">
      <Row className="card-action-btn">
        <Col xs={7} md={4}>
          <ActionsTradeContactSelect isReassignFIC actionId={actionId} value={assignedContact.contactId} onSelect={onChangeDetails} />
        </Col>
        <Col xs={5} md={3}>
          <ActionButton
            isDisabled={isDisabledView}
            isSubmitAction
            className={cx({ "grey-solid": isDisabledView, "common-button": !isDisabledView })}
            labelLocaleMessageId={ACTIONS_PAGE.SUBMIT_AND_CLOSE}
            onClick={onSubmitAndClose}
          />
        </Col>
        <Col>
          {isMobileView && item.note && <ActionButton
            className="w-100 secondary-solid"
            labelLocaleMessageId={
              isShowNote ? ACTIONS_PAGE.HIDE_NOTE : ACTIONS_PAGE.VIEW_NOTE
            }
            onClick={() => setShowNote(!isShowNote)}
          />}</Col>
      </Row>
      {isMobileView && item.note && <CustomNotes item={item} isShow={isShowNote} />}

    </div>
  )
}

export default localeMessageWrapper(ActionsView)
