import React, { useMemo, useState, useCallback } from "react";
import { Container, Button } from "reactstrap"

import { LANGUAGE_CONSTANTS } from "shared/language-constants";
import { getLocaleMessage } from "utility/Utils.js";
import { MessageCard } from "views/components";
import localeMessageWrapper from "views/components/locale-message";
import ConfirmSiteVisit from "./confirm-site-visit";
import FailedInspectionCorrection from "./failed-inspection-correction"
import VerifyBySuperindendent from "./verify-by-superindendent"
import ReassignFailedInspectionCorrection from "./reassign-failed-inspection-correction"
import { ACTION_TASK_CARD_TYPES } from "../config";

const getCardComponent = (actionTypeId) => {
  switch (String(actionTypeId)) {
    case ACTION_TASK_CARD_TYPES.FAILED_INSPECTION_CORRECTION:
      return FailedInspectionCorrection
    case ACTION_TASK_CARD_TYPES.REASSIGN_FAILED_INSPECTION_CORRECTION:
      return ReassignFailedInspectionCorrection
    case ACTION_TASK_CARD_TYPES.VERIFY_BY_SUPERINTENDENT:
      return VerifyBySuperindendent
    case ACTION_TASK_CARD_TYPES.CONFIRM_SITE_VISIT:
      return ConfirmSiteVisit;
    default:
      return null;
  }
};

const DetailView = ({
  intl,
  isSubGoBackRequired = true,
  actionCardDetail,
  overdueTime,
  isFetching,
  onGoBack = () => { },
}) => {
  const CardComponent = useMemo(
    () => getCardComponent(actionCardDetail?.actionTypeId || ""),
    [actionCardDetail]
  );

  const [isToggleDetails, setToggleDetails] = useState(false)

  const handleToggleDetails = useCallback(() => {
    setToggleDetails(!isToggleDetails)
  }, [isToggleDetails])

  return (
    <>
      <Container>
        <Button
          outline
          className="secondary-solid back-btn"
          onClick={onGoBack}
        >
          {getLocaleMessage(intl, LANGUAGE_CONSTANTS.BACK)}
        </Button>
        {isFetching && (
          <div className="mt-1">
            <MessageCard
              messageText={getLocaleMessage(
                intl,
                LANGUAGE_CONSTANTS.LOADER.LOADING
              )}
            />
          </div>
        )}
      </Container>
      {!isFetching && CardComponent && (
        <CardComponent
          item={actionCardDetail}
          overdueTime={overdueTime}
          onToggleDetails={handleToggleDetails}
          isToggleDetails={isToggleDetails}
          onGoBack={onGoBack}
        />
      )}
    </>
  );
};

export default localeMessageWrapper(DetailView);
