import PropTypes from 'prop-types'
import { Label } from 'reactstrap'

const CustomLabel = ({
  className,
  title,
  forValue,
  required,
  requiredIconClassName,
  ...restProps
}) => {

  if (!title) {
    return null
  }

  const props = {}

  if (className) {
    props.className = className
  }
  if (forValue) {
    props.for = forValue
  }

  return (
    <>
      <Label {...restProps} {...props}>
        {title}
        {required && <>
          {' '}
          <span className={`text-danger ${requiredIconClassName}`}>*</span>
        </>}
      </Label>
    </>
  )
}

CustomLabel.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  forValue: PropTypes.string,
  required: PropTypes.bool,
  requiredIconClassName: PropTypes.string
}

CustomLabel.defaultProps = {
  title: "",
  className: "",
  required: false,
  forValue: '',
  requiredIconClassName: '',
}

export default CustomLabel
