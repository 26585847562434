import React from "react"
import { Card, Row } from "reactstrap"

const MessageCard = ({ messageText = "" }) => {
  return (
    <Card className="p-2">
      <Row className={"overview-detail-view justify-content-center"}>
        <p className="m-0">
          <strong>{messageText}</strong>
        </p>
      </Row>
    </Card>
  )
}

export default MessageCard
