import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useDispatch } from "react-redux"

import { LANGUAGE_CONSTANTS } from "shared/language-constants"
import * as actions from "store/actions"
import { getLocaleMessage, getLastElementObserver } from "utility/Utils"
import { MessageCard } from "views/components"
import localeMessageWrapper from "views/components/locale-message"

const { LOADER } = LANGUAGE_CONSTANTS

const initialActivityListState = {
  items: [],
  pageNumber: 0,
  pageSize: 50,
  hasNextPage: false
}

const InspectionDetails = ({
  actionId,
  intl
}) => {
  const observerRef = useRef(null)
  const dispatch = useDispatch()

  const [activityList, setActivityList] = useState(initialActivityListState)
  const [isFetching, setFetching] = useState(true)
  /**
   * lastElement: Used for auto loading next options, when user scroll to last visible element in options list
   */
  const [lastElement, setLastElement] = useState(null)

  const { items, pageNumber, pageSize, hasNextPage } = useMemo(() => activityList, [activityList])

  const handleFetchActivityList = useCallback((data) => {
    const {
      page
    } = data || {}

    const payload = {
      pageNumber: page,
      pageSize: pageSize,
      actionId: actionId
    }

    if (actionId) {
      dispatch(
        actions.getInspectionDetailRequest(payload,
          (res) => {
            if (res) {
              setActivityList(res.activities)
            }
            setFetching(false)
          }
        )
      )
    } else {
      setFetching(false)
    }
  }, [actionId])

  useEffect(() => {
    handleFetchActivityList({ page: 1 })
  }, [])

  const handleLoadNextPage = useCallback(() => {
    handleFetchActivityList({
      page: pageNumber + 1
    })
  }, [pageNumber, handleFetchActivityList])

  useEffect(() => {
    (() => {
      setTimeout(() => {
        if (!items?.length) {
          return null
        }
        const lastEleDetail = items[items.length - 1]
        if (!lastEleDetail) {
          return null
        }

        setLastElement(
          document.getElementById('last-action-item')
        )
      })
    })()
  }, [items])

  useEffect(() => {
    if (hasNextPage && lastElement) {
      observerRef.current = getLastElementObserver({ onLoadMore: handleLoadNextPage })

      // Observe the last element
      if (observerRef.current) {
        observerRef.current.observe(lastElement)
      }
    }

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect()
      }
    }
  }, [hasNextPage, lastElement])

  const lastActionIndex = useMemo(() => items.length - 1, [items])

  if (isFetching) {
    return <div className="mt-1">
      <MessageCard messageText={getLocaleMessage(intl, LOADER.LOADING)} />
    </div>
  }

  return (
    <ol className="inspection-detail-list">
      {items.map((item, index) => (
        <li id={lastActionIndex === index ? 'last-action-item' : ''}>
          <span className="sr-no">{index + 1}.</span> {item.activityName}
        </li>
      ))}
    </ol>
  )
}

export default localeMessageWrapper(InspectionDetails)
