import moment from "moment"

/**
 * @method getLocaleMessage : Method to format text to selected locale
 * @param {object} intl 
 * @param {string} id 
 * @returns {string} : Returns formatted text value
 */
export const getLocaleMessage = (intl, id, values = {}) => {
  if (id && intl) {
    return intl.formatMessage({ id, defaultMessage: id }, values)
  } else {
    return ''
  }
}

// ** Checks if the passed date is today
export const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 * @method getFormattedDate
 * @param {Date} date
 * @returns date in "MM/DD/yyyy" format i.e 08/31/2021
 */
export const getFormattedDate = (date, dateFormat) => {

  if (!date) return '-'

  if (dateFormat) {
    return moment(date).format(dateFormat).toString()
  }
  return moment(date).format("M/DD/YYYY").toString()
}

/**
 * @method toDataURL :  To get base64 url for file
 * @param {object} fileObj 
 * 
 * @returns base64 url
 */
export const toDataURL = fileObj => fetch(fileObj)
  .then(response => response.blob())
  .then(blob => new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.onerror = reject
    reader.readAsDataURL(blob)
  }))

/**
* @method cloneArrayOfObject : To clone simple array of objects
*
* @param {Array<any>} data : Initial array value
*
* @returns {Array<any>} Cloned array
*/
export const cloneArrayOfObject = (data) => {
  return (
    (Array.isArray(data) && data.map((val) => Object.assign({}, val))) || []
  )
}

//To create the Intersection Observer for last element in rendered list
const elementObserverOptions = {
  root: null, // Use the viewport as the root
  rootMargin: '0px',
  threshold: 0.5 // Trigger when at least 50% of the last element is visible
}

//To create the Intersection Observer for last element in activity list
export const getLastElementObserver = ({ onLoadMore = () => { } }) => {
  return new IntersectionObserver(
    (entries) => {
      if (entries[0]?.isIntersecting) {
        onLoadMore()
      }
    },
    elementObserverOptions)
}

export const getParsedJSONDetails = ({
  details,
  isShowJSONView
}) => {
  if (isShowJSONView) {
    try {
      if (!details.length) {
        return {}
      }
      const parsedJSONData = JSON.parse(details)
      const isObjectFormat = (typeof parsedJSONData === 'object')
      if (isObjectFormat) {
        return { parsedJSONData }
      }
    } catch (error) {
      return { isInvalidJSONData: true }
    }
  }

  return {}
}
