export const activityResponseListState = {
  responseId: "",
  responseTypeId: 0,
  answerText: "",
  otherOption: "",
  dateSelected: null,
  singleNumber: 0,
  minNumber: 0,
  maxNumber: 0,
  optionAnswers: [],
  responseImages: []
}

