import React, { useCallback, useState } from "react"
import { useDispatch } from "react-redux"

import * as actions from "store/actions"
import { toDataURL } from "utility/Utils"
import { ResourceCenterUploader } from "views/components"
import ActionsView from "./ActionsView"
import Details from "./Details"
import { ACTION_TYPES } from "../../../config"

const FailedInspectionCorrection = ({
  item,
  isOverviewCardView,
  onViewMore = () => { },
  onRefreshActionList = () => { }
}) => {
  const dispatch = useDispatch()

  const [selectedActionType, setSelectedActionType] = useState("")
  const [isShowSubmitActionView, setShowSubmitActionView] = useState(false)
  const [isShowAddNoteView, setShowAddNoteView] = useState(false)
  const [noteDetail, setNoteDetail] = useState("")
  const [selectedPhotos, setSelectedPhotos] = useState([])
  const [selectedPhotosDetail, setSelectedPhotosDetail] = useState([])
  const [isShowUploader, setShowUploader] = useState(false)
  const [isShowAssignContactView, setShowAssignContactView] = useState(false)
  const [assignedContact, setAssignedContact] = useState({})
  const [isShowFullActivityName, setShowFullActivityName] = useState(false)
  const [isShowFullNotes, setShowFullNotes] = useState(false)

  const handleShowConfirmActionView = useCallback((value) => {
    setSelectedActionType(value || "")
  }, [])

  const handleViewMore = useCallback(() => {
    onViewMore(item.actionId)
  }, [item, onViewMore])

  const handleResetState = useCallback(() => {
    setSelectedActionType("")
    setShowSubmitActionView(false)
    setShowAddNoteView(false)
    setNoteDetail("")
    setSelectedPhotos([])
    setSelectedPhotosDetail([])
    setShowAssignContactView(false)
    setAssignedContact({})
  }, [])

  const handleConfirmAction = useCallback(() => {
    setShowSubmitActionView(true)
    if (selectedActionType === ACTION_TYPES.FAILED_INSPECTION_CORRECTION.REASSIGN) {
      setShowAssignContactView(true)
    }
  }, [selectedActionType])

  const handleToggleAddNote = useCallback(() => {
    setShowAddNoteView((prevState) => !prevState)

    //Todo: On hide note view , earlier note needs to be kept or not?
    if (isShowAddNoteView) {
      setNoteDetail("")
    }
  }, [isShowAddNoteView])

  const handleChangeNoteDetail = useCallback((event) => {
    setNoteDetail(event.target.value)
  }, [])

  const handleToggleAddPhoto = useCallback(() => {
    setShowUploader(!isShowUploader)
  }, [isShowUploader])

  const handleSetSelectedFiles = useCallback(({ fileList, customFileDetailList }) => {
    setSelectedPhotos(fileList)
    setSelectedPhotosDetail(customFileDetailList)
  }, [])

  const handleChangeAssignedContact = useCallback((value) => {
    setAssignedContact(value)
  }, [])

  const handleSubmitAndCloseCorrection = useCallback(async () => {
    const prevPhotos = []
    for (let i = 0; i < selectedPhotos.length; i++) {
      const result = await toDataURL(URL.createObjectURL(selectedPhotos[i]))
      const { fileName, extension, fileDescription } = selectedPhotosDetail[i]
      prevPhotos.push({
        fileName: `${fileName}${extension}`,
        imageContent: result.split(',').pop(),
        imageId: 0,
        fileDescription,
        isDeleted: false
      })
    }
    const payload = {
      wfActionId: item.actionId,
      note: noteDetail,
      images: prevPhotos
    }
    dispatch(
      actions.updateAcceptByTradeRequest(payload, (res) => {
        if (res) {
          onRefreshActionList()
          handleResetState()
        }
      })
    )
  }, [
    item,
    noteDetail,
    selectedPhotos,
    selectedPhotosDetail,
    handleResetState,
    onRefreshActionList
  ])

  const handleSubmitAndCloseReassign = useCallback((actionId) => {
    if (actionId) {
      dispatch(actions.updateTradeActionReassignRequest({ actionId, contactId: assignedContact.contactId, roleId: assignedContact.roleId }, () => {
        handleResetState()
        onRefreshActionList()
      }))
    }
  }, [assignedContact, onRefreshActionList, handleResetState])

  const handleSubmitAndClose = useCallback(async () => {
    if (isShowAssignContactView) {
      handleSubmitAndCloseReassign(item.actionId)
    } else {
      handleSubmitAndCloseCorrection()
    }
  }, [
    item.actionId,
    isShowAssignContactView,
    handleSubmitAndCloseCorrection,
    handleSubmitAndCloseReassign
  ])

  const handleShowFullActivityName = useCallback(() => {
    if (isShowFullActivityName) {
      return
    }
    setShowFullActivityName(true)
  }, [isShowFullActivityName])

  const handleShowFullNotes = useCallback(() => {
    if (isShowFullNotes) {
      return
    }
    setShowFullNotes(true)
  }, [isShowFullNotes])

  return (
    <div className="action-card">
      <Details
        item={item}
        isShowAssignContactView={isShowAssignContactView}
        isShowFullActivityName={isShowFullActivityName}
        isShowFullNotes={isShowFullNotes}
        onShowFullNotes={handleShowFullNotes}
        onShowFullActivityName={handleShowFullActivityName}
      />
      <ActionsView
        actionId={item.actionId}
        actionStatus={item.actionStatus}
        assignedContact={assignedContact}
        hasPhotos={item.hasPhotos}
        isOverviewCardView={isOverviewCardView}
        isShowAddNoteView={isShowAddNoteView}
        isShowAssignContactView={isShowAssignContactView}
        isShowSubmitActionView={isShowSubmitActionView}
        noteDetail={noteDetail}
        selectedActionType={selectedActionType}
        selectedPhotos={selectedPhotos}
        onChangeAssignedContact={handleChangeAssignedContact}
        onChangeNoteDetail={handleChangeNoteDetail}
        onConfirmAction={handleConfirmAction}
        onShowConfirmActionView={handleShowConfirmActionView}
        onSubmitAndClose={handleSubmitAndClose}
        onToggleAddNote={handleToggleAddNote}
        onToggleAddPhoto={handleToggleAddPhoto}
        onViewMore={handleViewMore}
      />
      {isShowUploader && (
        <ResourceCenterUploader
          isAllowImageTypeFilesOnly
          initialFileList={selectedPhotos}
          initialCustomFileDetailList={selectedPhotosDetail}
          onSetSelectedFiles={handleSetSelectedFiles}
          onClose={handleToggleAddPhoto}
        />
      )}
    </div>
  )
}

export default FailedInspectionCorrection
