import React from "react";
import { Row, Col } from "reactstrap";
import { ChevronRight } from "react-feather";

import avatar from "assets/images/avatars/avatar-blank.png";
import { LANGUAGE_CONSTANTS } from "shared/language-constants";
import { getLocaleMessage } from "utility/Utils";
import localeMessageWrapper from "views/components/locale-message";

const { ACTIONS_PAGE } = LANGUAGE_CONSTANTS;

const ActionDetails = ({
  intl,
  item: {
    siteName,
    unitName,
    assignee,
    assigneeProfileUrl,
    actionStatus,
    dueDate,
  },
}) => {
  return (
    <div className="action-task-details">
      <Row>
        <Col md="4">
          <p>
            <span className="action-task-name">{getLocaleMessage(intl, ACTIONS_PAGE.LOCATION)}: </span>
            <span className="action-task-value">
              {siteName}
              <ChevronRight size={12} /> <span>{unitName}</span>
            </span>
          </p>
          <p>
            <span className="action-task-name">{getLocaleMessage(intl, ACTIONS_PAGE.DUE_DATE)}:{" "}</span>
            <span className="action-task-value">{dueDate}</span>
          </p>
        </Col>
        <Col md="4">
          <p>
            <span className="action-task-name">{getLocaleMessage(intl, ACTIONS_PAGE.STATUS)}:{" "}</span>
            <span className="action-task-value">{actionStatus}</span>
          </p>
          <p>
            <span className="action-task-name">
              {getLocaleMessage(intl, ACTIONS_PAGE.ASSIGNEE)}:
            </span>
            <span className="action-task-value">
              <img
                className="assignee-avatar"
                src={assigneeProfileUrl || avatar}
                alt=""
              />
              {assignee}
            </span>
          </p>
        </Col>
      </Row>
    </div>
  );
};

export default localeMessageWrapper(ActionDetails);
