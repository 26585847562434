import { LANGUAGE_CONSTANTS } from 'shared/language-constants'

const { ACTIONS_PAGE } = LANGUAGE_CONSTANTS

export const SECTION_TAB_TYPES = {
  OBSERVATION_DETAILS: 1,
  INSPECTION_DETAILS: 1,
  AUDIT_TRAIL: 2,
  ACTIVITY_DETAILS: 3
}

export const VERIFY_BY_SUPERINTENDENT_TAB_TYPES = {
  CORRECTION_DETAILS: 1,
  OBSERVATION_DETAILS: 2,
  AUDIT_TRAIL: 3,
  ACTIVITY_DETAILS: 4
}

export const REASSIGN_SECTION_TAB_TYPES = {
  ACTIVITY_DETAILS: 1,
  OBSERVATION_DETAILS: 2,
  AUDIT_TRAIL: 3
}

export const failedInspectionCorrectionDetailSectionTabs = [
  { id: SECTION_TAB_TYPES.OBSERVATION_DETAILS, name: ACTIONS_PAGE.OBSERVATION_DETAILS },
  { id: SECTION_TAB_TYPES.AUDIT_TRAIL, name: ACTIONS_PAGE.AUDIT_TRAIL },
  { id: SECTION_TAB_TYPES.ACTIVITY_DETAILS, name: ACTIONS_PAGE.ACTIVITY_DETAILS }
]

export const confirmSiteVisitDetailSectionTabs = [
  { id: SECTION_TAB_TYPES.INSPECTION_DETAILS, name: ACTIONS_PAGE.INSPECTION_DETAILS },
  { id: SECTION_TAB_TYPES.AUDIT_TRAIL, name: ACTIONS_PAGE.AUDIT_TRAIL }
]

export const verifyBySuperintendentDetailSectionTabs = [
  { id: VERIFY_BY_SUPERINTENDENT_TAB_TYPES.CORRECTION_DETAILS, name: ACTIONS_PAGE.CORRECTION_DETAILS },
  { id: VERIFY_BY_SUPERINTENDENT_TAB_TYPES.OBSERVATION_DETAILS, name: ACTIONS_PAGE.OBSERVATION_DETAILS },
  { id: VERIFY_BY_SUPERINTENDENT_TAB_TYPES.AUDIT_TRAIL, name: ACTIONS_PAGE.AUDIT_TRAIL },
  { id: VERIFY_BY_SUPERINTENDENT_TAB_TYPES.ACTIVITY_DETAILS, name: ACTIONS_PAGE.ACTIVITY_DETAILS }
]

export const reassignFailedInspectionCorrectionDetailSectionTabs = [
  { id: REASSIGN_SECTION_TAB_TYPES.ACTIVITY_DETAILS, name: ACTIONS_PAGE.ACTIVITY_DETAILS },
  { id: REASSIGN_SECTION_TAB_TYPES.OBSERVATION_DETAILS, name: ACTIONS_PAGE.OBSERVATION_DETAILS },
  { id: REASSIGN_SECTION_TAB_TYPES.AUDIT_TRAIL, name: ACTIONS_PAGE.AUDIT_TRAIL }
]