import { getDataApi } from "services/api"
import config from "../../config"

/**
 * @method getActionUnitListDDL
 *
 */
export const getActionUnitListDDL = (data) => {
  const { url } = config.getActionUnitListDDL
  return getDataApi(url(data))
}

export default getActionUnitListDDL
