import { all } from "redux-saga/effects"

import auditTrailSagas from "store/sagas/audit-trail"
import activityDetailsSagas from "store/sagas/activity-details"
import actionListSagas from '../sagas/actions-page'
import correctionDetailsSagas from '../sagas/correction-details'

export default function* rootSagas() {
  yield all([
    actionListSagas(),
    auditTrailSagas(),
    activityDetailsSagas(),
    correctionDetailsSagas()
  ])
}
