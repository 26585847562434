import { useCallback } from "react"
import Select from "react-select"
import { FormGroup } from "reactstrap"
import { Controller } from "react-hook-form"

import { CustomLabel } from 'views/components'

const Placeholder = ({ placeholder }) => {
  return (
    <span style={{ color: "#B9B9C3" }}>
      {placeholder}
    </span>
  )
}

const ReactSelect = ({
  id,
  value,
  labelType = "text",
  valueType = "value",
  name,
  addressId,
  label,
  control,
  options,
  isRequired = false,
  noOptionsMessage,
  formGroupClass = "",
  menuPlacement = 'auto',
  addCategoryButton = false,
  onSelect,
  onFocus,
  isDisabled = false,
  isInitialSelectOptionRequired = true,
  /**
   * If isFormattedValue = true, means value is in following format :-
   *  {[lableType]: 'label here', [valueType]: 'value here }
   *
   * This property is for single select.
   */
  isFormattedValue = false,
  ...props
}) => {
  const defaultOption = {
    [labelType]: '— Select —', [valueType]: ""
  }

  const optionsArr = options?.length ? [defaultOption, ...options] : []
  const defaultText = props.placeHolder ? '' : { [labelType]: label, [valueType]: "" }
  const defaultValue = props.defaultValue || defaultText

  /**
   * @method handleCloseMenuOnScroll : To Close menu list if user scrolls away from menu list view
   * 
   */
  const handleCloseMenuOnScroll = useCallback((event) => {
    if (!!event?.target) {
      const { className } = event.target
      if (!!className && className.includes("select__menu-list")) {
        return false
      }
    }
    return true
  }, [])

  return <FormGroup className={formGroupClass}>
    <CustomLabel title={label} required={isRequired} />
    <Controller
      id={id}
      control={control}
      name={name}
      classNamePrefix="select"
      render={({
      }) => (
        <Select
          menuPlacement={menuPlacement}
          closeMenuOnScroll={handleCloseMenuOnScroll}
          getOptionLabel={option => option.text}
          getOptionValue={option => option.value}
          options={isInitialSelectOptionRequired ? optionsArr : options}
          className={'select-input'}
          classNamePrefix="select"
          placeholder={<Placeholder placeholder={props.placeHolder} />}
          defaultValue={defaultValue}
          onChange={(selectedOption) => onSelect(selectedOption, addressId)}
          onFocus={onFocus}
          isDisabled={isDisabled}
          noOptionsMessage={noOptionsMessage}
          value={!!props.isMulti ? value : isFormattedValue ? value || null : value ? optionsArr.find(op => op[valueType] === value) : defaultValue}
          closeMenuOnSelect={!props.isMulti}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          {...props}
        />
      )}
    />
  </FormGroup>
}

export default ReactSelect
