import moment from 'moment'

import { 
  ACTIVITY_TYPE,
  ACTIVITY_COLLECTION_RESPONSE_KEYS,
  ACTIVITY_COLLECTION_RESPONSE_TYPES,
  CHECKPOINT_NUMERIC_TYPES,
  GO_TO_LIST_TYPE
} from "shared/constants"

export const getResponseValue = ({
  isInitialValueFormatting,
  value,
  responseTypeId,
  responseNumericTypeId
}) => {
  if (!value) {
    return value
  }

  let selectedValue = ""
  let selectedKey = ""
  let otherOptionValue = ""

  switch (responseTypeId) {
    case ACTIVITY_COLLECTION_RESPONSE_TYPES.BOOLEAN:
      selectedValue = value[ACTIVITY_COLLECTION_RESPONSE_KEYS.BOOLEAN] || value["collectionOptionDtos"] || []
      selectedKey = ACTIVITY_COLLECTION_RESPONSE_KEYS.BOOLEAN
      break
    case ACTIVITY_COLLECTION_RESPONSE_TYPES.CHOICE_SINGLE_SELECT:
      selectedValue = value[ACTIVITY_COLLECTION_RESPONSE_KEYS.CHOICE_SELECT] || value["collectionOptionDtos"] || ""
      selectedKey = ACTIVITY_COLLECTION_RESPONSE_KEYS.CHOICE_SELECT
      otherOptionValue = value['otherOption']
      break
    case ACTIVITY_COLLECTION_RESPONSE_TYPES.CHOICE_MULTI_SELECT:
      selectedValue = value[ACTIVITY_COLLECTION_RESPONSE_KEYS.CHOICE_SELECT] || value["collectionOptionDtos"] || []
      selectedKey = ACTIVITY_COLLECTION_RESPONSE_KEYS.CHOICE_SELECT
      otherOptionValue = value['otherOption']
      break
    case ACTIVITY_COLLECTION_RESPONSE_TYPES.DATE:
      selectedValue = value[ACTIVITY_COLLECTION_RESPONSE_KEYS.DATE] || ""
      selectedKey = ACTIVITY_COLLECTION_RESPONSE_KEYS.DATE
      break
    case ACTIVITY_COLLECTION_RESPONSE_TYPES.FREE_TEXT:
      selectedValue = value[ACTIVITY_COLLECTION_RESPONSE_KEYS.FREE_TEXT] || ""
      selectedKey = ACTIVITY_COLLECTION_RESPONSE_KEYS.FREE_TEXT
      break
    case ACTIVITY_COLLECTION_RESPONSE_TYPES.NUMERIC:
      if (responseNumericTypeId.toString() === CHECKPOINT_NUMERIC_TYPES.NUMERIC_RANGE) {
        selectedValue = {
          minNumber: value[ACTIVITY_COLLECTION_RESPONSE_KEYS.NUMERIC_MIN_RANGE] || "",
          maxNumber: value[ACTIVITY_COLLECTION_RESPONSE_KEYS.NUMERIC_MAX_RANGE] || ""
        }
      } else {
        selectedValue = value[ACTIVITY_COLLECTION_RESPONSE_KEYS.NUMERIC_SINGLE] || ""
        selectedKey = ACTIVITY_COLLECTION_RESPONSE_KEYS.NUMERIC_SINGLE
      }
      break
    case ACTIVITY_COLLECTION_RESPONSE_TYPES.PHOTO:
      selectedValue = value[ACTIVITY_COLLECTION_RESPONSE_KEYS.PHOTO] || value["imageCollectionDtos"] || []
      selectedKey = ACTIVITY_COLLECTION_RESPONSE_KEYS.PHOTO
      break
    case ACTIVITY_COLLECTION_RESPONSE_TYPES.DDL_SINGLE_SELECT:
      selectedValue = value[ACTIVITY_COLLECTION_RESPONSE_KEYS.DDL] || value["collectionOptionDtos"] || ""
      selectedKey = ACTIVITY_COLLECTION_RESPONSE_KEYS.DDL
      otherOptionValue = value['otherOption']
      break
    case ACTIVITY_COLLECTION_RESPONSE_TYPES.DDL_MULTI_SELECT:
      selectedValue = value[ACTIVITY_COLLECTION_RESPONSE_KEYS.DDL] || value["collectionOptionDtos"] || []
      selectedKey = ACTIVITY_COLLECTION_RESPONSE_KEYS.DDL
      otherOptionValue = value['otherOption']
      break
    case ACTIVITY_COLLECTION_RESPONSE_TYPES.FORESITE_STANDARD:
      selectedValue = value[ACTIVITY_COLLECTION_RESPONSE_KEYS.FORESITE_STANDARD] || value["collectionOptionDtos"] || []
      selectedKey = ACTIVITY_COLLECTION_RESPONSE_KEYS.FORESITE_STANDARD
      break
    case ACTIVITY_COLLECTION_RESPONSE_TYPES.BUTTON:
      selectedValue = value[ACTIVITY_COLLECTION_RESPONSE_KEYS.BUTTON] || value["collectionOptionDtos"] || []
      selectedKey = ACTIVITY_COLLECTION_RESPONSE_KEYS.BUTTON
      break
    default: break
  }

  if (isInitialValueFormatting) {
    return {
      selectedValue,
      selectedKey,
      otherOptionValue
    }
  }

  return selectedValue
}

const getFormattedResponse = ({
  activityResponseListState,
  isPrimary,
  resQes
}) => {
  const result = resQes.inspectionResult ? resQes.inspectionResult[0] : null
  if (!result) {
    return null
  }

  const value = getResponseValue({
    isInitialValueFormatting: true,
    value: result,
    responseTypeId: resQes.responseTypeId,
    responseNumericTypeId: resQes.responseNumericTypeId
  })
  if (!!value) {
    const { selectedKey, selectedValue, otherOptionValue } = value
    let responseValue = {}
    if (resQes.responseTypeId === ACTIVITY_COLLECTION_RESPONSE_TYPES.NUMERIC && resQes.responseNumericTypeId.toString() === CHECKPOINT_NUMERIC_TYPES.NUMERIC_RANGE) {
      responseValue = {
        minNumber: selectedValue['minNumber'],
        maxNumber: selectedValue['maxNumber']
      }
    } else if (
      resQes.responseTypeId === ACTIVITY_COLLECTION_RESPONSE_TYPES.BOOLEAN ||
      resQes.responseTypeId ===
      ACTIVITY_COLLECTION_RESPONSE_TYPES.CHOICE_SINGLE_SELECT ||
      resQes.responseTypeId ===
      ACTIVITY_COLLECTION_RESPONSE_TYPES.DDL_SINGLE_SELECT ||
      resQes.responseTypeId ===
      ACTIVITY_COLLECTION_RESPONSE_TYPES.FORESITE_STANDARD ||
      resQes.responseTypeId ===
      ACTIVITY_COLLECTION_RESPONSE_TYPES.BUTTON
    ) {
      responseValue = {
        [selectedKey]: selectedValue[0].responseOptionId
      }
    } else if (
      resQes.responseTypeId ===
      ACTIVITY_COLLECTION_RESPONSE_TYPES.CHOICE_MULTI_SELECT
    ) {
      responseValue = {
        [selectedKey]: selectedValue.map(i => i.responseOptionId)
      }
    } else if (
      resQes.responseTypeId ===
      ACTIVITY_COLLECTION_RESPONSE_TYPES.PHOTO
    ) {
      responseValue = {
        [selectedKey]: selectedValue.map(i => ({
          imageFile: i.imageUrl,
          isDeleted: false,
          imageId: i.imageId,
          externalResources: i.externalResources,
          isExternal: i.isExternal
        }))
      }
    } else if (
      resQes.responseTypeId ===
      ACTIVITY_COLLECTION_RESPONSE_TYPES.DDL_MULTI_SELECT
    ) {
      responseValue = {
        [selectedKey]: selectedValue.map(i => ({
          value: i.responseOptionId,
          text: resQes.responseOptions.find(j => j.responseOptionId === i.responseOptionId)?.options || ""
        }))
      }
    } else {
      responseValue = {
        [selectedKey]: selectedValue
      }
    }

    if (otherOptionValue) {
      responseValue = {
        ...responseValue,
        otherOption: otherOptionValue || ''
      }
    }

    if (isPrimary) {
      return {
        value: resQes.responseTypeId === ACTIVITY_COLLECTION_RESPONSE_TYPES.NUMERIC && resQes.responseNumericTypeId.toString() === CHECKPOINT_NUMERIC_TYPES.NUMERIC_RANGE ?
          {
            minNumber: selectedValue['minNumber'],
            maxNumber: selectedValue['maxNumber']
          }
          :
          responseValue[selectedKey],
        initialValue: {
          ...activityResponseListState,
          responseId: resQes.responseId,
          responseTypeId: resQes.responseTypeId,
          ...responseValue
        }
      }
    }

    return {
      ...activityResponseListState,
      responseId: resQes.responseId,
      responseTypeId: resQes.responseTypeId,
      ...responseValue
    }
  }

  return null
}

export const getInitialActivityPrimaryResponseDetails = ({
  primaryResponse,
  activityResponseListState
}) => {
  return getFormattedResponse({
    activityResponseListState,
    isPrimary: true,
    resQes: primaryResponse
  })
}

export const getInitialActivityAdditionalResponseDetails = ({
  additionalResponse,
  activityResponseListState
}) => {
  const updatedDetail = []
  additionalResponse.forEach(resQes => {
    const value = getFormattedResponse({
      activityResponseListState,
      resQes
    })
    if (value) {
      updatedDetail.push(value)
    }
  })

  return updatedDetail
}

/**
 * @method compareValue
 * x : Value responsed by user
 * y : gotoAnswer
 * operator
 * 
 * @returns boolean
 */
const compareValue = ({
  x,
  y,
  operator,
  primaryResponse
}) => {
  let res = false

  switch (primaryResponse.responseTypeId) {
    case ACTIVITY_COLLECTION_RESPONSE_TYPES.BOOLEAN:
    case ACTIVITY_COLLECTION_RESPONSE_TYPES.CHOICE_SINGLE_SELECT:
    case ACTIVITY_COLLECTION_RESPONSE_TYPES.DDL_SINGLE_SELECT:
    case ACTIVITY_COLLECTION_RESPONSE_TYPES.FORESITE_STANDARD:
    case ACTIVITY_COLLECTION_RESPONSE_TYPES.BUTTON:
      res = x === y
      break
    case ACTIVITY_COLLECTION_RESPONSE_TYPES.CHOICE_MULTI_SELECT:
      res = x?.includes(y) || false
      break
    case ACTIVITY_COLLECTION_RESPONSE_TYPES.DDL_MULTI_SELECT:
      res = x?.some(i => i.value === y) || false
      break
    case ACTIVITY_COLLECTION_RESPONSE_TYPES.DATE:
      if (operator === 'Primary is before') {
        res = x && y ? moment.utc(x) < moment.utc(y) : false
      } else if (operator === 'Primary is equal to') {
        res = x && y ? moment.utc(x) === moment.utc(y) : false
      } else if (operator === 'Primary is after') {
        res = x && y ? moment.utc(x) > moment.utc(y) : false
      }
      break
    case ACTIVITY_COLLECTION_RESPONSE_TYPES.FREE_TEXT:
    case ACTIVITY_COLLECTION_RESPONSE_TYPES.PHOTO:
      res = !!x?.length || false
      break
    case ACTIVITY_COLLECTION_RESPONSE_TYPES.NUMERIC:
      if (x && y) {
        if (primaryResponse.responseNumericTypeId.toString() === CHECKPOINT_NUMERIC_TYPES.NUMERIC_RANGE) {
          const yValue = y.split(',')
          const yMinValue = Number(yValue[0])
          const yMaxValue = Number(yValue[1])
          if (primaryResponse.numericMin <= Number(x.minNumber) &&
            Number(x.minNumber) < primaryResponse.numericMax &&
            primaryResponse.numericMin < Number(x.maxNumber) &&
            Number(x.maxNumber) <= primaryResponse.numericMax
          ) {
            if (operator === 'Primary is greater than') {
              res = Number(x.minNumber) > yMinValue && Number(x.maxNumber) > yMaxValue
            } else if (operator === 'Primary is greater than or equal to') {
              res = Number(x.minNumber) >= yMinValue && Number(x.maxNumber) >= yMaxValue
            } else if (operator === 'Primary is equal to') {
              res = Number(x.minNumber) === yMinValue && Number(x.maxNumber) === yMaxValue
            } else if (operator === 'Primary is less than') {
              res = Number(x.minNumber) < yMinValue && Number(x.maxNumber) < yMaxValue
            } else if (operator === 'Primary is less than or equal to') {
              res = Number(x.minNumber) <= yMinValue && Number(x.maxNumber) <= yMaxValue
            }
          }
        } else {
          if (operator === 'Primary is greater than') {
            res = Number(x) > Number(y)
          } else if (operator === 'Primary is greater than or equal to') {
            res = Number(x) >= Number(y)
          } else if (operator === 'Primary is equal to') {
            res = Number(x) === Number(y)
          } else if (operator === 'Primary is less than') {
            res = Number(x) < Number(y)
          } else if (operator === 'Primary is less than or equal to') {
            res = Number(x) <= Number(y)
          }
        }
      }
      break
    default: res = false
  }

  return res
}

export const getAdditionalResponseIdList = ({
  primaryResponse,
  primaryQuestionUpdatedResVal
}) => {
  const goToConditionValue = primaryResponse.gotoConditions

  if (!goToConditionValue || !goToConditionValue.length) {
    return []
  }

  let additionalList = []

  if (GO_TO_LIST_TYPE.SINGLE.includes(primaryResponse.responseTypeId)) {
    const selectedCondition = goToConditionValue.find(cond => {
      return compareValue({
        x: primaryQuestionUpdatedResVal,
        y: cond.gotoAnswer,
        operator: cond.operator,
        primaryResponse
      })
    })

    if (selectedCondition) {
      const updatedList = selectedCondition.goto || []
      additionalList = [...updatedList]
    }
  } else if (GO_TO_LIST_TYPE.MULTIPLE.includes(primaryResponse.responseTypeId)) {
    goToConditionValue.forEach(cond => {
      const isMatched = compareValue({
        x: primaryQuestionUpdatedResVal,
        y: cond.gotoAnswer,
        operator: cond.operator,
        primaryResponse
      })

      if (isMatched) {
        const updatedList = cond.goto || []
        additionalList = [...additionalList, ...updatedList]
      }
    })
  }

  return additionalList
}

export const getAdditionalQuesForPrimaryResponse = ({
  primaryQesResponse,
  primaryResponseQes,
  primaryResponseVal,
  activity,
  activityResponseListState,
  isUpdate = false,
  existingActivityQuestionDetail = {}
}) => {
  let additionalResponseQes = []
  let additionalResponseValue = []
  let additionalHiddenResponseValue = []

  let activityQuestionItem = {}
  let activityResponseItem = []

  const gotoAnswerIdList = getAdditionalResponseIdList({
    primaryResponse: primaryResponseQes,
    primaryQuestionUpdatedResVal: primaryResponseVal
  })

  if (gotoAnswerIdList.length) {
    additionalResponseQes = gotoAnswerIdList.length ? activity.responseCollectionDtos.filter(
      (q) => !!gotoAnswerIdList.includes(q.responseId)
    ) : []

    additionalHiddenResponseValue = additionalResponseQes.filter((item) => item.responseTypeId === ACTIVITY_COLLECTION_RESPONSE_TYPES.HIDDEN_FIELD)
    if (additionalHiddenResponseValue.length) {
      additionalResponseQes = additionalResponseQes.filter((item) => item.responseTypeId !== ACTIVITY_COLLECTION_RESPONSE_TYPES.HIDDEN_FIELD)
    }
    additionalResponseValue = getInitialActivityAdditionalResponseDetails({
      additionalResponse: additionalResponseQes,
      activityResponseListState: Object.assign({}, activityResponseListState)
    })
  }

  const activityId = activity.checkpointId
  const activityVersionId = activity.checkpointVersionId

  //todo: check if needs to update complete full object or only additional
  if (isUpdate) {
    activityQuestionItem = {
      ...existingActivityQuestionDetail,
      additionalQes: additionalResponseQes
    }
    activityResponseItem = [
      primaryQesResponse,
      ...additionalResponseValue
    ]
  } else {
    activityQuestionItem = {
      activityId,
      activityVersionId,
      assignmentId: activity.assignmentId,
      assignmentActivityId: activity.assignmentActivityId,
      activityReviewNotes: activity.activityReviewNotes,
      isUpdated: false,
      primaryQes: primaryResponseQes,
      additionalQes: additionalResponseQes
    }
    activityResponseItem = [
      primaryQesResponse,
      ...additionalResponseValue
    ]
  }

  return {
    activityQuestionItem,
    activityResponseItem,
    hiddenResponses: additionalHiddenResponseValue
  }
}

export const checkIfActivityFailed = (primaryResponseQes = {}, selectedResponseVal = "", hiddenResponses = []) => {
  if (primaryResponseQes.responseTypeId === ACTIVITY_COLLECTION_RESPONSE_TYPES.FORESITE_STANDARD) {
    const options = primaryResponseQes.responseOptions || []
    const isUnapproved = options.some(op => op.responseOptionId === selectedResponseVal && (op.options === "Unapproved" || op.options === "no aprobado"))
    if (isUnapproved) {
      return true
    } else if (hiddenResponses.some(res => res.responseName?.toLowerCase().includes('fail'))) {
      return true
    }
  } else if (hiddenResponses.some(res => res.responseName?.toLowerCase().includes('fail'))) {
    return true
  }

  return false
}

const currentDueDate = () => {
  const currentDate = moment()
  const dateData = currentDate.add(2, 'days').toISOString()
  return dateData
}

/**
 * @method getFormattedTradeActivityCorrections
 * @param {Object} data => activity: {}
 * 
 * return tradeActivityCorrections: []
 */
export const getFormattedTradeActivityCorrections = (
  activity
) => {
  if (!!activity.activityTradeContacts?.length) {
    return activity.activityTradeContacts.map(tradeDetail => {
      return {
        ...tradeDetail,
        activityTradeId: tradeDetail.activityTradeId?.toString(),
        dueDate: tradeDetail.dueDate ? tradeDetail.dueDate : currentDueDate()
      }
    })
  }

  if (!!activity.activityTradeId) {
    return [
      {
        ...tradeCorrectionInitialDetails,
        assignmentActivityFailureId: '0',
        activityTradeId: activity.activityTradeId?.toString(),
        activityTradeName: activity.activityTradeName,
        activityTradeContactId: activity.activityTradeContactId,
        dueDate: activity.dueDate ? activity.dueDate : currentDueDate()
      }
    ]
  }

  return [tradeCorrectionInitialDetails]
}

export const getActivityPrimaryResponseList = ({
  activityList,
  activityResponseListState,
  isTradeCorrectionNeeded
}) => {
  const primaryResponseList = []
  /**
    * activityQuestionList =>
    * {
    * [activityId_activityVersionId]: {
    *     activityId: '',
    *     activityVersionId: '',
    *     assignmentId: '',
    *     isUpdated: false,
    *     primaryQes: {},
    *     additionalQes: [],
    * 
    *   },
    *  .....
    * }
    * 
    */
  const activityQuestionList = {}

  /**
    * activityResponseList =>
    * {
    * [activityId_activityVersionId]: [
    *   primaryQesResponse,
    *   {...additionalQesResponse},
    * 
    * ],
    *  .....
    * }
    * 
    */
  const activityResponseList = {}

  /**
    * activityTradeCorrectionList: Contains boolean value for if required to show trade correction or not based on failure criteria
    * {
    *  [activityId_activityVersionId]: false
    * }
    */
  const activityTradeCorrectionList = {}
  /**
   * To set initial state for already saved trade corrections for activities
   */
  const tradeActivityCorrections = {}
   /**
   * To set initial state for already saved notes and media data for activities
   */
   const activityNotesMediasData = {}

  //Note: No need to format question and responses for already formatted data if next page loaded
  let activities = activityList

  const observationActivityList = activities.filter(activity => activity.activityTypeId.toString() === ACTIVITY_TYPE.OBSERVATION)
  observationActivityList.forEach(activity => {
    const primaryResponseQes = activity.responseCollectionDtos?.find(
      (q) => q.isPrimary
    )
    let primaryQesResponse = {}

    const result = getInitialActivityPrimaryResponseDetails({
      primaryResponse: primaryResponseQes,
      activityResponseListState
    })

    const activityId = activity.checkpointId
    const activityVersionId = activity.checkpointVersionId

    if (result) {
        primaryQesResponse = result.initialValue

        const {
          activityQuestionItem,
          activityResponseItem,
          hiddenResponses = []
        } = getAdditionalQuesForPrimaryResponse({
          primaryQesResponse,
          primaryResponseQes,
          primaryResponseVal: result.value,
          activity,
          activityResponseListState
        })

        activityQuestionList[`${activityId}_${activityVersionId}`] = activityQuestionItem
        activityResponseList[`${activityId}_${activityVersionId}`] = activityResponseItem

        activityNotesMediasData[`${activityId}_${activityVersionId}`] = activity.activityNoteMedias || []

        if (isTradeCorrectionNeeded) {
          activityTradeCorrectionList[`${activityId}_${activityVersionId}`] = checkIfActivityFailed(primaryResponseQes, result.value, hiddenResponses)

          if (activityTradeCorrectionList[`${activityId}_${activityVersionId}`]) {
            tradeActivityCorrections[`${activityId}_${activityVersionId}`] = getFormattedTradeActivityCorrections(activity)
          }
        }
    } else {
        activityQuestionList[`${activityId}_${activityVersionId}`] = {
          activityId,
          activityVersionId,
          assignmentId: activity.assignmentId,
          assignmentActivityId: activity.assignmentActivityId,
          activityReviewNotes: activity.activityReviewNotes,
          isUpdated: false,
          primaryQes: primaryResponseQes,
          additionalQes: []
        }
        activityResponseList[`${activityId}_${activityVersionId}`] = []
        activityNotesMediasData[`${activityId}_${activityVersionId}`] = activity.activityNoteMedias || []
    }
  })

  return {
    primaryResponseList,
    activityQuesList: activityQuestionList,
    activityResList: activityResponseList,
    activityTradeCorrections: activityTradeCorrectionList,
    tradeActivityCorrections,
    activityNotesMediasData
  }
}