import React from 'react'
import { Route, Redirect } from 'react-router-dom'

import { ROUTES } from "shared/constants"
import ActionsListPage from 'views/pages/actions'
import ActionDetailsPage from 'views/pages/actions/action-detail-view'

const ActionRoutes = () => {
  return (
    <>
      <Route
        exact
        path='/'
        render={() => {
          return <Redirect to={ROUTES.actionsOverview} />
        }}
      />   
      <Route exact path={ROUTES.actionsOverview}>
        <ActionsListPage />
      </Route>
      <Route exact path={ROUTES.actionsDetails}>
        <ActionDetailsPage />
      </Route>
    </>
  )
}

export default ActionRoutes