import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { ROUTES } from "shared/constants"
import InitialPage from "views/pages/misc/InitialPage"
import NotAuthorized from "views/pages/misc/NotAuthorized"
import { Routes } from './routes'

const Router = ({ isLoading }) => {
  if (isLoading) {
    return <InitialPage />
  }

  return (
      <Switch>
        <Route exact path={ROUTES.notAuthorised}>
          <NotAuthorized />
        </Route>
        <Routes />
      </Switch>
  )
}

export default Router
