import cx from "classnames";
import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";

const ForesiteStandardResponse = ({
  responseOptions = [],
  value,
}) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (responseOptions.length) {
      const formattedOptions = responseOptions.map((op) => {
        let labelData = op.options;
        if (op.options === "N/A") {
          labelData = "NA";
        }
        return {
          text: op.options,
          value: op.responseOptionId,
          label: labelData,
        };
      });
      setOptions(formattedOptions);
    }
  }, [responseOptions]);

  const selectedValue = options.find((item) => item.value === value);

  return (
    <div className="foresite_standard_options">
      {selectedValue && (
        <Button
          disabled
          className={cx(
            `mr-2 common-button btn-${selectedValue.label.toLowerCase()}`
          )}
          color={"primary"}
        >
          {selectedValue.text}
        </Button>
      )}
    </div>
  );
};

export default ForesiteStandardResponse;
