import React, { useCallback } from "react"
import { Button } from "reactstrap"
import localeMessageWrapper from "../../components/locale-message"
import { getLocaleMessage } from "utility/Utils"

const ActionButton = ({
  intl,
  hasLeftIcon,
  hasRightIcon,
  isDisabled,
  isRequired,
  isAuditAction,
  isConfirmAction,
  isViewAction,
  isSubmitAction,
  color,
  className,
  type,
  labelLocaleMessageId = "",
  localeMessageValues = {},
  IconComponent,
  onClick = () => { }
}) => {
  const handleClick = useCallback(() => {
    if (isViewAction || isConfirmAction || isAuditAction) {
      onClick()
    } else {
      onClick(type)
    }
  }, [isConfirmAction, isViewAction, type, onClick])

  return (
    <Button
      disabled={isDisabled}
      // outline={!isConfirmAction && !isSubmitAction}
      color={color}
      className={className}
      onClick={handleClick}
    >
      {hasLeftIcon && <IconComponent size={15} />}{" "}
      {getLocaleMessage(intl, labelLocaleMessageId, localeMessageValues)}
      {isRequired && <span className={`text-danger required-field`}>*</span>}
      {hasRightIcon && <IconComponent size={15} />}{" "}
    </Button>
  )
}

export default localeMessageWrapper(ActionButton)
