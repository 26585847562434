import { getDataApi } from "services/api"
import config from "../../config"

/**
 * @method getActionListVM
 *
 */
export const getActionListVM = () => {
  const { url } = config.getActionListVM
  return getDataApi(url)
}

export default getActionListVM
