import { LANGUAGE_CONSTANTS } from "shared/language-constants";
import { getLocaleMessage } from "utility/Utils";
import localeMessageWrapper from "views/components/locale-message";

const {
  LOADER: { LOADING },
} = LANGUAGE_CONSTANTS;

const InitialPage = ({ intl }) => {
  return (
    <div className="misc-wrapper">
      <div className="misc-inner p-2 p-sm-3">
        <div className="w-100 text-center">
          <h2 className="mb-1">{getLocaleMessage(intl, LOADING)}</h2>
        </div>
      </div>
    </div>
  );
};

export default localeMessageWrapper(InitialPage);
