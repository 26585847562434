import React, { useCallback, useMemo, useState } from "react"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter
} from "reactstrap"
import { useDropzone } from "react-dropzone"
import PropTypes from 'prop-types'
import { LANGUAGE_CONSTANTS } from "shared/language-constants"
import { cloneArrayOfObject, getLocaleMessage } from "utility/Utils"
import { ErrorMessage } from "views/components"
import localeMessageWrapper from "views/components/locale-message"
import AcceptedFileList from './AcceptedFileList'
import {
  ACCEPTED_FILE_TYPES,
  ACCEPTED_IMAGE_FILE_TYPES,
  ACTION_TYPE,
  FILE_UPLOAD_ERROR_TYPES,
  MAX_NAME_LENGTH
} from "./config"
import { fileSelectionValidator, getCustomFileDetailList, validateFileData } from './helpers'
import * as styles from './styles'


const { ACTIONS_PAGE: { ADD, ADD_AND_CLOSE, CANCEL, RESOURCES, UPLOAD } } = LANGUAGE_CONSTANTS

//todo: edit mode for files
const ResourceCenterUploader = ({
  actionType = ACTION_TYPE.UPLOAD,
  isAllowImageTypeFilesOnly = false,
  initialFileList = [],
  initialCustomFileDetailList = [],
  isSingleUpload = false,
  intl,
  onClose = () => { },
  onSetSelectedFiles = () => { }
}) => {
  const [fileList, setFileList] = useState(initialFileList)
  const [errors, setErrors] = useState({})
  const [customFileDetailList, setCustomFileDetailList] = useState(initialCustomFileDetailList)
  const [isEditMode] = useState(!!initialFileList?.length)

  const handleDropFile = useCallback(
    (acceptedFileList, rejectedFileList) => {
      if (!!rejectedFileList?.length) {
        const rejectionErrors = {}
        rejectedFileList.forEach(item => {
          if (
            item?.errors[0]?.code === FILE_UPLOAD_ERROR_TYPES.FILE_TOO_LARGE &&
            !rejectionErrors[FILE_UPLOAD_ERROR_TYPES.FILE_TOO_LARGE]?.length
          ) {
            rejectionErrors[FILE_UPLOAD_ERROR_TYPES.FILE_TOO_LARGE] =
              item.errors[0].message
          }
        })
        setErrors(rejectionErrors)
      } else if (Object.keys(errors)) {
        setErrors({})
      }

      if (actionType === ACTION_TYPE.UPLOAD) {
        setFileList([...fileList, ...acceptedFileList])
        setCustomFileDetailList([
          ...customFileDetailList,
          ...getCustomFileDetailList(acceptedFileList)
        ])
      }
    },
    [actionType, customFileDetailList, errors, fileList]
  )

  const { isDragAccept, isDragReject, isFocused, getRootProps, getInputProps } = useDropzone({
    accept: isAllowImageTypeFilesOnly ? ACCEPTED_IMAGE_FILE_TYPES : ACCEPTED_FILE_TYPES,
    validator: (file) => fileSelectionValidator(file, intl),
    onDrop: handleDropFile,
    multiple: !isSingleUpload
  })

  const style = useMemo(() => ({
    ...styles.baseStyle,
    ...(isFocused ? styles.focusedStyle : {}),
    ...(isDragAccept ? styles.acceptStyle : {}),
    ...(isDragReject ? styles.rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ])

  const handleChangeDetails = useCallback((
    key, value, index
  ) => {
    if (Object.keys(errors)) {
      setErrors({})
    }
    const updatedFileList = cloneArrayOfObject(customFileDetailList)
    const extLength = updatedFileList[index].extension?.length || 0
    if (key === 'fileName' && value.trim().length > (MAX_NAME_LENGTH - extLength)) {
      return
    }
    updatedFileList[index][key] = value
    setCustomFileDetailList(updatedFileList)
  }, [errors, customFileDetailList])

  const handleRemoveDocument = useCallback((index) => {
    if (Object.keys(errors)) {
      setErrors({})
    }
    const updatedCFileList = cloneArrayOfObject(customFileDetailList)
    updatedCFileList.splice(index, 1)
    setCustomFileDetailList(updatedCFileList)

    const updatedFileList = [...fileList]
    updatedFileList.splice(index, 1)
    setFileList(updatedFileList)
  }, [errors, customFileDetailList, fileList])

  const handleAddFile = useCallback(() => {
    if (customFileDetailList.length) {
      const errors = validateFileData({ intl, customFileDetailList })
      if (!!Object.keys(errors).length) {
        setErrors(errors)
        return
      }
    }
    onSetSelectedFiles({
      fileList,
      customFileDetailList
    })
    onClose()
  }, [customFileDetailList, fileList, isEditMode, onClose, onSetSelectedFiles])

  return (
    <Modal isOpen className="modal-dialog-centered all-modal upload-modal">
      <ModalBody className="p-0">
        <div className="rc-uploader-tabs">
          <span
            className={"active"}
          >
            {getLocaleMessage(intl, UPLOAD)}
          </span>
        </div>
        {(
          <>
            <div>
              <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <p>
                  {getLocaleMessage(intl, RESOURCES.RESOURCE_CENTER_UPLOADER_DESCRIPTION)}
                </p>
                <p>
                  ({getLocaleMessage(intl, RESOURCES.RESOURCE_CENTER_UPLOADER_SUB_DESCRIPTION)})
                </p>
              </div>
            </div>
            <ErrorMessage
              isShow={!!errors[FILE_UPLOAD_ERROR_TYPES.FILE_TOO_LARGE]}
              message={errors[FILE_UPLOAD_ERROR_TYPES.FILE_TOO_LARGE]}
            />
            <div>
              <AcceptedFileList
                errors={errors}
                intl={intl}
                selectedFiles={customFileDetailList}
                onChangeDetails={handleChangeDetails}
                onRemoveDocument={handleRemoveDocument}
              />
            </div>
          </>
        )}
      </ModalBody>
      <ModalFooter className="justify-content-center border-0 p-0">
        <Button outline className="delete-btn mr-1 " onClick={onClose}>
          {getLocaleMessage(intl, CANCEL)}
        </Button>
        {(
          <Button
            className="bg-btn"
            disabled={(!isEditMode) && !fileList.length}
            onClick={handleAddFile}
          >
            {getLocaleMessage(
              intl,
              isEditMode ? ADD_AND_CLOSE : ADD
            )}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  )
}

ResourceCenterUploader.propTypes = {
  actionType: PropTypes.string,
  intl: PropTypes.object,
  isAllowImageTypeFilesOnly: PropTypes.bool,
  initialFileList: PropTypes.array,
  initialCustomFileDetailList: PropTypes.array,
  onClose: PropTypes.func
}

export default localeMessageWrapper(ResourceCenterUploader)
