import cx from 'classnames'
import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  useContext,
} from "react"
import { Container } from "reactstrap"
import { ChevronUp, ChevronDown } from 'react-feather'
import { useDispatch, useSelector } from "react-redux"

import { COUNTRY_CODE_TYPE, LANGUAGE_TYPE } from "shared/constants"
import { LANGUAGE_CONSTANTS } from "shared/language-constants"
import * as actions from "store/actions";
import { IntlContext } from "utility/context/Internationalization"
import { getLocaleMessage, toDataURL } from "utility/Utils"
import { OverviewSectionTabs, ObservationDetails } from "views/components"
import localeMessageWrapper from "views/components/locale-message"
import ActionsView from "./ActionsView"
import CorrectionDetails from "./correction-details"
import Details from "../ActionDetails"
import {
  VERIFY_BY_SUPERINTENDENT_TAB_TYPES,
  verifyBySuperintendentDetailSectionTabs,
} from "../config";
import { ActivityDetails, AuditTrial } from "../../components"
import {
  ACTION_TYPES,
  VERIFY_BY_SUPERINTENDENT_RESPONSE_TYPES,
} from "../../config";
const { ACTIONS_PAGE, ACTIONS_DESCRIPTION } = LANGUAGE_CONSTANTS;

const getActionsDetailSectionComponent = (activeSectionTabId) => {
  switch (activeSectionTabId) {
    case VERIFY_BY_SUPERINTENDENT_TAB_TYPES.CORRECTION_DETAILS:
      return CorrectionDetails
    case VERIFY_BY_SUPERINTENDENT_TAB_TYPES.OBSERVATION_DETAILS:
      return ObservationDetails
    case VERIFY_BY_SUPERINTENDENT_TAB_TYPES.AUDIT_TRAIL:
      return AuditTrial
    case VERIFY_BY_SUPERINTENDENT_TAB_TYPES.ACTIVITY_DETAILS:
      return ActivityDetails
    default:
      return CorrectionDetails
  }
}

const VerifyBySuperindendent = ({ intl, item, isToggleDetails, onGoBack = () => { }, onToggleDetails = () => { } }) => {
  const dispatch = useDispatch()
  const intlContext = useContext(IntlContext);
  const { isMobileView } = useSelector((state) => state.appConfig)

  const [selectedActionType, setSelectedActionType] = useState("")
  const [isShowSubmitActionView, setShowSubmitActionView] = useState(false)
  const [isShowAddNoteView, setShowAddNoteView] = useState(false)
  const [noteDetail, setNoteDetail] = useState("");
  const [selectedPhotos, setSelectedPhotos] = useState([])
  const [selectedPhotosDetail, setSelectedPhotosDetail] = useState([])
  const [correctionDetails, setCorrectionDetails] = useState({})
  const [activeSectionTabId, setActiveSectionTabId] = useState(
    VERIFY_BY_SUPERINTENDENT_TAB_TYPES.CORRECTION_DETAILS
  );

  useEffect(() => {
    dispatch(
      actions.getCorrectionDetailsRequest(
        {
          actionId: item.actionId,
          language:
            intlContext.locale === COUNTRY_CODE_TYPE.SPANISH
              ? `${LANGUAGE_TYPE.SPANISH}`
              : `${LANGUAGE_TYPE.ENGLISH}`
        },
        (res) => {
          if (res) {
            setCorrectionDetails(res)
          } else {
            setActiveSectionTabId(VERIFY_BY_SUPERINTENDENT_TAB_TYPES.OBSERVATION_DETAILS)
          }
        }
      )
    )
  }, [])

  const handleGoToOnSubmittingAction = useCallback(() => {
    onGoBack();
  }, [onGoBack]);

  const handleShowConfirmActionView = useCallback((value) => {
    setSelectedActionType(value || "");
  }, []);

  const handleResetState = useCallback(() => {
    setSelectedActionType("");
    setShowSubmitActionView(false);
    setShowAddNoteView(false);
    setNoteDetail("");
    setSelectedPhotos([]);
    setSelectedPhotosDetail([]);
  }, []);

  const handleUpdateVerificationStatus = useCallback(
    (data) => {
      const payload = {
        wfActionId: item.actionId,
        ...data,
      };
      dispatch(
        actions.updateVerifyBySuperintendentRequest(payload, (res) => {
          if (res) {
            handleResetState();
            handleGoToOnSubmittingAction();
          }
        })
      );
    },
    [item, handleGoToOnSubmittingAction, handleResetState]
  );

  const handleConfirmAction = useCallback(() => {
    setShowSubmitActionView(true);
  }, []);

  const handleToggleAddNote = useCallback(() => {
    setShowAddNoteView(!isShowAddNoteView);

    //Todo: On hide note view , earlier note needs to be kept or not?
    if (isShowAddNoteView) {
      setNoteDetail("");
    }
  }, [isShowAddNoteView]);

  const handleChangeNoteDetail = useCallback((event) => {
    setNoteDetail(event.target.value);
  }, []);

  const handleSetSelectedFiles = useCallback(
    ({ fileList, customFileDetailList }) => {
      setSelectedPhotos(fileList);
      setSelectedPhotosDetail(customFileDetailList);
    },
    []
  );

  const handleSubmitAndClose = useCallback(async () => {
    if (selectedActionType === ACTION_TYPES.VERIFY_BY_SUPERINTENDENT.APPROVE) {
      handleUpdateVerificationStatus({
        verifyBySuperintendent: VERIFY_BY_SUPERINTENDENT_RESPONSE_TYPES.ACCEPT,
        note: "",
        images: [],
      });
    } else {
      const prevPhotos = [];
      for (let i = 0; i < selectedPhotos.length; i++) {
        const result = await toDataURL(URL.createObjectURL(selectedPhotos[i]));
        const { fileName, fileDescription, extension } =
          selectedPhotosDetail[i];
        prevPhotos.push({
          fileName: `${fileName}${extension}`,
          imageContent: result.split(",").pop(),
          imageId: 0,
          fileDescription,
          isDeleted: false,
        });
      }
      const payload = {
        verifyBySuperintendent:
          VERIFY_BY_SUPERINTENDENT_RESPONSE_TYPES.NOT_ACCEPT,
        note: noteDetail,
        images: prevPhotos,
      };
      handleUpdateVerificationStatus(payload);
    }
  }, [
    noteDetail,
    selectedPhotos,
    selectedPhotosDetail,
    handleUpdateVerificationStatus,
  ]);

  const handleSelectTab = useCallback((activeTabId) => {
    setActiveSectionTabId(activeTabId);
  }, []);

  const Component = getActionsDetailSectionComponent(activeSectionTabId)

  const tabList = useMemo(() => {
    const actionDetailSection = [...verifyBySuperintendentDetailSectionTabs];
    return !!Object.keys(correctionDetails).length
      ? actionDetailSection
      : actionDetailSection.splice(1, 3);
  }, [correctionDetails]);

  return (
    <div className={cx("action-detail-outer", {
      "mobile-view-detail-outer": isMobileView
    })}>
      <Container>
        <div className="details-view">
          <div className="action-detail-list">
            <div
              className={cx("", {
                "action-header": isMobileView
              })}>

              <h6 className="action-detail-title">
                {item.taskOrActivityName}
              </h6>

              {isMobileView && <button
                type="button"
                className="advanced-btn"
                onClick={onToggleDetails}
              >
                {isToggleDetails ? <ChevronUp /> : <ChevronDown />}
              </button>}
            </div>
            <p className="action-detail-sub-title">{getLocaleMessage(intl, ACTIONS_PAGE.VERIFY_BY_SUPERINDENDENT)}</p>
            <p>{getLocaleMessage(intl, ACTIONS_DESCRIPTION)}</p>
          </div>

          {isMobileView ? isToggleDetails ? <Details item={item} /> : "" : <Details item={item} />}

          <ActionsView
            item={item}
            isMobileView={isMobileView}
            selectedActionType={selectedActionType}
            isShowSubmitActionView={isShowSubmitActionView}
            selectedPhotos={selectedPhotos}
            selectedPhotosDetail={selectedPhotosDetail}
            actionStatus={item.actionStatus}
            isShowAddNoteView={isShowAddNoteView}
            noteDetail={noteDetail}
            onChangeNoteDetail={handleChangeNoteDetail}
            onToggleAddNote={handleToggleAddNote}
            onConfirmAction={handleConfirmAction}
            onSetSelectedFiles={handleSetSelectedFiles}
            onShowConfirmActionView={handleShowConfirmActionView}
            onSubmitAndClose={handleSubmitAndClose}
          />
        </div>
      </Container>
      <div className="section-tabs-border">
        <Container>
          <div className="mb-1 mt-1 mobile-tabs-scroll">
            <OverviewSectionTabs
              activeTabId={activeSectionTabId}
              tabsList={tabList}
              isBorderBottomRequired={false}
              onChangeTab={handleSelectTab}
            />
          </div>

          {!!Component && <Component
            actionId={item.actionId}
            correctionDetails={correctionDetails}
            observationTaskId={item.observationTaskId}
          />
          }
        </Container>
      </div>
    </div>
  );
};

export default localeMessageWrapper(VerifyBySuperindendent);
