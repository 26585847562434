import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { ToastContainer } from 'react-toastify'
// ** styles
import './index.css';
import './assets/scss/style.scss'
import App from './App';
import { store, persistor } from './store';
import { IntlProviderWrapper } from './utility/context/Internationalization'

ReactDOM.render(
    <IntlProviderWrapper>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ToastContainer newestOnTop />
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </IntlProviderWrapper>
,
  document.getElementById('root')
);