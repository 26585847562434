import React from "react";
import { FormGroup, Input } from "reactstrap";

import {
  CHECKPOINT_NUMERIC_TYPES,
  ACTIVITY_COLLECTION_RESPONSE_KEYS,
} from "shared/constants";
import { LANGUAGE_CONSTANTS } from "shared/language-constants";
import { getLocaleMessage } from "utility/Utils";
import { getNumericResponsePlaceholder } from "../Helpers";

const {
   RESPONSES_PLACEHOLDER_TEXT
} = LANGUAGE_CONSTANTS;

const NumericResponse = ({
  intl,
  responseNumericTypeId = "",
  responseNumericValidationId,
  value,
}) => {
  if (
    responseNumericTypeId.toString() === CHECKPOINT_NUMERIC_TYPES.NUMERIC_RANGE
  ) {
    return (
      <>
        <div className="range-inputbox">
          <Input
            name={ACTIVITY_COLLECTION_RESPONSE_KEYS.NUMERIC_MIN_RANGE}
            disabled
            placeholder={getNumericResponsePlaceholder(
              intl,
              responseNumericValidationId
            )}
            className="mr-1"
            value={value?.minNumber || ""}
            onChange={() => {}}
          />
          <span>{getLocaleMessage(intl, RESPONSES_PLACEHOLDER_TEXT.TO)}</span>
          <div className="range-field">
            <Input
              name={ACTIVITY_COLLECTION_RESPONSE_KEYS.NUMERIC_MAX_RANGE}
              disabled
              placeholder={getNumericResponsePlaceholder(
                intl,
                responseNumericValidationId
              )}
              value={value?.maxNumber || ""}
              onChange={() => {}}
            />
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="response-field">
        <FormGroup>
          <Input
            id="singleNumber"
            name="singleNumber"
            disabled
            rows="3"
            value={value}
            placeholder={getNumericResponsePlaceholder(
              intl,
              responseNumericValidationId
            )}
            onChange={() => {}}
          />
        </FormGroup>
      </div>
    </>
  );
};

export default NumericResponse;
