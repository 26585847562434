export const SET_IS_MOBILE_VIEW = "SET_IS_MOBILE_VIEW"
export const SET_IS_LOADING = 'SET_IS_LOADING'

/****************************************************/
/** SET_IS_LOADING **/
/****************************************************/

/**
 * @method setIsLoading :  To set loading
 *
 */
export const setIsLoading = (isLoading) => {
  return {
    type: SET_IS_LOADING,
    payload: isLoading
  }
}

/****************************************************/
/** SET_IS_MOBILE_VIEW **/
/****************************************************/

/**
 * @method setMobileViewRequest :  To set mobile view
 *
 */
export const setMobileViewRequest = (payload) => {
  return {
    type: SET_IS_MOBILE_VIEW,
    payload
  }
}