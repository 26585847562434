import React, { useCallback, useState } from "react"
import { useDispatch } from "react-redux"

import * as actions from "store/actions"
import ActionsView from "./ActionsView"
import Details from "./Details"

const ReassignFailedInspectionCorrection = ({
  item,
  isOverviewCardView,
  onViewMore = () => { }
}) => {
  const dispatch = useDispatch()

  const [assignedContact, setAssignedContact] = useState({})
  const [isShowFullActivityName, setShowFullActivityName] = useState(false)
  const [isShowFullNotes, setShowFullNotes] = useState(false)

  const handleResetState = useCallback(() => {
    setAssignedContact({})
  }, [])

  const handleSubmitAndClose = useCallback((actionId = item.actionId) => {

    if (actionId) {
      dispatch(actions.updateReassignFailedInspectionRequest({ actionId, contactId: assignedContact.contactId, roleId: assignedContact.roleId }, () => {
        handleResetState()
      }))
    }
  }, [assignedContact, item, handleResetState])

  const handleViewMore = useCallback(() => {
    onViewMore(item.actionId)
  }, [item.actionId, onViewMore])

  const handleChangeDetails = useCallback((value) => {
    setAssignedContact(value)
  }, [])

  const handleShowFullActivityName = useCallback(() => {
    if (isShowFullActivityName) {
      return
    }
    setShowFullActivityName(true)
  }, [isShowFullActivityName])

  const handleShowFullNotes = useCallback(() => {
    if (isShowFullNotes) {
      return
    }
    setShowFullNotes(true)
  }, [isShowFullNotes])

  return (
    <div className="action-card">
      <Details item={item}
        isShowFullActivityName={isShowFullActivityName}
        isShowFullNotes={isShowFullNotes}
        onShowFullNotes={handleShowFullNotes}
        onShowFullActivityName={handleShowFullActivityName}
      />
      <ActionsView
        actionId={item.actionId}
        actionStatus={item.actionStatus}
        assignedContact={assignedContact}
        isOverviewCardView={isOverviewCardView}
        onChangeDetails={handleChangeDetails}
        onSubmitAndClose={handleSubmitAndClose}
        onViewMore={handleViewMore}
      />
    </div>
  )
}

export default ReassignFailedInspectionCorrection
