// ** React Imports
import { useState, createContext } from 'react'

// ** Intl Provider Import
import { IntlProvider } from 'react-intl'

// ** Core Language Data
import messagesEn from '@core/assets/data/locales/en.json'
import messagesEs from '@core/assets/data/locales/es.json'

// ** User Language Data
import userMessagesEn from 'assets/data/locales/en.json'
import userMessagesEs from 'assets/data/locales/es.json'

export const DEFAULT_LANGUAGE = "English"
/**
 * @constant PREFERRED_LANGUAGES : To define list of languages available for preferrence in foresite
 */
export const PREFERRED_LANGUAGES = [
  { text: "English", value: "en", countryCode: "us" },
  { text: "Español", value: "es", countryCode: "es" }
]

// ** Menu msg obj
const menuMessages = {
  en: { ...messagesEn, ...userMessagesEn },
  es: { ...messagesEs, ...userMessagesEs }
}

// ** Create Context
const Context = createContext()

const IntlProviderWrapper = ({ children }) => {

  const langText = DEFAULT_LANGUAGE
  const langValue = PREFERRED_LANGUAGES.find(lang => lang.text === langText)?.value || ''

  // ** States
  const [locale, setLocale] = useState(langValue)
  const [messages, setMessages] = useState(menuMessages[langValue])

  // ** Switches Language
  const switchLanguage = lang => {
    setLocale(lang)
    setMessages(menuMessages[lang])
  }

  return (
    <Context.Provider value={{ locale, switchLanguage }}>
      <IntlProvider key={locale} locale={locale} messages={messages} defaultLocale={langValue}>
        {children}
      </IntlProvider>
    </Context.Provider>
  )
}

export { IntlProviderWrapper, Context as IntlContext }
