import moment from "moment"
import React, { useCallback, useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux"

import * as actions from "store/actions"
import { getDateTimeFormat, getStartTimeOptions } from "views/helpers"
import ActionsView from "./ActionsView"
import Details from "./Details"
import { ACTION_TYPES } from "../../../config"

const ConfirmSiteVisit = ({
  item,
  isOverviewCardView,
  onViewMore = () => { },
  onRefreshActionList = () => { }
}) => {
  const { control } = useForm()
  const dispatch = useDispatch()

  const [selectedActionType, setSelectedActionType] = useState("")
  const [isShowSubmitActionView, setShowSubmitActionView] = useState(false)
  const [confirmSiteReadyDate, setConfirmSiteReadyDate] = useState(null)
  const [confirmSiteReadyTime, setConfirmSiteReadyTime] = useState(null)
  const [isShowFullActivityName, setShowFullActivityName] = useState(false)
  const [isShowFullNotes, setShowFullNotes] = useState(false)

  const timeOptions = useMemo(() => {
    return getStartTimeOptions(confirmSiteReadyDate || new Date())
  }, [confirmSiteReadyDate])

  const assignedStartTime = useMemo(() => {
    const temp = confirmSiteReadyTime
      ? moment.utc(confirmSiteReadyTime).format("LT")
      : ""
    return temp
      ? timeOptions.find((option) => option.text === temp)?.value || ""
      : ""
  }, [confirmSiteReadyTime])

  const handleResetState = useCallback(() => {
    setSelectedActionType("")
    setShowSubmitActionView(false)
    setConfirmSiteReadyDate(null)
    setConfirmSiteReadyTime(null)
  }, [])

  const handleChangeDetails = useCallback((key, value) => {
    if (key === "readyDate") {
      setConfirmSiteReadyDate(value)
    } else {
      setConfirmSiteReadyTime(value)
    }
  }, [])

  const handleShowConfirmActionView = useCallback((value) => {
    setSelectedActionType(value || "")
    setShowSubmitActionView(false)
  }, [])

  const handleConfirmAction = useCallback(() => {
    if (selectedActionType === ACTION_TYPES.CONFIRM_SITE_VISIT.NOT_READY) {
      setShowSubmitActionView(true)
    } else {
      const payload = {
        observationTaskId: item.observationTaskId,
        isConfirmSiteVisit: true
      }
      dispatch(
        actions.createConfirmSiteVisitRequest(payload, (res) => {
          if (res) {
            onRefreshActionList()
            handleResetState()
          }
        })
      )
    }
  }, [
    item,
    selectedActionType,
    handleResetState,
    onRefreshActionList
  ])

  const handleViewMore = useCallback(() => {
    onViewMore(item.actionId)
  }, [item.actionId, onViewMore])

  const handleSubmitAndClose = useCallback(() => {
    const payload = {
      observationTaskId: item.observationTaskId,
      isConfirmSiteVisit: false
    }

    if (confirmSiteReadyDate) {
      payload.confirmSiteReadyDate = getDateTimeFormat(confirmSiteReadyDate)
    }

    if (confirmSiteReadyTime) {
      payload.confirmSiteReadyTime = moment(confirmSiteReadyTime).format('YYYY-MM-DD HH:mm:ss').replace(' ', 'T')
    }

    dispatch(
      actions.createConfirmSiteVisitRequest(payload, (res) => {
        if (res) {
          onRefreshActionList()
          handleResetState()
        }
      })
    )
  }, [
    confirmSiteReadyDate,
    confirmSiteReadyTime,
    item,
    handleResetState,
    onRefreshActionList
  ])

  const handleShowFullActivityName = useCallback(() => {
    if (isShowFullActivityName) {
      return
    }
    setShowFullActivityName(true)
  }, [isShowFullActivityName])

  const handleShowFullNotes = useCallback(() => {
    if (isShowFullNotes) {
      return
    }
    setShowFullNotes(true)
  }, [isShowFullNotes])


  return (
    <div className="action-card">
      <Details
        item={item}
        isShowSubmitActionView={isShowSubmitActionView}
        selectedActionType={selectedActionType}
        isShowFullActivityName={isShowFullActivityName}
        isShowFullNotes={isShowFullNotes}
        onShowFullNotes={handleShowFullNotes}
        onShowFullActivityName={handleShowFullActivityName}
      />
      <ActionsView
        actionId={item.actionId}
        actionStatus={item.actionStatus}
        assignedStartTime={assignedStartTime}
        confirmSiteReadyDate={confirmSiteReadyDate}
        control={control}
        isOverviewCardView={isOverviewCardView}
        isShowSubmitActionView={isShowSubmitActionView}
        selectedActionType={selectedActionType}
        timeOptions={timeOptions}
        onChangeDetails={handleChangeDetails}
        onConfirmAction={handleConfirmAction}
        onShowConfirmActionView={handleShowConfirmActionView}
        onSubmitAndClose={handleSubmitAndClose}
        onViewMore={handleViewMore}
      />
    </div>
  )
}

export default ConfirmSiteVisit
