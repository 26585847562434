export const GET_CORRECTION_DETAILS_REQUEST = 'GET_CORRECTION_DETAILS_REQUEST'

/****************************************************/
/** GET_CORRECTION_DETAILS_REQUEST **/
/****************************************************/

/**
 * @method getCorrectionDetailsRequest :  To get the correction details
 *
 */
export const getCorrectionDetailsRequest = (payload, callback = () => { }) => {
  return {
    type: GET_CORRECTION_DETAILS_REQUEST,
    payload,
    callback
  }
}