import React from "react"

import localeMessageWrapper from "views/components/locale-message"
import SiteList from "./site-list"

const MobileActionListOverview = ({ intl }) => {
  return (
    <SiteList intl={intl} />
  )
}

export default localeMessageWrapper(MobileActionListOverview)
