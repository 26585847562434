import React from "react";

import TradeCorrectionItem from "./TradeCorrectionItem";

const TradeCorrection = ({
  directoryVM = {},
  tradeCorrectionDetails = [],
  isDisableUpdateActivityResponse,
}) => {
  return (
    <div className="trade-correction-view">
      {tradeCorrectionDetails.map((item, index) => {
        if (item.isDeleted) {
          return null;
        }
        return (
          <TradeCorrectionItem
            key={`trade_correction_${index}`}
            item={item}
            directoryVM={directoryVM}
            isDisabledView={isDisableUpdateActivityResponse}
          />
        );
      })}
    </div>
  );
};

export default TradeCorrection;
