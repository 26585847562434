export const SET_AUTH_TOKEN = "SET_AUTH_TOKEN"
export const SET_ACCESS_DENIED = 'SET_ACCESS_DENIED'
export const RESET_ACCESS_DENIED = 'RESET_ACCESS_DENIED'

/****************************************************/
/** SET_AUTH_TOKEN **/
/****************************************************/

/**
 * @method setAuthTokenRequest :  To set token
 *
 */
export const setAuthTokenRequest = (payload) => {
  return {
    type: SET_AUTH_TOKEN,
    payload
  }
}

/****************************************************/
/** SET_ACCESS_DENIED **/
/****************************************************/

/**
* @method setAccessDenied : To set user access denied when user token has expired
*/
export const setAccessDenied = () => {
  return {
    type: SET_ACCESS_DENIED
  }
}

/****************************************************/
/** RESET_ACCESS_DENIED **/
/****************************************************/

/**
* @method resetAccessDenied : To reset user access denied state when logout 
*/
export const resetAccessDenied = () => {
  return {
    type: RESET_ACCESS_DENIED
  }
}
