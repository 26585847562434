import React, { useEffect, useState } from "react";
import { CustomInput } from "reactstrap";

const BooleanResponse = ({ responseId, value, responseOptions = [] }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    //Todo: set based on actual language and actual api json
    if (responseOptions.length) {
      const formattedOptions = responseOptions.map((op) => ({
        text: op.options,
        value: op.responseOptionId,
      }));
      setOptions(formattedOptions);
    }
  }, [responseOptions]);

  return (
    <div className="mx-1 mb-1 mb-md-0 mx-md-0">
      {options.map((option, index) => {
        return (
          <div
            key={`booleanResponseInput_${option.value}_${index}`}
            className="response-input response-disabled"
          >
            <CustomInput
              type="radio"
              id={`booleanResponse_${option.value}_${index}`}
              name={`booleanResponse_${responseId}`}
              label={option.text}
              disabled
              checked={value === option.value}
              onChange={() => {}}
            />
          </div>
        );
      })}
    </div>
  );
};

export default BooleanResponse;
