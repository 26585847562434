import cx from "classnames";
import React, { useCallback, useState, useMemo, useEffect } from "react";
import { Col, Row } from "reactstrap";

import { LANGUAGE_CONSTANTS } from "shared/language-constants";
import { getLocaleMessage } from "utility/Utils";
import { CustomReactSelect } from "views/components";
import localeMessageWrapper from "views/components/locale-message";
import AddNoteCard from "./AddNoteCard";

const {
  TRADE_CORRECTION
} = LANGUAGE_CONSTANTS;

const TradeCorrectionItem = ({
  intl,
  item,
  directoryVM: { trades = {}, contacts = [] },
  isDisabledView,
}) => {
  const [isShowAddNote, setShowAddNote] = useState(false);
  const [tradeContacts, setTradeContacts] = useState(contacts);

  useEffect(() => {
    if (!!item.noteId && item.note) {
      setShowAddNote(true);
    }
  }, [item.note]);

  useEffect(() => {
    setTradeContacts(contacts);
  }, [contacts]);

  const handleChangeTradeDetails = useCallback(() => {}, []);

  const tradeOptions = useMemo(() => {
    return trades.items || [];
  }, [trades.items]);

  const tradeContactOptions = useMemo(() => {
    const tradeId = Number(item.activityTradeId);
    const updatedContacts = tradeContacts.filter(
      (contact) => Number(contact.organizationId) === tradeId
    );

    // Check if contactId exists in the updatedContacts array
    const contactIdExists = updatedContacts.some(
      (contact) => contact.contactId === item.activityTradeContactId
    );

    // If contactId does not exist, add it to the array
    if (!contactIdExists) {
      updatedContacts.push({
        organizationId: item.activityTradeId,
        contactId: item.activityTradeContactId,
        contactName: item.activityTradeContactName,
      });
    }

    return updatedContacts;
  }, [item.activityTradeId, tradeContacts]);

  return (
    <div
      className={cx("ml-2 mr-2 trade-correction-item", {
        disabled: isDisabledView,
      })}
    >
      <div className="trade-detail-outer">
        <h5 className="trade-details">
          {getLocaleMessage(intl, TRADE_CORRECTION.TRADE_CORRECTION_DETAILS)}
        </h5>
      </div>
      <Row className="position-relative">
        {isDisabledView && <div className="trade-correction-overlay" />}
        <Col sm={12} md={6} xl={4}>
          <CustomReactSelect
            hideSelectedOptions={false}
            isInitialSelectOptionRequired
            isPaginationRequired={false}
            isShowCustomAction={false}
            id="activityTradeId"
            name="activityTradeId"
            value={item.activityTradeId}
            options={tradeOptions}
            defaultOptions={tradeOptions}
            lableType={"text"}
            valueType={"value"}
            isDisabled
            getOptionLabel={(item) => item.text}
            getOptionValue={(item) => item.value}
            placeholder={getLocaleMessage(intl, TRADE_CORRECTION.SELECT_TRADE)}
            onSelect={handleChangeTradeDetails}
          />
        </Col>
        <Col sm={12} md={6} xl={4}>
          <CustomReactSelect
            hideSelectedOptions={false}
            isInitialSelectOptionRequired
            isPaginationRequired={false}
            id="activityTradeContactId"
            name="activityTradeContactId"
            value={item.activityTradeContactId}
            options={tradeContactOptions}
            defaultOptions={tradeContactOptions}
            lableType={"contactName"}
            valueType={"contactId"}
            isDisabled
            getOptionLabel={(item) => item.contactName}
            getOptionValue={(item) => item.contactId}
            placeholder={getLocaleMessage(
              intl,
              TRADE_CORRECTION.SELECT_TRADE_CONTACT
            )}
            onSelect={handleChangeTradeDetails}
          />
        </Col>
      </Row>
      {!isShowAddNote && isDisabledView ? null : (
        <div className={cx("attach-note", { disabled: isDisabledView })}>
          {isDisabledView && <div className="trade-correction-overlay" />}
          {isShowAddNote && (
            <AddNoteCard value={item.note || ""} isTradeEditMode={false} />
          )}
        </div>
      )}
    </div>
  );
};

export default localeMessageWrapper(TradeCorrectionItem);
