import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import PropTypes from 'prop-types'

export const LocaleMessage = ({ id, values }) => {
  if (!id) {
    return ''
  }
  return <FormattedMessage id={id} defaultMessage={id} values={values} />
}

const localeMessageWrapper = (component) => injectIntl(component, { forwardRef: true })

LocaleMessage.propTypes = {
  id: PropTypes.string,
  values: PropTypes.object
}

LocaleMessage.defaultProps = {
  values: {}
}

export default localeMessageWrapper
