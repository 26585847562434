import { call, takeEvery } from "redux-saga/effects"

import { ACTIVITY_TYPE } from 'shared/constants'
import * as apiServices from "services/api/activity-details"
import * as actions from "../../actions"

/** getChecklistChildrenList API */
function* getChecklistChildrenListRequest(action) {
  try {
    const response = yield call(apiServices.getChecklistChildrenList, action.payload)
    if (response?.data) {
      action.callback({ ...response.data, pageSize: action.payload.pageSize })
    } else {
      action.callback()
    }
  } catch (error) {
    action.callback()
  }
}

/** getCheckpointChildDetail API */
function* getCheckpointChildDetailRequest(action) {
  try {
    const { activityTypeId, ...payload } = action.payload
    /**
     * Note:
     * For Training Activity : activityTypeId = 2
     * For Observation Activity (i.e Checkpoint) : activityTypeId = 1
     * */
    const response = yield call(Number(activityTypeId) === Number(ACTIVITY_TYPE.TRAINING) ? apiServices.getTemplateTrainingActivityChildDetail : apiServices.getCheckpointChildDetail, payload)
    action.callback(response?.data || [])
  } catch (error) {
    action.callback()
  }
}

export function* activityDetailsSagas() {
  yield takeEvery(actions.GET_CHECKLIST_CHILDREN_LIST_REQUEST, getChecklistChildrenListRequest)
  yield takeEvery(actions.GET_CHECKPOINT_CHILD_DETAIL_REQUEST, getCheckpointChildDetailRequest)
}

export default activityDetailsSagas