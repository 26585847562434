import React, { useEffect, useState, useCallback, useRef } from 'react'
import { Row, Col, Container } from "reactstrap";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom"

import * as actions from "store/actions"

import { SEARCH_HISTORY_LIST_PAGE_KEYS } from 'shared/constants';
import ActionCards from './ActionCards';
import ActionFilter from './Filters';
import { checkIfSearchFiltersApplied, getAdvanceSearchData } from './helper';
import { setActionDetailsSearchParams, getInitialSearchState } from '../helpers';

export const initialActionListState = {
  list: [],
  pageNumber: 0,
  pageSize: 10,
  totalCount: 0,
  totalPages: 0,
  hasNextPage: false
}

const INITIAL_VM_STATE = {
  actionType: [],
  assignedBy: []
}

const ACTION_SEARCH_DETAIL = {
  actionType: null,
  siteType: null,
  siteUnitType: null,
  assignedBy: null,
  builder: null
}

const ActionsListOverview = ({ isToggleDetails, isMobileView, onToggleDetails = () => { } }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const searchHistory = useSelector(state => state.searchHistory[SEARCH_HISTORY_LIST_PAGE_KEYS.ACTIONS])
  const searchHistoryRef = useRef({})

  const [cardViewActionsList, setCardViewActionsList] = useState(
    Object.assign({}, initialActionListState)
  )
  const [actionListVM, setActionListVM] = useState(Object.assign({}, INITIAL_VM_STATE))
  const [searchDetail, setSearchDetail] = useState(getInitialSearchState({ initialSearchDetail: { ...ACTION_SEARCH_DETAIL }, searchHistory }))


  const [isFetching, setFetchingList] = useState(false)
  const [isSearchApplied, setSearchApplied] = useState(false)
  const [isFetchingMore, setFetchingListMore] = useState(false)

  const handleResetCardViewActionList = useCallback(() => {
    setCardViewActionsList(Object.assign({}, initialActionListState))
  }, [])

  const handleFetchCardViewActionList = useCallback((data) => {
    const {
      page = 1,
      size,
      isAutoAdvanceSearch = false,
      advanceSearchDetail = {},
      isResetAdvanceSearch = false,
      isLoadMore = false
    } = data || {}

    let advanceSearch = {}
    let isSearchApplied = false
    const searchFormData = isAutoAdvanceSearch ?
      advanceSearchDetail : searchDetail

    if (!isResetAdvanceSearch) {
      advanceSearch = getAdvanceSearchData({ searchFormData })
      searchHistoryRef.current.advanceSearch = searchFormData
      isSearchApplied = checkIfSearchFiltersApplied(searchFormData)
    } else {
      isSearchApplied = false
    }
    setSearchApplied(isSearchApplied)
    searchHistoryRef.current.isSearchApplied = isSearchApplied

    const payload = {
      pageNumber: page || 1,
      pageSize: size,
      advanceSearch
    }

    if (isLoadMore) {
      setFetchingListMore(true)
    } else {
      setFetchingList(true)
    }

    dispatch(
      actions.getActionListRequest(payload, (data) => {
        if (data) {
          setCardViewActionsList((prevOptions) => {
            return {
              list:
                page === 1
                  ? data.items
                  : [...prevOptions.list, ...data.items],
              pageSize: data.pageSize,
              pageNumber: data.pageIndex,
              totalCount: data.totalCount,
              totalPages: data.totalPages,
              hasNextPage: data.hasNextPage
            }
          })
        }
        if (isLoadMore) {
          setFetchingListMore(false)
        } else {
          setFetchingList(false)
        }
      })
    )
  }, [searchDetail])


  useEffect(() => {
    const handleSaveSearchHistory = () => {
      const searchHistoryDetail = searchHistoryRef.current
      if (searchHistoryDetail || searchHistoryDetail.isSearchApplied) {
        dispatch(actions.updateListPageSearchHistoryRequest({
          keyName: SEARCH_HISTORY_LIST_PAGE_KEYS.ACTIONS,
          searchValues: {
            advanceSearch: searchHistoryDetail.isSearchApplied ? searchHistoryDetail.advanceSearch : null
          }
        }))
      }
    }
    window.addEventListener("beforeunload", handleSaveSearchHistory)

    return () => {
      window.removeEventListener("beforeunload", handleSaveSearchHistory)
      handleSaveSearchHistory()
    }
  }, [])

  const handleFetchList = useCallback(
    (params) => {
      const { ...data } = params || {}
      handleResetCardViewActionList()
      handleFetchCardViewActionList({
        page: cardViewActionsList.pageNumber,
        size: cardViewActionsList.pageSize,
        ...data
      })
    },
    [
      cardViewActionsList,
      handleFetchCardViewActionList,
      handleResetCardViewActionList
    ]
  )

  const handleFetchActionListVM = useCallback(() => {
    dispatch(actions.getActionListVMRequest((data) => {
      if (data) {
        setActionListVM((prev) => ({
          ...prev,
          actionType: data.actionType
        }))
      }
    }))
  }, [])

  const handleFetchEntityListVM = useCallback(() => {
    dispatch(actions.getEntityListRequest((data) => {
      if (data) {
        setActionListVM((prev) => ({
          ...prev,
          assignedBy: data
        }))
      }
    }))
  }, [])

  const handleAdvancedSearch = useCallback(
    (isAutoAdvanceSearch, data) => {
      const isAutoSearch = isAutoAdvanceSearch && !!data
      if (
        checkIfSearchFiltersApplied(isAutoSearch ? data : searchDetail) ||
        isSearchApplied
      ) {
        const autoSearchConfig = isAutoSearch
          ? { isAutoAdvanceSearch, advanceSearchDetail: data }
          : {}
        handleFetchList({ page: 1, ...autoSearchConfig })
      }
    },
    [searchDetail, isSearchApplied, handleFetchList]
  )

  const handleChangeDetails = useCallback(
    (key, value, valueType = "value", callback = () => { }) => {
      let updatedDetail = Object.assign({}, searchDetail)
      updatedDetail[key] = value?.value !== '' ? value : null

      if (value?.[valueType] === '') {
        if (key === 'assignedBy') {
          updatedDetail = Object.assign({}, ACTION_SEARCH_DETAIL)
        } else if (key === 'builder') {
          updatedDetail.siteType = null
          updatedDetail.siteUnitType = null
        } else if (key === 'siteType') {
          updatedDetail.siteUnitType = null
        }
      }

      setSearchDetail(updatedDetail)
      callback(updatedDetail)
    },
    [searchDetail]
  )

  const handleCustomAdvanceSearch = useCallback(
    (event, isVMBasedSearch, data) => {
      if (event?.keyCode === 13) {
        handleAdvancedSearch()
      } else if (isVMBasedSearch) {
        handleAdvancedSearch(true, data)
      }
    },
    [handleAdvancedSearch]
  )

  const handleResetSearchDetail = useCallback(() => {
    setSearchDetail(Object.assign({}, ACTION_SEARCH_DETAIL))
    if (isSearchApplied) {
      handleFetchList({ page: 1, isResetAdvanceSearch: true })
    }
  }, [isSearchApplied, handleFetchList])

  useEffect(() => {
    const payload = {
      page: 1
    }
    handleFetchList(payload)
    handleFetchActionListVM()
    handleFetchEntityListVM()
  }, [])

  const handleLoadMore = useCallback(() => {
    if (cardViewActionsList.list.length < cardViewActionsList.totalCount) {
      handleFetchCardViewActionList({
        page: cardViewActionsList.pageNumber + 1,
        size: cardViewActionsList.pageSize,
        isLoadMore: true
      })
    }
  }, [cardViewActionsList, handleFetchCardViewActionList])

  const handleRefreshActionList = useCallback(() => {
    handleFetchCardViewActionList({
      page: 1,
      size: cardViewActionsList.pageSize,
    })
  }, [cardViewActionsList, handleFetchCardViewActionList])

  const handleViewMoreDetail = useCallback((actionId) => {
    const queryParams = setActionDetailsSearchParams({
      actionId,
      isDetailView: true
      // observationTaskId: taskId //todo: check if 
    })
    /**
     * Note:
     * If actions list opened from Actions menu, use Route -> ROUTES.actionsDetails,
     * else if actions list used in overview pages of other sections, use concept of current route with action id
     */
    const { pathname } = window.location
    const searchString = `?${queryParams.toString()}`
    const previousHistory = history.location?.state
    history.push({
      pathname: `${pathname}/${actionId}`,
      search: searchString,
      state: previousHistory
    })
  }, [])

  return (
    <div className='action-list'>
      <Container>
        <Row className='justify-content-between'>
          <Col md={3}>
            {isMobileView ? isToggleDetails ? (
              <div
                className="filter-overlay"
                onClick={() => onToggleDetails(false)}
              >
                <ActionFilter
                  searchDetail={searchDetail}
                  actionListVM={actionListVM}
                  onChangeDetails={handleChangeDetails}
                  onCustomAdvanceSearch={handleCustomAdvanceSearch}
                  onResetSearchDetails={handleResetSearchDetail}
                />
              </div>
            ) : "" : <ActionFilter
              searchDetail={searchDetail}
              actionListVM={actionListVM}
              onChangeDetails={handleChangeDetails}
              onCustomAdvanceSearch={handleCustomAdvanceSearch}
              onResetSearchDetails={handleResetSearchDetail}
            />}
          </Col>
          <Col md={8}>
            <ActionCards
              actionList={cardViewActionsList}
              isFetching={isFetching}
              isFetchingMore={isFetchingMore}
              isSearchApplied={isSearchApplied}
              onLoadMore={handleLoadMore}
              onRefreshActionList={handleRefreshActionList}
              onViewMore={handleViewMoreDetail}
            />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default ActionsListOverview
