import PropTypes from 'prop-types'
import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Col, Row } from 'reactstrap'

import { DEFAULT_VM_PAGE_SIZE } from 'shared/constants'
import { LANGUAGE_CONSTANTS } from "shared/language-constants"
import * as actions from "store/actions"
import { getLocaleMessage } from "utility/Utils"
import { CustomReactSelect, ErrorMessage } from "views/components"
import localeMessageWrapper from "views/components/locale-message"

const { ACTIONS_PAGE: { SELECT_CONTACT } } = LANGUAGE_CONSTANTS

const ActionsTradeContactSelect = ({
  intl,
  isReassignFIC,
  actionId,
  value = "",
  errors,
  valueType,
  labelType,
  placeholder,
  getOptionLabel,
  getOptionValue,
  onSelect = () => { }
}) => {

  const dispatch = useDispatch()
  const [options, setOptions] = useState([])

  /**
   * Note: Paginated select maintain cache of options,
   * so to set new default options we need to reset previous cache
   */
  const [isResetCache, setResetCache] = useState(false)

  useEffect(() => {
    setResetCache((prevState) => !prevState)
  }, [])

  const handleLoadMore = useCallback((search, page, prevOptions) => {
    return new Promise((resolve) => {
      if (!actionId) {
        resolve({
          optionList: [],
          hasMore: false
        })
      }
      const params = {
        actionId,
        pageSize: DEFAULT_VM_PAGE_SIZE,
        pageNumber: page,
        contactName: search
      }
      if (isReassignFIC) {
        dispatch(actions.getReassignFailedInspectionContactRequest(params, (response) => {
          if (response) {
            const { items, hasNextPage } = response
            resolve({
              optionList: items,
              hasMore: hasNextPage
            })

            setOptions(() => (!!prevOptions.length ? ([...prevOptions, ...items]) : items))
          } else {
            resolve({
              optionList: [],
              hasMore: false
            })
          }
        })
        )
      } else {
        dispatch(actions.getTradeContactToReassignRequest(params, (response) => {
          if (response) {
            const { items, hasNextPage } = response
            resolve({
              optionList: items,
              hasMore: hasNextPage
            })

            setOptions(() => (!!prevOptions.length ? ([...prevOptions, ...items]) : items))
          } else {
            resolve({
              optionList: [],
              hasMore: false
            })
          }
        })
        )
      }
    })
  }, [isReassignFIC, actionId, options])

  const handleSelect = useCallback((selected) => {
    onSelect({ contactId: selected?.value || '', roleId: selected?.roleId || '' })
  }, [onSelect])

  return <Row>
    <Col sm={12} md={12} xl={12}>
      <CustomReactSelect
        additional={{
          page: 1
        }}
        cacheUniqs={[isResetCache]}
        id='tradeContactId'
        name='tradeContactId'
        placeholder={placeholder || getLocaleMessage(intl, SELECT_CONTACT)}
        options={options}
        defaultOptions={options}
        value={value}
        valueType={valueType}
        labelType={labelType}
        onSelect={handleSelect}
        onLoadOptions={handleLoadMore}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
      />
      {errors && <ErrorMessage isShow={!!errors['tradeContactId']} message={errors['tradeContactId']} />}
    </Col>
  </Row>
}

ActionsTradeContactSelect.propTypes = {
  actionId: PropTypes.number,
  isReassignFIC: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  errors: PropTypes.object,
  valueType: PropTypes.string,
  labelType: PropTypes.string,
  onSelect: PropTypes.func,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
}

ActionsTradeContactSelect.defaultProps = {
  actionId: 0,
  isReassignFIC: false,
  id: "",
  name: "",
  value: "",
  placeholder: "",
  valueType: "value",
  labelType: "text",
  onSelect: () => { },
  getOptionLabel: option => option.text,
  getOptionValue: option => option.value
}

export default localeMessageWrapper(ActionsTradeContactSelect)