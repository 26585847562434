import moment from "moment"
import React, { useCallback, useMemo, useState } from "react"
import { Row, Col } from "reactstrap"

import { LANGUAGE_CONSTANTS } from "shared/language-constants";
import { getLocaleMessage } from "utility/Utils";
import { ImageFullViewModal } from "views/components"

const {
  ACTIONS_PAGE
} = LANGUAGE_CONSTANTS

const CorrectionDetailItem = ({
  intl,
  data
}) => {
  const [isShowImageFullView, setShowImageFullView] = useState(false)
  const [selectedImageList, setSelectedImageList] = useState([])

  const handleToggleImageFullView = useCallback((images) => {
    setShowImageFullView(!isShowImageFullView)
    if (images) {
      setSelectedImageList(images)
    }
  }, [isShowImageFullView])

  const formattedDate = useMemo(() => !!data.createdDate ? moment(data.createdDate).format('MMMM D YYYY, hh:mm a') : '', [data.createdDate])

  return (
    <>
      <div className="audit-trail border-bottom-0">
        <Row>
          <Col xs={12} sm={12} md={2}>
            {!!data.images?.length && <>
              <img className="w-100" src={data.images[0].imageUrl} alt="auditTrail"
                onClick={() => handleToggleImageFullView(data.images)} />
              <div className="multiple-imgs" onClick={() => handleToggleImageFullView(data.images)}>
                {data.images.length > 1 && <img className="w-100" src={data.images[1].imageUrl} alt="auditTrail" />}
                {data.images.length > 2 && <span>+{data.images.length - 2}</span>}
              </div>
            </>}
          </Col>
          <Col xs={12} sm={12} md={10}>
            <div className="audit-trail-detail">
              <h6 className="audit-trail-item"><span>{getLocaleMessage(intl, ACTIONS_PAGE.CREATED)}</span>{formattedDate} </h6>
              <h6 className="audit-trail-item"><span>{getLocaleMessage(intl, ACTIONS_PAGE.SUBMITTER)}</span>{data.submitter}</h6>
              <h6 className="audit-trail-item"><span>{getLocaleMessage(intl, ACTIONS_PAGE.LOCATION)}</span>{data.location}</h6>
              <p>{data.note}</p>
            </div>
          </Col>
        </Row>
      </div>
      <ImageFullViewModal
        isOpen={isShowImageFullView}
        images={selectedImageList}
        onToggle={handleToggleImageFullView}
      />
    </>
  )
}

export default CorrectionDetailItem
