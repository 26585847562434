import { LANGUAGE_CONSTANTS } from "shared/language-constants"
import { getLocaleMessage } from "utility/Utils"
import { FILE_UPLOAD_ERROR_TYPES, MAX_FILE_SIZE } from '../config'

const { ACTIONS_PAGE: { RESOURCES } } = LANGUAGE_CONSTANTS

export const fileSelectionValidator = (file, intl) => {
  if (file.size > (MAX_FILE_SIZE * 1024 * 1024)) {
    return {
      code: FILE_UPLOAD_ERROR_TYPES.FILE_TOO_LARGE,
      message: getLocaleMessage(intl, RESOURCES.FILE_SIZE_INVALID)
    }
  }

  return null
}

export const getCustomFileDetailList = (acceptedFileList) => {
  return acceptedFileList.map(acceptedFile => {
    const lastIndex = acceptedFile.name.lastIndexOf('.')
    return ({
      fileName: lastIndex === -1 ? acceptedFile.name : acceptedFile.name.substr(0, lastIndex),
      extension: lastIndex === -1 ? '' : acceptedFile.name.substr(lastIndex),
      fileDescription: ''
    })
  })
}

export const validateFileData = ({
  customFileDetailList,
  intl
}) => {
  const error = {}
  customFileDetailList.forEach((cFile, index) => {
    if (!cFile.fileName.trim().length) {
      error[`fileName_${index}`] = getLocaleMessage(intl, RESOURCES.FILE_TITLE_REQUIRED)
    }
  })

  return error
}
