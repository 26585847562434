import React, { useMemo } from "react";
import { Row, Col } from "reactstrap";

import { ACTIVITY_COLLECTION_RESPONSE_TYPES } from "shared/constants";
import ResponseControls from "../observation-activity/response-controls";

const AdditionalResponseCard = ({
  activityResponseDetails,
  responses = [],
}) => {
  const filteredResponse = useMemo(
    () =>
      responses.filter(
        (item) =>
          item.responseTypeId !==
          Number(ACTIVITY_COLLECTION_RESPONSE_TYPES.HIDDEN_FIELD)
      ),
    [responses]
  );

  return (
    <div className="additional-response">
      {filteredResponse.map((responseQuestion, index) => (
        <div
          key={`${responseQuestion.responseId}`}
          className="response-question-list"
        >
          <p>
            {index + 1}. {responseQuestion.responseName}
            {!!responseQuestion.required && (
              <span className={"text-danger"}> *</span>
            )}
          </p>
          <div className="response-question">
            <Row>
              <Col
                lg={
                  responseQuestion.responseTypeId ===
                  ACTIVITY_COLLECTION_RESPONSE_TYPES.PHOTO
                    ? 12
                    : 7
                }
                md={6}
                sm={12}
              >
                <ResponseControls
                  responseQuestion={responseQuestion}
                  value={
                    (activityResponseDetails || []).find(
                      (res) => res.responseId === responseQuestion.responseId
                    ) || null
                  }
                  responseClassName="select-response"
                />
              </Col>
            </Row>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AdditionalResponseCard;
