import React from 'react'

import { LocaleMessage } from 'views/components/locale-message'
import { LANGUAGE_CONSTANTS } from 'shared/language-constants'

const ActionsHeader = () => {
  return (
    <div className='action-head'>
      <div>
        <h4>
          <LocaleMessage id={LANGUAGE_CONSTANTS.MY_ASSIGNED} /> : <LocaleMessage id={LANGUAGE_CONSTANTS.ACTIONS} />
        </h4>
      </div>
      <p>
        {<LocaleMessage id={LANGUAGE_CONSTANTS.ACTIONS_DESCRIPTION} />}
      </p>
    </div>
  )
}

export default ActionsHeader