import axios from "axios";

import * as actions from "store/actions"
import { Notification } from "views/components";
import { apiConfig } from "./config";
import { store } from "../../store";


export const handleNetworkError = (error, callback) => {
  if (error?.message?.toLowerCase().includes("network")) {
    Notification({
      status: 400,
      isLocalizedMessage: false,
      message: error.message,
    });
    setTimeout(() => {
      callback();
    }, 1000);
  } else {
    callback();
  }
};

export const handleUnauthorizedResponse = (error) => {
  const { response } = error
  if (error && (
    (error.status === 401) ||
    (error.statusCode === 401) ||
    (error.response?.data?.statusCode === 401) ||
    (response?.status === 401))
  ) {
    Notification({
      status: 400,
      isLocalizedMessage: false,
      message: 'Error'
    });

    const state = store.getState()
    if (!state.authDetail.isAccessDenied) {
      store.dispatch(actions.setAccessDenied())
      store.dispatch(actions.setIsLoading(false))
    }

  }
}

export const getHeaders = (tokenType = "Bearer") => {
  const auth = store.getState()?.authDetail
  const headers = {
    accept: "text/plain",
    'Ocp-Apim-Subscription-Key': apiConfig.API_SUBSCRIPTION_KEY
  }
  if (auth) {
    headers.Authorization = `${tokenType} ${auth.accessToken}`
  }

  return { headers }
};

const getBaseUrl = (path) => {
  if (path.startsWith("api/FSPublic")) {
    return apiConfig.BASE_URL;
  }

  return "";
};

const getApiUrl = (path = "") => {
  return `${getBaseUrl(path)}${path}`;
};

export const getDataApi = (
  path = "no-path-provided"
) => {

  try {
    return new Promise((resolve, reject) => {
      return axios
        .get(`${getApiUrl(path)}`, getHeaders())
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          handleNetworkError(error, () => {
            handleUnauthorizedResponse(error)
            reject(error);
          });
        });
    });
  } catch (error) {
    handleUnauthorizedResponse(error)
  }
};


export const postDataApi = (
  path = 'no-path-provided',
  data
) => {
  try {
    return new Promise((resolve, reject) => {
      axios
        .post(`${getApiUrl(path)}`, data, getHeaders())
        .then((response) => {
          handleUnauthorizedResponse(response)
          resolve(response)
        })
        .catch((error) => {
          handleNetworkError(error, () => {
            handleUnauthorizedResponse(error)
            reject(error)
          })
        })
    })
  } catch (error) {
    handleUnauthorizedResponse(error)
  }
}

export const putDataApi = (
  path = 'no-path-provided',
  data
) => {
  try {
    return new Promise((resolve, reject) => {
      axios
        .put(`${getApiUrl(path)}`, data, getHeaders())
        .then((response) => {
          handleUnauthorizedResponse(response)
          resolve(response)
        })
        .catch((error) => {

          handleNetworkError(error, () => {
            handleUnauthorizedResponse(error)
            reject(error)
          })
        })
    })
  } catch (error) {
    handleUnauthorizedResponse(error)
  }
}