import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Col } from "reactstrap";

import { LANGUAGE_CONSTANTS } from "shared/language-constants";
import * as actions from "store/actions";
import { getLocaleMessage } from "utility/Utils";
import localeMessageWrapper from "views/components/locale-message";
import AdditionalResponseCard from "./AdditionalResponseCard";
import PrimaryResponseCard from "./PrimaryResponseCard";
import { activityResponseListState } from "./config";
import { getActivityPrimaryResponseList } from "./Helpers";
import TradeCorrection from "./trade-correction"
import ViewNoteCard from "./ViewNoteCard";

const initialActivityListState = {
  items: [],
  pageNumber: 0,
  pageSize: 50,
  hasNextPage: false,
};

const initialDirectoryVM = {
  contacts: [],
  trades: {}
}

const ObservationDetails = ({ actionId, intl }) => {
  const dispatch = useDispatch();

  const [activityList, setActivityList] = useState(initialActivityListState);
  const [isFetching, setFetching] = useState(true);
  const [directoryVM, setDirectoryVM] = useState(initialDirectoryVM)
  const [isFormattingResponses, setFormattingResponses] = useState(true);
  /**
   * {
   * [activityId_activityVersionId]: [
   *   primaryQesResponse,
   *   {...additionalQesResponse},
   *
   * ],
   *  .....
   * }
   *
   */
  const [activityResponseList, setActivityResponseList] = useState({});
  /**
   * {
   * [activityId_activityVersionId]: {
   *     activityId: '',
   *     activityVersionId: '',
   *     assignmentId: '',
   *     isUpdated: false
   *     primaryQes: {},
   *     additionalQes: [],
   *
   *   },
   *  .....
   * }
   *
   */
  const [activityQuestionList, setActivityQuestionList] = useState({});
  // Activity Note and Media
  /**
   * activityNotesMediasList: Contains list of activity's notes and media
   * {
   *  [activityId_activityVersionId]: []
   * }
   */
  const [activityNotesMediasList, setActivityNotesMediasList] = useState({});
  // Activity Trade correction
  /**
   * {
   *  [activityId_activityVersionId]: [
   *   {
   *     ...tradeCorrectionInitialDetails
   *   }
   *  ]
   * }
   */
  const [tradeActivityCorrection, setTradeActivityCorrection] = useState({})
  /**
   * activityTradeCorrectionList: Contains boolean value for if required to show trade correction or not based on failure criteria
   * {
   *  [activityId_activityVersionId]: false
   * }
   */
  const [activityTradeCorrectionList, setActivityTradeCorrectionList] = useState({})

  const { items: list = [], pageSize } = useMemo(
    () => activityList,
    [activityList]
  );

  const handleFetchActivityList = useCallback(() => {
    const payload = {
      pageNumber: 1,
      pageSize: pageSize,
      actionId: actionId,
    };
    dispatch(
      actions.getInspectionDetailRequest(payload, (res) => {
        if (res?.activities) {
          setActivityList(res.activities);
        }
        if (res?.directory) {
          setDirectoryVM(res.directory)
        }
        setFetching(false);
        setFormattingResponses(true)
      })
    );
  }, [actionId, pageSize]);

  useEffect(() => {
    handleFetchActivityList();
  }, []);

  useEffect(() => {
    if (!isFetching && !!list.length) {
      const {
        activityQuesList,
        activityResList,
        activityTradeCorrections,
        activityNotesMediasData,
        tradeActivityCorrections,
      } = getActivityPrimaryResponseList({
        activityList: list,
        activityResponseListState,
        isTradeCorrectionNeeded: true,
      });

      setActivityQuestionList((prevState) => ({
        ...prevState,
        ...activityQuesList,
      }));
      setActivityResponseList((prevState) => ({
        ...prevState,
        ...activityResList,
      }));
      setActivityNotesMediasList((prevState) => ({
        ...prevState,
        ...activityNotesMediasData,
      }));
      setFormattingResponses(false)

      setActivityTradeCorrectionList(prevState => ({
        ...prevState,
        ...activityTradeCorrections
      }))
      setTradeActivityCorrection(prevState => ({
        ...prevState,
        ...tradeActivityCorrections
      }))
    } else if (!list.length) {
      setActivityQuestionList({});
      setActivityResponseList({});
      setActivityNotesMediasList({});
      setFormattingResponses(false)
      setActivityTradeCorrectionList({})
      setTradeActivityCorrection({})
    }
  }, [list, isFetching]);

  const activity = useMemo(() => {
    return list[0];
  }, [list]);

  const primaryResponse = useMemo(() => {
    return activity?.responseCollectionDtos?.find((q) => q.isPrimary);
  }, [activity]);

  const isDisableUpdateActivityResponse = useMemo(() => true, []);

  const {
    activityResList,
    activityQuesDetail,
    activityNotesMediasData,
    primaryQuestionResVal,
    isRequiresTradeCorrection,
    tradeCorrectionDetails
  } = useMemo(() => {
    if (!primaryResponse) {
      return {};
    }

    const primaryResponseId = primaryResponse.responseId;
    const activityKey = `${activity.checkpointId}_${activity.checkpointVersionId}`;
    const activityResList = activityResponseList[activityKey] || [];
    return {
      activityResList,
      activityQuesDetail: activityQuestionList[activityKey],
      activityNotesMediasData: activityNotesMediasList[activityKey],
      primaryQuestionResVal: activityResList.find(
        (res) => res.responseId === primaryResponseId
      ),
      isRequiresTradeCorrection: activityTradeCorrectionList[activityKey],
      tradeCorrectionDetails: tradeActivityCorrection[activityKey] || []
    };
  }, [
    activity,
    primaryResponse,
    activityResponseList,
    activityQuestionList,
    activityNotesMediasList,
    activityTradeCorrectionList,
    tradeActivityCorrection
  ]);

  if (isFetching || isFormattingResponses) {
    return (
      <div className="activities-questions d-flex justify-content-center">
        <span>
          <strong>
            {getLocaleMessage(intl, LANGUAGE_CONSTANTS.LOADER.LOADING)}
          </strong>
        </span>
      </div>
    );
  }

  if ((!list.length && !isFetching) || !primaryResponse) {
    return (
      <div className="activities-questions d-flex justify-content-center">
        <span>
          <strong>
            {getLocaleMessage(
              intl,
              LANGUAGE_CONSTANTS.ACTIONS_PAGE.NO_ACTIVITY_DETAILS
            )}
          </strong>
        </span>
      </div>
    );
  }

  return (
    <div className="checklist-view">
      <div className="task-detail-view">
        <div className="activities-questions">
          <PrimaryResponseCard
            activityData={activity}
            value={primaryQuestionResVal}
            responseQuestion={primaryResponse}
            isDisableUpdateActivityResponse={isDisableUpdateActivityResponse}
          >
            {!!activityQuesDetail?.additionalQes.length && (
              <div className="ml-2 mt-2 col-md-12">
                {isDisableUpdateActivityResponse && (
                  <div className="activity-response-overlay" />
                )}
                <AdditionalResponseCard
                  activityResponseDetails={activityResList}
                  responses={activityQuesDetail.additionalQes}
                />
              </div>
            )}
            {isRequiresTradeCorrection && <TradeCorrection
              directoryVM={directoryVM}
              tradeCorrectionDetails={tradeCorrectionDetails}
              isDisableUpdateActivityResponse={isDisableUpdateActivityResponse}
            />}
            {!!activityNotesMediasData?.length && (
              <Col md={12} lg={12} xl={12}>
                {activityNotesMediasData.map((media) => (
                  <ViewNoteCard key={media.noteId} data={media} />
                ))}
              </Col>
            )}
          </PrimaryResponseCard>
        </div>
      </div>
    </div>
  );
};

export default localeMessageWrapper(ObservationDetails);
