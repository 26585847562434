import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom'
import 'react-block-ui/style.css';
import BlockUi from 'react-block-ui';
import { useSelector, useDispatch } from 'react-redux';

import { ROUTES } from "shared/constants"
import { WINDOW_WIDTH } from 'views/helpers';
import { setActionDetailsSearchParams } from 'views/pages/actions/helpers'
import Router from './router';
import * as actions from "./store/actions"

const App = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { accessToken: token, isAccessDenied } = useSelector((state) => state.authDetail)
  const { isMobileView, isPageLoading } = useSelector((state) => state.appConfig)

  const [isLoading, setLoading] = useState(true)
  const [isRouteChanged, setRouteChanged] = useState(false)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  const actionId = useMemo(() => {
    const url = new URL(window.location.href)
    const id = url.searchParams.get('actionId')
    return id
  }, [])

  useEffect(() => {
    const isMobileViewRequired = windowWidth <= WINDOW_WIDTH

    if (isMobileViewRequired !== isMobileView) {
      dispatch(actions.setMobileViewRequest(isMobileViewRequired))
    }
  }, [isMobileView, windowWidth])

  useEffect(() => {
    const updateDimensions = () => {
      setWindowWidth(window.innerWidth);
    }
    updateDimensions();

    window.addEventListener("resize", updateDimensions);
    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);

  useEffect(() => {
    let url = new URL(window.location.href);
    let token = url.searchParams.get('tkn');
    if (!!token) {
      dispatch(actions.setAuthTokenRequest(token))
      setRouteChanged(true)
    }
    setLoading(false)
  }, [])

  useEffect(() => {
    if (!!token && isRouteChanged) {
      setTimeout(() => {
        if (!actionId) {
          history.push(ROUTES.actionsOverview)
        } else {
          const queryParams = setActionDetailsSearchParams({
            actionId,
            isDetailView: true
          })
          const searchString = `?${queryParams.toString()}`
          history.push({
            pathname: `${ROUTES.actionsOverview}/${actionId}`,
            search: searchString
          })
        }
      }, [])
      setRouteChanged(false)
    }
  }, [isRouteChanged, token, actionId])

  useEffect(() => {
    if (!token && !isLoading) {
      if (window.location.pathname !== ROUTES.notAuthorised) {
        history.push(ROUTES.notAuthorised)
      }
    }
  }, [isLoading, token])

  useEffect(() => {
    //To reset access denied state if user is on notAuthorised page and default isAccessDenied is not being already reseted
    if (isAccessDenied && !!window.location.pathname.includes(ROUTES.notAuthorised)) {
      dispatch(actions.resetAccessDenied())
    }

    // user will automatic logout When login session has expired
    if (isAccessDenied && window.location.pathname !== ROUTES.notAuthorised) {
      history.push(ROUTES.actionsOverview)
      dispatch(actions.resetAccessDenied())
    }
  }, [isAccessDenied])

  return (
    <BlockUi tag="div" blocking={isPageLoading}>
      <div className='outer'>
        <Router isLoading={isLoading} />
      </div>
    </BlockUi>
  );
}

export default App;
