import jwtDecode from "jwt-decode"
import AppSettings from '../../../../AppSettings.json'

export const checkIfCardViewHasData = (data) => {
  return !!data.list.length
}

//To create the Intersection Observer for last element in rendered list
const elementObserverOptions = {
  root: null, // Use the viewport as the root
  rootMargin: '0px',
  threshold: 0.5 // Trigger when at least 50% of the last element is visible
}

//To create the Intersection Observer for last element in activity list
export const getLastElementObserver = ({ onLoadMore = () => { } }) => {
  return new IntersectionObserver(
    (entries) => {
      if (entries[0]?.isIntersecting) {
        onLoadMore()
      }
    },
    elementObserverOptions)
}

export const setActionDetailsSearchParams = ({
  actionId,
  observationTaskId,
  isDetailView
}) => {
  const queryParams = new URLSearchParams(window.location.search)
  queryParams.delete('observationTaskId')
  queryParams.delete('tkn')
  if (isDetailView) {
    queryParams.set('viewDetail', true)
    queryParams.set('actionId', actionId)
    if (observationTaskId) {
      queryParams.set('observationTaskId', observationTaskId)
    }
  }

  return queryParams
}

/**
 * @method getInitialSearchState : To get initail search state for list page based on page search history and initial search details if no search history
 * @param {object} { initialSearchDetail, searchHistory } 
 * 
 * @returns search form's state
 */
export const getInitialSearchState = ({ initialSearchDetail, searchHistory = {} }) => {
  if (!!searchHistory?.advanceSearch) {
    const searchInfo = searchHistory.advanceSearch
    return searchInfo
  } else {
    return Object.assign({}, initialSearchDetail)
  }
}

const { REACT_APP_NAME_URL } = AppSettings

export const getEntityFromToken = (accessToken) => {
  const decodedAccessToken = jwtDecode(accessToken)

  if (decodedAccessToken) {
    return decodedAccessToken[REACT_APP_NAME_URL] || ''
  }

  return ''
}
