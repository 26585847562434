import { SET_IS_MOBILE_VIEW, SET_IS_LOADING } from "../../actions"

// ** Initial State
const initialState = {
  isMobileView: false,
  isPageLoading: false
};

const appConfigReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_MOBILE_VIEW: {
      return {
        ...state,
        isMobileView: action.payload
      };
    }
    case SET_IS_LOADING: {
      return {
        ...state,
        isPageLoading: action.payload
      }
    }
    default:
      return state
  }
}

export default appConfigReducer
