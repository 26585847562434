import moment from "moment"
import React, { useMemo } from "react"

import { LANGUAGE_CONSTANTS } from "shared/language-constants"
import { getLocaleMessage } from "utility/Utils";
import localeMessageWrapper from "views/components/locale-message";
import CorrectionDetailItem from './correction-detail-item'

const { ACTIONS_PAGE } = LANGUAGE_CONSTANTS

const CorrectionDetails = ({
  intl,
  actionId,
  correctionDetails
}) => {

  if (!actionId) {
    return null
  }

  const formattedDate = useMemo(() => !!correctionDetails["createdDate"] ? moment(correctionDetails["createdDate"]).format('MMMM D, YYYY') : '', [correctionDetails["createdDate"]])

  return (
    <>
      {!!Object.keys(correctionDetails).length && <div className="correction-details">
        <div className="audit-trail-header">
          <h3 className="text-capitalize">{getLocaleMessage(intl, ACTIONS_PAGE.FAILED_INSPECTION_CORRECTION)}</h3>
          <div className="audit-trail-details">
            <span className="submitter">{correctionDetails["submitter"] || ""}</span>
            <span className="creater">{formattedDate}</span>
          </div>
        </div>
        <CorrectionDetailItem intl={intl} data={correctionDetails} />
      </div>}
    </>
  )
}

export default localeMessageWrapper(CorrectionDetails)
