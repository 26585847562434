import { putDataApi } from "services/api"
import config from "../../config"

/**
 * @method updateAcceptByTrade
 * This api is called when the trade accept the correction for some activity
 * [Failed inspection correction]
 * 
 * @param {object} data 
 * 
 * {
 *  observationTaskId: 0,
 *  assignmentActivityId: 0
 * }
 * 
 */
export const updateAcceptByTrade = async (data) => {
  const { url } = config.updateAcceptByTrade
  return await putDataApi(url(data), data)
}

export default updateAcceptByTrade
