import { getSearchQuery } from '../../../config'

const config = {
  getAuditTrailModal: {
    method: 'GET',
    url: ({
      observationTaskId,
      wfActionId,
      assignmentActivityId
    }) => {

      const searchString = `${getSearchQuery(
        "ObservationTaskId",
        observationTaskId,
        !!observationTaskId
      )}${getSearchQuery(
        "WFActionId",
        wfActionId)}${getSearchQuery(
          "AssignmentActivityId",
          assignmentActivityId)}`

      return `api/FSPublic/AuditTrail/${observationTaskId}${searchString}`
    }
  }
}

export default config
