export const checkIfSearchFiltersApplied = ({
  actionType,
  siteType,
  siteUnitType,
  builder,
  assignedBy
}) => {
  return (
    !!actionType?.value ||
    !!siteType?.value ||
    !!siteUnitType?.value ||
    !!builder?.value ||
    !!assignedBy?.entityId
  )
}

export const getAdvanceSearchData = ({
  searchFormData
}) => {
  return {
    actionTypeId: searchFormData.actionType?.value,
    siteId: searchFormData.siteType?.value,
    siteUnitId: searchFormData.siteUnitType?.value,
    organizationId: searchFormData.builder?.value,
    entityId: searchFormData.assignedBy?.entityId
  }
}