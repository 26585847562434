import React, { useMemo } from "react";

import { getButtonResponseStyle } from "../Helpers";

const ButtonResponse = ({
  responseOptions = [],
  value,
}) => {
  const selectedValue = useMemo(
    () => responseOptions.find((item) => item.responseOptionId === value),
    [responseOptions, value]
  );

  return (
    <div className="button_response_options">
      {selectedValue && (
        <button
          disabled
          className={"mr-2 button-control"}
          style={getButtonResponseStyle(selectedValue)}
        >
          {selectedValue.options}
        </button>
      )}
    </div>
  );
};

export default ButtonResponse;
