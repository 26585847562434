import PropTypes from 'prop-types'
import React from 'react'
import { X } from 'react-feather'

const SideModalCloseButton = ({ onClose = () => { } }) => {

  return (
    <X className="cursor-pointer" size={15} onClick={onClose} />
  )
}

SideModalCloseButton.propTypes = {
  onClose: PropTypes.func
}

export default SideModalCloseButton
