import { UPDATE_LIST_PAGE_SEARCH_HISTORY, RESET_SEARCH_HISTORY } from "store/actions/search-history"

// ** Initial State
const initialState = {}

const searchHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    /** UPDATE_LIST_PAGE_SEARCH_HISTORY **/
    case UPDATE_LIST_PAGE_SEARCH_HISTORY:
      return {
        ...state,
        [action.payload.keyName]: action.payload.searchValues
      }
    default:
      return state
  }
}

export default searchHistoryReducer
