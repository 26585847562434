import cx from "classnames"
import React, { useMemo } from 'react'
import { useForm } from "react-hook-form"

import { LANGUAGE_CONSTANTS } from "shared/language-constants"
import { getLocaleMessage } from 'utility/Utils'
import { SiteSearch, UnitSearch, BuilderSearch } from 'views/components'
import localeMessageWrapper from "views/components/locale-message"
import ReactSelect from 'views/components/react-select'
import { checkIfSearchFiltersApplied } from './helper'

const {
  ACTIONS_FILTER: {
    FILTER,
    CLEAR,
    FILTER_BY_ACTION_TYPE,
    FILTER_BY_ASSIGNED_BY,
    FILTER_BY_BUILDER,
    FILTER_BY_SITE,
    FILTER_BY_UNIT,
    ASSIGNED_BY,
    BUILDER,
    ACTION_TYPE,
    ACTION_SITE,
    ACTION_UNIT
  },
} = LANGUAGE_CONSTANTS;

const ActionFilter = ({ intl, searchDetail, actionListVM, onChangeDetails = () => { }, onCustomAdvanceSearch = () => { }, onResetSearchDetails = () => { } }) => {
  const { control } = useForm({})

  const isFilterApplied = useMemo(() => {
    return checkIfSearchFiltersApplied(searchDetail)
  }, [searchDetail])

  // Check if there is only one item in the list and set it as the default value
  const defaultAssignedBy = useMemo(() => {
    return actionListVM.assignedBy && actionListVM.assignedBy.length === 1
      ? actionListVM.assignedBy[0]
      : null
  }, [actionListVM.assignedBy])

  const entityId = useMemo(() => searchDetail.assignedBy?.entityId, [searchDetail.assignedBy?.entityId])
  const organisationId = useMemo(() => searchDetail.builder?.value, [searchDetail.builder?.value])
  const siteId = useMemo(() => searchDetail.siteType?.value, [searchDetail.siteType?.value])

  return (
    <div className="filter-overlay-inner" onClick={(e) => e.stopPropagation()}>
      <h6 className={cx('filter-head', { 'filter-applied': isFilterApplied })}>
        {getLocaleMessage(intl, FILTER)} <span onClick={onResetSearchDetails}> {getLocaleMessage(intl, CLEAR)} </span>
      </h6>
      <ReactSelect
        id={"assignedBy"}
        name={"assignedBy"}
        control={control}
        value={!!defaultAssignedBy ? defaultAssignedBy : searchDetail.assignedBy}
        placeHolder={getLocaleMessage(intl, FILTER_BY_ASSIGNED_BY)}
        label={getLocaleMessage(intl, ASSIGNED_BY)}
        isFormattedValue
        labelType="entityName"
        valueType="entityId"
        getOptionLabel={(item) => item.entityName}
        getOptionValue={(item) => item.entityId}
        options={actionListVM.assignedBy || []}
        onSelect={(selected) => onChangeDetails('assignedBy', selected, "entityId", (data) => onCustomAdvanceSearch(null, true, data))}
      />
      <BuilderSearch
        id="builder"
        name="builder"
        placeholder={getLocaleMessage(intl, FILTER_BY_BUILDER)}
        label={getLocaleMessage(intl, BUILDER)}
        value={searchDetail.builder}
        entityId={entityId}
        isDisabled={!entityId}
        onSelect={(selected) => onChangeDetails('builder', selected, undefined, (data) => onCustomAdvanceSearch(null, true, data))}
      />
      <SiteSearch
        id='siteType'
        name='siteType'
        placeholder={getLocaleMessage(intl, FILTER_BY_SITE)}
        label={getLocaleMessage(intl, ACTION_SITE)}
        value={searchDetail.siteType}
        organisationId={organisationId}
        isDisabled={!organisationId}
        onSelect={(selected) => onChangeDetails('siteType', selected, undefined, (data) => onCustomAdvanceSearch(null, true, data))}
      />
      <UnitSearch
        id="siteUnitType"
        name="siteUnitType"
        placeholder={getLocaleMessage(intl, FILTER_BY_UNIT)}
        label={getLocaleMessage(intl, ACTION_UNIT)}
        value={searchDetail.siteUnitType}
        siteId={siteId}
        isDisabled={!siteId}
        onSelect={(selected) => onChangeDetails('siteUnitType', selected, undefined, (data) => onCustomAdvanceSearch(null, true, data))}
      />
      <ReactSelect
        id={"actionType"}
        name={"actionType"}
        control={control}
        value={searchDetail.actionType}
        placeHolder={getLocaleMessage(intl, FILTER_BY_ACTION_TYPE)}
        label={getLocaleMessage(intl, ACTION_TYPE)}
        isFormattedValue
        isDisabled={!entityId}
        getOptionLabel={(item) => item.text}
        getOptionValue={(item) => item.value}
        options={actionListVM.actionType || []}
        onSelect={(selected) => onChangeDetails('actionType', selected, undefined, (data) => onCustomAdvanceSearch(null, true, data))}
      />
    </div>
  )
}

export default localeMessageWrapper(ActionFilter)