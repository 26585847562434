import { LANGUAGE_CONSTANTS } from 'shared/language-constants'
import { getLocaleMessage } from "utility/Utils"

const { ACTIONS } = LANGUAGE_CONSTANTS

export const getActionDetailsSearchParams = () => {
  const queryParams = new URLSearchParams(window.location.search)
  return {
    isViewDetail: queryParams.get('viewDetail')
  }
}

export const getOverdueTime = (date, intl) => {
  const dueDate = new Date(date)
  const currentDate = new Date()

  const miliSeconds = currentDate - dueDate
  if (currentDate < dueDate) {
    return ""
  }
  let hours = Math.floor(miliSeconds / (1000 * 60 * 60))
  const days = Math.floor(hours / 24)
  hours = hours - (days * 24)
  const daysToShow = getLocaleMessage(
    intl,
    days ? days === 1
      ? ACTIONS.OVERDUE_DAY
      : ACTIONS.OVERDUE_DAYS : 0,
    { days }
  )
  const hoursToShow = getLocaleMessage(
    intl,
    hours === 1
      ? ACTIONS.OVERDUE_HOUR
      : ACTIONS.OVERDUE_HOURS,
    { hours }
  )
  const pastDue = getLocaleMessage(intl, ACTIONS.PAST_DUE)
  if (days > 0) {
    return `${daysToShow} ${hoursToShow} ${pastDue}`
  }
  if (hours > 0) {
    return `${hoursToShow} ${pastDue}`
  }

  return ""
}
