import { TailSpin } from "react-loader-spinner"
import PropTypes from 'prop-types'

import { LANGUAGE_CONSTANTS } from "shared/language-constants"
import { LocaleMessage } from "views/components/locale-message"
import '../../../../node_modules/react-loader-spinner/dist/loader/TailSpin'

const DataTableLoader = ({ customProgress, isLoading, loadingMessage }) => {
  if (customProgress) {
    return (
      <div style={{ padding: "100px" }}>
        <TailSpin type="TailSpin" height={50} width={50} color="#1b68b6" />
        {loadingMessage && <LocaleMessage id={LANGUAGE_CONSTANTS.LOADER.LOADING} />}
      </div>
    )
  }

  return isLoading ? (
    <TailSpin
      type="TailSpin"
      color="#1b68b6"
      className="main-loader"
      height={50}
      width={50}
    />
  ) : (
    <div />
  )
}

DataTableLoader.propTypes = {
  customProgress: PropTypes.bool,
  isLoading: PropTypes.bool,
  loadingMessage: PropTypes.bool
}

DataTableLoader.defaultProps = {
  loadingMessage: false
}

export default DataTableLoader
