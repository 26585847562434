import { getDataApi } from "services/api"
import config from "../../config"

/**
 * @method getEntityListDDL
 *
 */
export const getEntityListDDL = () => {
  const { url } = config.getEntityListDDL
  return getDataApi(url)
}

export default getEntityListDDL
