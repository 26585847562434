import React, { useCallback, useState, useMemo } from "react"
import { Col } from 'reactstrap'

import AccordionComponent from '../accordion'
import ActionsList from '../actions-list'

const UnitList = ({ intl, unitDetails }) => {
  const [accordianExpanded, setAccordianExpanded] = useState([0])

  const handleAccordianExpanded = useCallback((id) => {
    let updatedAccordian = [...accordianExpanded]
    if (updatedAccordian.includes(id)) {
      updatedAccordian = updatedAccordian.filter((item) => item !== id)
    } else {
      updatedAccordian.push(id)
    }
    setAccordianExpanded(updatedAccordian)
  }, [accordianExpanded])

  return (
    <div className="setup-accordion activity-details-accordion">
      {unitDetails.map((item, index) => (
        <div className="setup-accordion-header" key={`view-action-details-${index}`}> <AccordionComponent index={index} intl={intl} title={item.title} accordianExpanded={accordianExpanded} onAccordianExpanded={handleAccordianExpanded}>
          <ActionsList data={item} intl={intl} />
        </AccordionComponent></div>
      ))
      }
    </div>
  )
}

export default UnitList
