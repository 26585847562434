// ** Redux Imports
import { combineReducers } from "redux";
import authDetail from "../reducers/auth"
import appConfig from "../reducers/app-config"
import searchHistory from "../reducers/search-history"

const appReducer = combineReducers({
  authDetail,
  appConfig,
  searchHistory
});

const rootReducers = (state, action) => {
  const obj = { ...action };

  return appReducer(state, obj);
};

export default rootReducers;
