import { 
  ACTIVITY_COLLECTION_RESPONSE_TYPES, 
  ACTIVITY_COLLECTION_RESPONSE_KEYS,
  CHECKPOINT_NUMERIC_VALIDATION_TYPES,
  CHECKPOINT_NUMERIC_TYPES
} from "shared/constants"
import { LANGUAGE_CONSTANTS } from "shared/language-constants";
import { getLocaleMessage } from "utility/Utils";

const { RESPONSES_PLACEHOLDER_TEXT } = LANGUAGE_CONSTANTS

export const getFormattedColorConfig = ({ buttonColor, fontColor }) => {
  try {
    //Note: For colors selected from default colors dropdown, buttonColor value is not valid json,
    // So catch block is used for setting color for these colors
    const bColor = JSON.parse(buttonColor)

    //fontColor always contain custom color value or null value
    const fColor = !!fontColor ? JSON.parse(fontColor) : ''

    //if color value is parsed but its string not object
    //Note: This is for worst case scenario
    if (typeof bColor !== "object" && typeof bColor === "string") {
      return {
        bColor
      }
    }

    //Note: When custom color is selected from color palette
    return {
      bColor: !!bColor ? `rgb(${bColor.r} ${bColor.g} ${bColor.b} / ${bColor.a})` : "#1c72e7",
      fColor: !!fColor ? `rgb(${fColor.r} ${fColor.g} ${fColor.b} / ${fColor.a})` : ''
    }
  } catch (error) {
    //Note: When color is selected from default colors dropdown
    if (typeof buttonColor === "string" && error.message.includes(`"${buttonColor}" is not valid JSON`)) {
      return { bColor: buttonColor }
    }

    return { bColor: "#1c72e7", fColor: "#fff" }
  }
}

export const getButtonResponseStyle = ({ buttonColor, fontColor }) => {
  const { bColor, fColor } = getFormattedColorConfig({
    buttonColor,
    fontColor
  })

  const style = {
    backgroundColor: bColor,
    fontWeight: 'bold',
    border: "none"
  }

  if (fColor) {
    style.color = fColor
  }

  if (bColor.toLowerCase() === 'white') {
    style.color = '#000'
    style.border = '1px solid #000'
  }

  return style
}

export const getResponseValue = ({
  isInitialValueFormatting,
  value,
  responseTypeId,
  responseNumericTypeId
}) => {
  if (!value) {
    return value
  }

  let selectedValue = ""
  let selectedKey = ""
  let otherOptionValue = ""

  switch (responseTypeId) {
    case ACTIVITY_COLLECTION_RESPONSE_TYPES.BOOLEAN:
      selectedValue = value[ACTIVITY_COLLECTION_RESPONSE_KEYS.BOOLEAN] || value["collectionOptionDtos"] || []
      selectedKey = ACTIVITY_COLLECTION_RESPONSE_KEYS.BOOLEAN
      break
    case ACTIVITY_COLLECTION_RESPONSE_TYPES.CHOICE_SINGLE_SELECT:
      selectedValue = value[ACTIVITY_COLLECTION_RESPONSE_KEYS.CHOICE_SELECT] || value["collectionOptionDtos"] || ""
      selectedKey = ACTIVITY_COLLECTION_RESPONSE_KEYS.CHOICE_SELECT
      otherOptionValue = value['otherOption']
      break
    case ACTIVITY_COLLECTION_RESPONSE_TYPES.CHOICE_MULTI_SELECT:
      selectedValue = value[ACTIVITY_COLLECTION_RESPONSE_KEYS.CHOICE_SELECT] || value["collectionOptionDtos"] || []
      selectedKey = ACTIVITY_COLLECTION_RESPONSE_KEYS.CHOICE_SELECT
      otherOptionValue = value['otherOption']
      break
    case ACTIVITY_COLLECTION_RESPONSE_TYPES.DATE:
      selectedValue = value[ACTIVITY_COLLECTION_RESPONSE_KEYS.DATE] || ""
      selectedKey = ACTIVITY_COLLECTION_RESPONSE_KEYS.DATE
      break
    case ACTIVITY_COLLECTION_RESPONSE_TYPES.FREE_TEXT:
      selectedValue = value[ACTIVITY_COLLECTION_RESPONSE_KEYS.FREE_TEXT] || ""
      selectedKey = ACTIVITY_COLLECTION_RESPONSE_KEYS.FREE_TEXT
      break
    case ACTIVITY_COLLECTION_RESPONSE_TYPES.NUMERIC:
      if (responseNumericTypeId.toString() === CHECKPOINT_NUMERIC_TYPES.NUMERIC_RANGE) {
        selectedValue = {
          minNumber: value[ACTIVITY_COLLECTION_RESPONSE_KEYS.NUMERIC_MIN_RANGE] || "",
          maxNumber: value[ACTIVITY_COLLECTION_RESPONSE_KEYS.NUMERIC_MAX_RANGE] || ""
        }
      } else {
        selectedValue = value[ACTIVITY_COLLECTION_RESPONSE_KEYS.NUMERIC_SINGLE] || ""
        selectedKey = ACTIVITY_COLLECTION_RESPONSE_KEYS.NUMERIC_SINGLE
      }
      break
    case ACTIVITY_COLLECTION_RESPONSE_TYPES.PHOTO:
      selectedValue = value[ACTIVITY_COLLECTION_RESPONSE_KEYS.PHOTO] || value["imageCollectionDtos"] || []
      selectedKey = ACTIVITY_COLLECTION_RESPONSE_KEYS.PHOTO
      break
    case ACTIVITY_COLLECTION_RESPONSE_TYPES.DDL_SINGLE_SELECT:
      selectedValue = value[ACTIVITY_COLLECTION_RESPONSE_KEYS.DDL] || value["collectionOptionDtos"] || ""
      selectedKey = ACTIVITY_COLLECTION_RESPONSE_KEYS.DDL
      otherOptionValue = value['otherOption']
      break
    case ACTIVITY_COLLECTION_RESPONSE_TYPES.DDL_MULTI_SELECT:
      selectedValue = value[ACTIVITY_COLLECTION_RESPONSE_KEYS.DDL] || value["collectionOptionDtos"] || []
      selectedKey = ACTIVITY_COLLECTION_RESPONSE_KEYS.DDL
      otherOptionValue = value['otherOption']
      break
    case ACTIVITY_COLLECTION_RESPONSE_TYPES.FORESITE_STANDARD:
      selectedValue = value[ACTIVITY_COLLECTION_RESPONSE_KEYS.FORESITE_STANDARD] || value["collectionOptionDtos"] || []
      selectedKey = ACTIVITY_COLLECTION_RESPONSE_KEYS.FORESITE_STANDARD
      break
    case ACTIVITY_COLLECTION_RESPONSE_TYPES.BUTTON:
      selectedValue = value[ACTIVITY_COLLECTION_RESPONSE_KEYS.BUTTON] || value["collectionOptionDtos"] || []
      selectedKey = ACTIVITY_COLLECTION_RESPONSE_KEYS.BUTTON
      break
    default: break
  }

  if (isInitialValueFormatting) {
    return {
      selectedValue,
      selectedKey,
      otherOptionValue
    }
  }

  return selectedValue
}

export const getNumericResponsePlaceholder = (intl, responseNumericValidationId) => {
  return getLocaleMessage(
    intl,
    responseNumericValidationId === CHECKPOINT_NUMERIC_VALIDATION_TYPES.WHOLE_NUMBER
      ? RESPONSES_PLACEHOLDER_TEXT.ENTER_WHOLE_NUMBER
      : responseNumericValidationId === CHECKPOINT_NUMERIC_VALIDATION_TYPES.DECIMAL_NUMBER
        ? RESPONSES_PLACEHOLDER_TEXT.ENTER_DECIMAL_NUMBER
        : RESPONSES_PLACEHOLDER_TEXT.ENTER_PERCENTAGE
  )
}
