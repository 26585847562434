import moment from "moment"

export const getDateTimeFormat = (date) => {
  return date ? moment(date).format('YYYY-MM-DDTHH:mm:ss') : ''
}

export const getStartTimeOptions = (date) => {
  const options = []
  const endTime = moment(date).endOf('day').format()
  for (let startTime = moment.utc(date).startOf('day'); startTime.format() < endTime; startTime = moment(startTime).add(1, 'hours')) {
    options.push({ text: startTime.format('LT'), value: startTime.utc().format() })
  }
  return options
}

export const WINDOW_WIDTH = 767