import cx from "classnames"
import React, { useMemo } from "react"
import { Col, Row } from "reactstrap"

import { LANGUAGE_CONSTANTS } from "shared/language-constants"
import { getLocaleMessage } from "utility/Utils"
import { ReactSelect, DatePicker } from "views/components"
import localeMessageWrapper from "views/components/locale-message"
import ActionButton from "../../../ActionButton"
import { ACTION_STATUS_VALUE_TYPES, ACTION_TYPES } from "../../../config"

const { ACTIONS_PAGE, BACK } = LANGUAGE_CONSTANTS

const ActionsView = ({
  actionStatus,
  assignedStartTime,
  confirmSiteReadyDate,
  control,
  timeOptions,
  intl,
  selectedActionType,
  isShowSubmitActionView,
  onChangeDetails = () => { },
  onConfirmAction = () => { },
  onShowConfirmActionView = () => { },
  onSubmitAndClose = () => { },
  onViewMore = () => { }
}) => {

  const isDisabledView = useMemo(() => {
    return !(assignedStartTime || confirmSiteReadyDate)
  }, [assignedStartTime, confirmSiteReadyDate])

  if (!isShowSubmitActionView) {
    return (
      <div className="d-flex align-items-center card-action-btn">
        {!selectedActionType ? (
          <>
            {actionStatus !== ACTION_STATUS_VALUE_TYPES.COMPLETED && (
              <>
                <ActionButton
                  color="success"
                  className="mr-1 w-100"
                  labelLocaleMessageId={ACTIONS_PAGE.CONFIRM}
                  type={ACTION_TYPES.CONFIRM_SITE_VISIT.CONFIRM}
                  onClick={onShowConfirmActionView}
                />
                <ActionButton
                  color="danger"
                  className="mr-1 w-100"
                  labelLocaleMessageId={ACTIONS_PAGE.NOT_READY}
                  type={ACTION_TYPES.CONFIRM_SITE_VISIT.NOT_READY}
                  onClick={onShowConfirmActionView}
                />
              </>
            )}

            <ActionButton
              className="secondary-solid outline-button w-100"
              labelLocaleMessageId={ACTIONS_PAGE.VIEW_MORE}
              onClick={onViewMore}
            />

          </>
        ) : (
          <>
            {selectedActionType === ACTION_TYPES.CONFIRM_SITE_VISIT.CONFIRM ? (
              <ActionButton
                color="success"
                className="mr-1 to-capitalized w-100"
                isConfirmAction
                labelLocaleMessageId={ACTIONS_PAGE.YES_ITS_READY}
                onClick={onConfirmAction}
              />
            ) : (
              <ActionButton
                color="danger"
                className="mr-1 to-capitalized w-100"
                labelLocaleMessageId={ACTIONS_PAGE.NO_ITS_NOT_READY}
                isConfirmAction
                onClick={onConfirmAction}
              />
            )}
            <ActionButton
              className="secondary-solid mr-1 w-100"
              labelLocaleMessageId={BACK}
              onClick={onShowConfirmActionView}
            />
          </>
        )}
      </div>
    )
  }

  return (
    <>
      <Row xs="2">
        <Col md={6} className="pr-0">
          <DatePicker
            id="actionDate"
            placeholder={getLocaleMessage(intl, ACTIONS_PAGE.READY_DATE)}
            value={confirmSiteReadyDate}
            onChange={(value) => onChangeDetails("readyDate", value)}
            isClearable={!!confirmSiteReadyDate}
          />
        </Col>
        <Col md={6}>
          <ReactSelect
            isClearable
            id="assigned_start_time"
            name="assigned_start_time"
            placeHolder={getLocaleMessage(intl, ACTIONS_PAGE.READY_TIME)}
            control={control}
            value={assignedStartTime}
            options={timeOptions}
            onSelect={(selected) => onChangeDetails("readyTime", selected ? selected.value : "")
            }
          />
        </Col>
      </Row>
      <ActionButton
        className={cx("w-100", { "grey-solid": isDisabledView, "common-button": !isDisabledView })}
        isDisabled={isDisabledView}
        labelLocaleMessageId={ACTIONS_PAGE.SUBMIT_AND_CLOSE}
        isSubmitAction
        onClick={onSubmitAndClose}
      />
    </>
  )
}

export default localeMessageWrapper(ActionsView)
