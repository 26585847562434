import React, { useMemo } from "react";
import { Row, Col } from "reactstrap";
import { ACTIVITY_COLLECTION_RESPONSE_TYPES } from "shared/constants";
import ResponseControls from "../observation-activity/response-controls";

const PrimaryResponseCard = ({
  activityData = {},
  value,
  children,
  responseQuestion = {},
  isDisableUpdateActivityResponse,
}) => {
  const isButtonTypeResponse = useMemo(
    () =>
      [
        ACTIVITY_COLLECTION_RESPONSE_TYPES.BUTTON,
        ACTIVITY_COLLECTION_RESPONSE_TYPES.FORESITE_STANDARD,
      ].includes(responseQuestion.responseTypeId),
    [responseQuestion.responseTypeId]
  );

  return (
    <>
      <div className="d-flex nt-rdt-list-actions">
        <div className="d-flex">
          <p className="mr-1">{activityData.checkpointId}.</p>
          <p
            dangerouslySetInnerHTML={{ __html: responseQuestion.responseName }}
          />
          {!!activityData.isRequired && (
            <span className={"text-danger required-field"}>*</span>
          )}
        </div>
      </div>
      <div className="response-question">
        <Row>
          <Col
            lg={isButtonTypeResponse ? 12 : 6}
            md={isButtonTypeResponse ? 12 : 10}
            sm={12}
            className="d-flex flex-wrap flex-md-nowrap"
          >
            {isDisableUpdateActivityResponse && (
              <div className="activity-response-overlay" />
            )}
            <ResponseControls
              responseQuestion={responseQuestion}
              value={value}
            />
          </Col>
          {children}
        </Row>
      </div>
    </>
  );
};

export default PrimaryResponseCard;
