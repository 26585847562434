import cx from "classnames"
import React, { useState, useCallback, useEffect } from 'react'
import { Container } from "reactstrap";
import { useSelector } from 'react-redux'

import { filter } from "assets/images/actions"
import logo from "assets/images/logo.svg"
import { LocaleMessage } from 'views/components/locale-message'
import ActionsListOverview from './action-list-overview'
import ActionsHeader from './Header'
import { getEntityFromToken } from "./helpers";
import MobileActionListOverview from './mobile-view/action-list-overview'

const ActionsList = () => {
  const { isMobileView } = useSelector((state) => state.appConfig)
  const { accessToken: token } = useSelector((state) => state.authDetail)

  const [isToggleDetails, setToggleDetails] = useState(false)
  const [userName, setUserName] = useState('')

  const handleToggleDetails = useCallback(() => {
    setToggleDetails(!isToggleDetails)
  }, [isToggleDetails])

  useEffect(() => {
    if (token) {
      setUserName(getEntityFromToken(token))
    }
  }, [token])

  return (
    <div className={cx("", { "mobile-view-action-outer": isMobileView })}>
      <div className='header-logo'>
        <Container className='p-sm-0 inner-header-logo' >
          <img src={logo} alt="logo" />
          <div>
            {isMobileView && <span className="filter-view"><img src={filter} className="filter-icon" onClick={handleToggleDetails} /></span>}
            <span className="admin-profile">
              <LocaleMessage id={userName} />
            </span>
          </div>

        </Container>
      </div>
      <div className='assigned-action'>
        <div className='assigned-action-header'>
          <Container>
            <ActionsHeader />
          </Container>
        </div>
        <ActionsListOverview
          isToggleDetails={isToggleDetails}
          isMobileView={isMobileView}
          onToggleDetails={setToggleDetails}
        />
        {/* {isMobileView ? <MobileActionListOverview /> : <ActionsListOverview />} //todo required in the second mobile version */}
      </div>
    </div>
  )
}

export default ActionsList
