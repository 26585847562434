import React, { useEffect, useState, useCallback } from 'react'
import { Row, Col, Container } from "reactstrap";
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom"

import * as actions from "store/actions"

import ActionCards from './ActionCards';
import { setActionDetailsSearchParams } from '../../../../actions/helpers';

export const initialActionListState = {
  list: [],
  pageNumber: 0,
  pageSize: 10,
  totalCount: 0,
  totalPages: 0,
  hasNextPage: false
}

const ActionsList = ({ intl }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [cardViewActionsList, setCardViewActionsList] = useState(
    Object.assign({}, initialActionListState)
  )
  const [isFetching, setFetchingList] = useState(false)
  const [isFetchingMore, setFetchingListMore] = useState(false)

  const handleResetCardViewActionList = useCallback(() => {
    setCardViewActionsList(Object.assign({}, initialActionListState))
  }, [])

  const handleFetchCardViewActionList = useCallback((data) => {
    const {
      page = 1,
      size,
      isLoadMore = false
    } = data || {}

    const payload = {
      siteId: "",
      siteUnitId: "",
      pageNumber: page || 1,
      pageSize: size
    }

    if (isLoadMore) {
      setFetchingListMore(true)
    } else {
      setFetchingList(true)
    }

    dispatch(
      actions.getActionListRequest(payload, (data) => {
        if (data) {
          setCardViewActionsList((prevOptions) => {
            return {
              list:
                page === 1
                  ? data.items
                  : [...prevOptions.list, ...data.items],
              pageSize: data.pageSize,
              pageNumber: data.pageIndex,
              totalCount: data.totalCount,
              totalPages: data.totalPages,
              hasNextPage: data.hasNextPage
            }
          })
        }
        if (isLoadMore) {
          setFetchingListMore(false)
        } else {
          setFetchingList(false)
        }
      })
    )
  }, [])

  const handleFetchList = useCallback(
    (params) => {
      const { ...data } = params || {}
      handleResetCardViewActionList()
      handleFetchCardViewActionList({
        page: cardViewActionsList.pageNumber,
        size: cardViewActionsList.pageSize,
        ...data
      })
    },
    [
      cardViewActionsList,
      handleFetchCardViewActionList,
      handleResetCardViewActionList
    ]
  )

  useEffect(() => {
    const payload = {
      page: 1
    }
    handleFetchList(payload)
  }, [])

  const handleLoadMore = useCallback(() => {
    if (cardViewActionsList.list.length < cardViewActionsList.totalCount) {
      handleFetchCardViewActionList({
        page: cardViewActionsList.pageNumber + 1,
        size: cardViewActionsList.pageSize,
        isLoadMore: true
      })
    }
  }, [cardViewActionsList, handleFetchCardViewActionList])

  const handleRefreshActionList = useCallback(() => {
    handleFetchCardViewActionList({
      page: 1,
      size: cardViewActionsList.pageSize,
    })
  }, [cardViewActionsList, handleFetchCardViewActionList])

  const handleViewMoreDetail = useCallback((actionId) => {
    const queryParams = setActionDetailsSearchParams({
      actionId,
      isDetailView: true
      // observationTaskId: taskId //todo: check if 
    })
    /**
     * Note:
     * If actions list opened from Actions menu, use Route -> ROUTES.actionsDetails,
     * else if actions list used in overview pages of other sections, use concept of current route with action id
     */
    const { pathname } = window.location
    const searchString = `?${queryParams.toString()}`
    const previousHistory = history.location?.state
    history.push({
      pathname: `${pathname}/${actionId}`,
      search: searchString,
      state: previousHistory
    })
  }, [])

  return (
    <div className='action-list'>
      <ActionCards
        intl={intl}
        actionList={cardViewActionsList}
        isFetching={isFetching}
        isFetchingMore={isFetchingMore}
        onLoadMore={handleLoadMore}
        onRefreshActionList={handleRefreshActionList}
        onViewMore={handleViewMoreDetail}
      />
    </div>
  )
}

export default ActionsList
