import { takeLatest, call, put } from "redux-saga/effects"

import * as apiServices from "services/api/actions-page"
import { LANGUAGE_CONSTANTS } from "shared/language-constants"
import { Notification } from 'views/components'
import * as actions from "../../actions"
const { ACTIONS_PAGE_SAGA } = LANGUAGE_CONSTANTS

/** getActionList API **/
function* getActionListRequest(action) {
  try {
    const response = yield call(apiServices.getActionList, action.payload)
    if (response?.data) {
      action.callback({ ...response.data, pageSize: action.payload.pageSize })
    } else {
      action.callback()
    }
  } catch (error) {
    action.callback()
  }
}

/** getActionDetail API **/
function* getActionDetailRequest(action) {
  try {
    const response = yield call(apiServices.getActionDetail, action.payload)
    action.callback(response.data)
  } catch (error) {
    action.callback()
  }
}

/** updateAcceptByTrade API **/
function* updateAcceptByTradeRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(apiServices.updateAcceptByTrade, action.payload)
    if (response?.status === 204) {
      action.callback(true)
      yield put(actions.setIsLoading(false))
      Notification({ status: 200, message: ACTIONS_PAGE_SAGA.ACCEPT_BY_TRADE_UPDATE_SUCCESS })
    } else {
      action.callback()
      yield put(actions.setIsLoading(false))
      Notification({ status: 400, message: ACTIONS_PAGE_SAGA.ERROR_IN_UPDATING_ACCEPT_BY_TRADE })
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback()
  }
}

/** updateTradeActionReassign API **/
function* updateTradeActionReassignRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(apiServices.updateTradeActionReassign, action.payload)
    action.callback(response?.data)
    yield put(actions.setIsLoading(false))
    Notification({ status: 200, message: ACTIONS_PAGE_SAGA.UPDATE_CONTACT_TRADE_REASSIGN_SUCCESS })
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback()
  }
}

/** createConfirmSiteVisit  API **/
function* createConfirmSiteVisitRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(apiServices.createConfirmSiteVisit, action.payload)
    if (response?.status === 204) {
      action.callback(true)
      yield put(actions.setIsLoading(false))
    } else {
      action.callback()
      yield put(actions.setIsLoading(false))
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback()
  }
}

/** updateVerifyBySuperintendent API **/
function* updateVerifyBySuperintendentRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(apiServices.updateVerifyBySuperintendent, action.payload)
    if (response?.status === 204) {
      action.callback(true)
      yield put(actions.setIsLoading(false))
      Notification({ status: 200, message: ACTIONS_PAGE_SAGA.VERIFY_BY_SUPERINTENDENT_UPDATE_SUCCESS })
    } else {
      action.callback()
      yield put(actions.setIsLoading(false))
      Notification({ status: 400, message: ACTIONS_PAGE_SAGA.ERROR_IN_UPDATING_VERIFY_BY_SUPERINTENDENT })
    }
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback()
  }
}

/** updateReassignFailedInspection API **/
function* updateReassignFailedInspectionRequest(action) {
  try {
    yield put(actions.setIsLoading(true))
    const response = yield call(apiServices.updateReassignFailedInspection, action.payload)
    action.callback(response?.data)
    yield put(actions.setIsLoading(false))
    Notification({ status: 200, message: ACTIONS_PAGE_SAGA.UPDATE_REASSIGN_FAILED_INSPECTION_SUCCESS })
  } catch (error) {
    yield put(actions.setIsLoading(false))
    action.callback()
  }
}

/**  getActionListVM API **/
function* getActionListVMRequest(action) {
  try {
    const response = yield call(apiServices.getActionListVM)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getReassignFailedInspectionContact API **/
function* getReassignFailedInspectionContactRequest(action) {
  try {
    const response = yield call(apiServices.getReassignFailedInspectionContact, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getTradeContactToReassign API **/
function* getTradeContactToReassignRequest(action) {
  try {
    const response = yield call(apiServices.getTradeContactToReassign, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getActionSiteDDLList API **/
function* getActionSiteDDLListRequest(action) {
  try {
    const response = yield call(apiServices.getActionSiteListDDL, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getInspectionDetail API **/
function* getInspectionDetailRequest(action) {
  try {
    const response = yield call(apiServices.getInspectionDetail, action.payload)
    if (response?.data) {
      action.callback({ ...response.data, pageSize: action.payload.pageSize })
    } else {
      action.callback()
    }
  } catch (error) {
    action.callback()
  }
}

/** getActionUnitList API **/
function* getActionUnitListRequest(action) {
  try {
    const response = yield call(apiServices.getActionUnitListDDL, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getBuilderListDDL API **/
function* getBuilderListDDLRequest(action) {
  try {
    const response = yield call(apiServices.getBuilderListDDL, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

/** getEntityListDDL API **/
function* getEntityListDDLRequest(action) {
  try {
    const response = yield call(apiServices.getEntityListDDL)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

export function* actionsPageSagas() {
  yield takeLatest(actions.GET_ACTION_LIST_REQUEST, getActionListRequest)
  yield takeLatest(actions.GET_ACTION_DETAIL_REQUEST, getActionDetailRequest)
  yield takeLatest(actions.UPDATE_ACCEPT_BY_TRADE_REQUEST, updateAcceptByTradeRequest)
  yield takeLatest(actions.UPDATE_TRADE_ACTION_REASSIGN, updateTradeActionReassignRequest)
  yield takeLatest(actions.CONFIRM_SITE_VISIT_REQUEST, createConfirmSiteVisitRequest)
  yield takeLatest(actions.UPDATE_VERIFY_BY_SUPERINTENDENT_REQUEST, updateVerifyBySuperintendentRequest)
  yield takeLatest(actions.UPDATE_REASSIGN_FAILED_INSPECTION_REQUEST, updateReassignFailedInspectionRequest)
  yield takeLatest(actions.GET_ACTION_LIST_VM_REQUEST, getActionListVMRequest)
  yield takeLatest(actions.GET_REASSIGN_FAILED_INSPECTION_CONTACT_REQUEST, getReassignFailedInspectionContactRequest)
  yield takeLatest(actions.GET_TRADE_CONTACT_TO_REASSIGN, getTradeContactToReassignRequest)
  yield takeLatest(actions.GET_ACTION_SITE_LIST_DDL_REQUEST, getActionSiteDDLListRequest)
  yield takeLatest(actions.GET_INSPECTION_DETAIL_REQUEST, getInspectionDetailRequest)
  yield takeLatest(actions.GET_ACTION_UNIT_LIST_DDL_REQUEST, getActionUnitListRequest)
  yield takeLatest(actions.GET_BUILDER_LIST_DDL_REQUEST, getBuilderListDDLRequest)
  yield takeLatest(actions.GET_ENTITY_LIST_DDL_REQUEST, getEntityListDDLRequest)
}

export default actionsPageSagas
