import React, { useCallback, useEffect, useState } from "react";
import { Col, Input, Row } from "reactstrap";

import { LANGUAGE_CONSTANTS } from "shared/language-constants";
import { getLocaleMessage } from "utility/Utils";
import { CustomLabel } from "views/components";
import localeMessageWrapper from "views/components/locale-message";

const { TRADE_CORRECTION } = LANGUAGE_CONSTANTS;

const AddNoteCard = ({ intl, value, isTradeEditMode }) => {
  const [note, setNote] = useState("");

  useEffect(() => {
    setNote(value || "");
  }, [value]);

  useEffect(() => {
    if (!isTradeEditMode) {
      setNote(value);
    }
  }, [isTradeEditMode]);

  const handleChangeNote = useCallback(() => {}, []);

  return (
    <Row>
      <Col md={4}>
        <div className={"additional-note disabled"}>
          <div className="d-flex align-items-center justify-content-between">
            <CustomLabel title={TRADE_CORRECTION.ATTACH_NOTE_FOR_TRADE} />
          </div>
          <Input
            type="textarea"
            placeholder={getLocaleMessage(
              intl,
              TRADE_CORRECTION.NOTE_PLACEHOLDER
            )}
            disabled
            rows="5"
            value={note}
            onChange={handleChangeNote}
          />
        </div>
      </Col>
    </Row>
  );
};

export default localeMessageWrapper(AddNoteCard);
