import { toast } from "react-toastify";
import PropTypes from "prop-types";

const ActionStatusToast = ({ content = "", title = "" }) => {
  return (
    <div>
      <h6>
        <strong>{title}!</strong>
      </h6>
      <span role="img" aria-label="toast-text">
        {content}
      </span>
    </div>
  );
};

const Notification = ({ status, message = "" }) => {
  if (status === 200) {
    toast.success(<ActionStatusToast content={message} title={"Success"} />, {
      toastId: message,
    });
  } else if (status === 304) {
    toast.warn(<ActionStatusToast content={message} title={"Warning"} />, {
      toastId: message,
    });
  } else {
    toast.error(<ActionStatusToast content={message} title={"Error"} />, {
      toastId: message,
    });
  }
};

ActionStatusToast.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

Notification.propTypes = {
  status: PropTypes.number,
  message: PropTypes.string
};

export default Notification;
