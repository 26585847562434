import { getDataApi } from "services/api"
import config from "../../config"

/**
 * @method getReassignFailedInspectionContact
 *
 */
export const getReassignFailedInspectionContact = (data) => {
  const { url } = config.getReassignFailedInspectionContact
  return getDataApi(url(data))
}

export default getReassignFailedInspectionContact
