import classnames from "classnames";
import React, { useCallback, useState, useMemo } from "react";
import { Lightbox } from "react-modal-image";
import ReactJson from "react-json-view";

import { LANGUAGE_CONSTANTS } from "shared/language-constants";
import { getLocaleMessage, getParsedJSONDetails } from "utility/Utils";
import { CustomLabel } from "views/components";
import localeMessageWrapper from "views/components/locale-message";

const ViewNoteCard = ({ intl, key, data }) => {
  const [zoomImageUrl, setZoomImageUrl] = useState(null);
  const [isShowJSONView, setIsShowJSONView] = useState(false);

  //Note: To convert string json data to object json data for showing data using json viewer
  const { parsedJSONData, isInvalidJSONData } = useMemo(() => {
    return getParsedJSONDetails({ details: data.note, isShowJSONView });
  }, [isShowJSONView, data]);

  const handleToggleJSONView = useCallback((data) => {
    setIsShowJSONView(data);
  }, []);

  const handleZoomImage = useCallback((fileURL) => {
    setZoomImageUrl(fileURL);
  }, []);

  const handleCloseImageModal = useCallback(() => {
    setZoomImageUrl(null);
  }, []);

  return (
    <>
      <div key={key}>
        <div className="json-field-tabs">
          <button
            type="button"
            className={classnames("waves-effect btn json-tab-btn", {
              "primary-text-btn": !isShowJSONView,
            })}
            onClick={() => handleToggleJSONView(false)}
          >
            {getLocaleMessage(intl, LANGUAGE_CONSTANTS.TEXT)}
          </button>
          <button
            type="button"
            className={classnames("waves-effect btn json-tab-btn", {
              "primary-text-btn": isShowJSONView,
            })}
            onClick={() => handleToggleJSONView(true)}
          >
            {getLocaleMessage(intl, LANGUAGE_CONSTANTS.VIEW_JSON_OBJECT)}
          </button>
        </div>
        {isShowJSONView ? (
          !data?.note?.length || isInvalidJSONData ? (
            <CustomLabel
              title={LANGUAGE_CONSTANTS.JSON_DATA_VIEW_PLACEHOLDER}
            />
          ) : (
            <ReactJson
              src={parsedJSONData}
              collapsed={1}
              name={null}
              quotesOnKeys={false}
              enableClipboard={false}
              displayObjectSize={false}
              displayDataTypes={false}
            />
          )
        ) : (
          <div className="additional-note mt-0">
            <div className="additional-note-header">
              <span className="additional-note-title">
                {getLocaleMessage(intl, LANGUAGE_CONSTANTS.ADDITIONAL_NOTE)}
              </span>
            </div>
            <p>{data.note}</p>
          </div>
        )}

        {!!data.imageCollectionDtos?.length && (
          <div className="additional-note-imgs">
            {data.imageCollectionDtos.map((media, index) => (
              <div key={`media_${index}`} className="add-note-photo">
                <img
                  src={media.imageUrl}
                  onClick={() => handleZoomImage(media.imageUrl)}
                />
              </div>
            ))}
          </div>
        )}
      </div>
      {zoomImageUrl && (
        <Lightbox
          small={zoomImageUrl}
          large={zoomImageUrl}
          hideDownload={true}
          onClose={handleCloseImageModal}
        />
      )}
    </>
  );
};

export default localeMessageWrapper(ViewNoteCard);
