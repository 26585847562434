import React, { useCallback } from "react"
import { ChevronUp, ChevronDown } from 'react-feather'
import { getLocaleMessage } from "utility/Utils"

const AccordionComponent = ({ intl, title, children, accordianExpanded = [], index, onAccordianExpanded = () => { } }) => {

  const handleToggleAccordion = useCallback(() => {
    onAccordianExpanded(index)
  }, [index, onAccordianExpanded])

  return (
    <>
      <button
        type="button"
        className="waves-effect btn advanced-btn more-less-btn"
        onClick={handleToggleAccordion}
      >
        <span>{getLocaleMessage(intl, title)}</span>
        {(accordianExpanded.includes(index)) ? <ChevronUp /> : <ChevronDown />}
      </button>
      {accordianExpanded.includes(index) && children}
    </>
  )
}
export default AccordionComponent
