import cx from "classnames"
import React, { useState, useCallback, useMemo } from "react"

import { map, person, note, calendar } from "assets/images/actions"
import { LANGUAGE_CONSTANTS } from "shared/language-constants"
import { getLocaleMessage, getFormattedDate } from "utility/Utils"
import { ImageFullViewModal } from "views/components"
import localeMessageWrapper from "views/components/locale-message"

const { VIEW_ALL, N_A } = LANGUAGE_CONSTANTS

const Details = ({
  intl,
  isShowFullActivityName,
  isShowFullNotes,
  onShowFullActivityName,
  onShowFullNotes,
  item: {
    taskOrActivityName,
    siteName,
    createdDate,
    assignee,
    responseImages = null,
    responseNote = ""
  }
}) => {
  const [isShowImageFullView, setShowImageFullView] = useState(false)
  const [selectedImageList, setSelectedImageList] = useState([])

  const handleToggleImageFullView = useCallback(
    (images) => {
      setShowImageFullView(!isShowImageFullView)
      if (images) {
        setSelectedImageList(images)
      }
    },
    [isShowImageFullView]
  )

  const imageList = useMemo(() => {
    if (!responseImages) {
      return []
    }
    return responseImages.split(",")
  }, [responseImages])

  const imageListLength = useMemo(() => imageList.length, [imageList])

  return (
    <>
      <div className="d-flex align-items-start d-flex justify-content-between mb-2">
        <h4 className={cx("action-type-name", { expanded: isShowFullActivityName })} onClick={onShowFullActivityName}>
          {taskOrActivityName}
        </h4>
        <span className="task-date"><img src={calendar} />{getFormattedDate(createdDate, "M/DD")}</span>
      </div>
      <div className={cx("failed-activity-outerbox", { "show-image": !!imageListLength })}>
        {!!imageListLength && <div className="failed-activity-outerbox-img" onClick={() => handleToggleImageFullView(imageList)}>
          <img src={imageList[0]} alt="" />
          {imageListLength > 1 && <span className="view-all">{getLocaleMessage(intl, VIEW_ALL)}</span>}
        </div>}
        <div className="failed-activity-content">
          <p className="task-map"> <img src={map} />
            {siteName}
          </p>
          <p className="task-map">
            <img src={person} /> {assignee}
          </p>
          <p className="task-note-discription"> <img src={note} />
            <span className={cx("task-note", { expanded: isShowFullNotes })} onClick={onShowFullNotes}>
              {responseNote || getLocaleMessage(intl, N_A)}
            </span></p>
        </div>
      </div>
      <ImageFullViewModal
        isOpen={isShowImageFullView}
        images={selectedImageList}
        onToggle={handleToggleImageFullView}
      />
    </>
  )
}

export default localeMessageWrapper(Details)
