import moment from "moment"
import React, { useCallback, useMemo, useState } from "react"
import { Row, Col } from "reactstrap"

import { LANGUAGE_CONSTANTS } from "shared/language-constants"
import { getLocaleMessage } from "utility/Utils"
import { ImageFullViewModal } from "views/components"

const { ACTIONS_PAGE } = LANGUAGE_CONSTANTS

const AuditTrailItem = ({ intl, data: { trailDetails } }) => {
  const [isShowImageFullView, setShowImageFullView] = useState(false)
  const [selectedImageList, setSelectedImageList] = useState([])

  const handleToggleImageFullView = useCallback(
    (images) => {
      setShowImageFullView(!isShowImageFullView)
      if (images) {
        setSelectedImageList(images)
      }
    },
    [isShowImageFullView]
  )

  const getFormattedDate = useCallback((date) => !!date ? moment(date).format('MMMM D YYYY, hh:mm a') : '', [])

  return (
    <>
      {trailDetails.map((detail, index) => {
        const { auditTrailId, files, note } = detail
        return (
          <div className="audit-trail" key={`trail-item-${auditTrailId}-${index}`}>
            <Row>
              <Col xs={12} sm={4} md={2}>
                {!!files?.length && (
                  <>
                    <img
                      className="w-100"
                      src={files[0].fileURL}
                      alt="auditTrail"
                      onClick={() => handleToggleImageFullView(files)}
                    />
                    <div
                      className="multiple-imgs"
                      onClick={() => handleToggleImageFullView(files)}
                    >
                      {files.length > 1 && (
                        <img
                          className="w-100"
                          src={files[1].fileURL}
                          alt="auditTrail"
                        />
                      )}
                      {files.length > 2 && <span>+{files.length - 2}</span>}
                    </div>
                  </>
                )}
              </Col>
              <Col xs={12} sm={8} md={10}>
                <div className="audit-trail-detail">
                  <h6 className="audit-trail-item">
                    <span>{getLocaleMessage(intl, ACTIONS_PAGE.CREATED)}</span>
                    {getFormattedDate(note.createdTime)}
                  </h6>
                  <h6 className="audit-trail-item">
                    <span>
                      {getLocaleMessage(intl, ACTIONS_PAGE.SUBMITTER)}
                    </span>
                    {note.submitterName}
                  </h6>
                  <h6 className="audit-trail-item">
                    <span>
                      {getLocaleMessage(intl, ACTIONS_PAGE.REASON_FOR_NOT_READY)}
                    </span>
                    {note.noteReasonName || 'N/A'}
                  </h6>
                  <p>{note.noteText}</p>
                </div>
              </Col>
            </Row>
          </div>
        )
      })}
      <ImageFullViewModal
        isOpen={isShowImageFullView}
        images={selectedImageList}
        onToggle={handleToggleImageFullView}
      />
    </>
  )
}

export default AuditTrailItem
