export const GET_ACTION_LIST_REQUEST = 'GET_ACTION_LIST_REQUEST'
export const GET_ACTION_DETAIL_REQUEST = 'GET_ACTION_DETAIL_REQUEST'
export const UPDATE_ACCEPT_BY_TRADE_REQUEST = 'UPDATE_ACCEPT_BY_TRADE_REQUEST'
export const UPDATE_TRADE_ACTION_REASSIGN = 'UPDATE_TRADE_ACTION_REASSIGN'
export const CONFIRM_SITE_VISIT_REQUEST = 'CONFIRM_SITE_VISIT_REQUEST'
export const UPDATE_VERIFY_BY_SUPERINTENDENT_REQUEST = 'UPDATE_VERIFY_BY_SUPERINTENDENT_REQUEST'
export const UPDATE_REASSIGN_FAILED_INSPECTION_REQUEST = 'UPDATE_REASSIGN_FAILED_INSPECTION_REQUEST'
export const GET_ACTION_LIST_VM_REQUEST = 'GET_ACTION_LIST_VM_REQUEST'
export const GET_REASSIGN_FAILED_INSPECTION_CONTACT_REQUEST = 'GET_REASSIGN_FAILED_INSPECTION_CONTACT_REQUEST'
export const GET_TRADE_CONTACT_TO_REASSIGN = 'GET_TRADE_CONTACT_TO_REASSIGN'
export const GET_ACTION_SITE_LIST_DDL_REQUEST = 'GET_ACTION_SITE_LIST_DDL_REQUEST'
export const GET_INSPECTION_DETAIL_REQUEST = 'GET_INSPECTION_DETAIL_REQUEST'
export const GET_ACTION_UNIT_LIST_DDL_REQUEST = 'GET_ACTION_UNIT_LIST_DDL_REQUEST'
export const GET_BUILDER_LIST_DDL_REQUEST = "GET_BUILDER_LIST_DDL_REQUEST"
export const GET_ENTITY_LIST_DDL_REQUEST = "GET_ENTITY_LIST_DDL_REQUEST"

/****************************************************/
/** GET_ACTION_LIST_REQUEST **/
/****************************************************/

/**
 * @method getActionListRequest :  To get list of actions for card view
 *
 */
export const getActionListRequest = (payload, callback = () => { }) => {
  return {
    type: GET_ACTION_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_ACTION_DETAIL_REQUEST **/
/****************************************************/

/**
 * @method getActionDetailRequest : To get detail of action
 *
 */
export const getActionDetailRequest = (payload, callback = () => { }) => {
  return {
    type: GET_ACTION_DETAIL_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_ACCEPT_BY_TRADE_REQUEST **/
/****************************************************/

/**
 * @method updateAcceptByTradeRequest :  To update failed inspection correction action for correction accept confirmation for some activity
 *
 */
export const updateAcceptByTradeRequest = (payload, callback = () => { }) => {
  return {
    type: UPDATE_ACCEPT_BY_TRADE_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_TRADE_ACTION_REASSIGN **/
/****************************************************/

/**
 * @method updateTradeActionReassignRequest:  To update the actions trade contact
 *
 */
export const updateTradeActionReassignRequest = (payload, callback = () => { }) => {
  return {
    type: UPDATE_TRADE_ACTION_REASSIGN,
    payload,
    callback
  }
}

/****************************************************/
/** CONFIRM_SITE_VISIT_REQUEST **/
/****************************************************/

/**
 * @method createConfirmSiteVisitRequest :  To confirm action site visit
 *
 */
export const createConfirmSiteVisitRequest = (payload, callback = () => { }) => {
  return {
    type: CONFIRM_SITE_VISIT_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_VERIFY_BY_SUPERINTENDENT_REQUEST **/
/****************************************************/

/**
 * @method updateVerifyBySuperintendentRequest :  To update verify by superintendent action for accept/reject verification for some activity
 *
 */
export const updateVerifyBySuperintendentRequest = (payload, callback = () => { }) => {
  return {
    type: UPDATE_VERIFY_BY_SUPERINTENDENT_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** UPDATE_REASSIGN_FAILED_INSPECTION_REQUEST **/
/****************************************************/

/**
 * @method updateReassignFailedInspectionRequest:  To update the reassign failed inspection correction action contact
 *
 */
export const updateReassignFailedInspectionRequest = (payload, callback = () => { }) => {
  return {
    type: UPDATE_REASSIGN_FAILED_INSPECTION_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_ACTION_LIST_VM_REQUEST **/
/****************************************************/

/**
 * @method getActionListVMRequest :  To get VM for action list
 *
 */
export const getActionListVMRequest = (callback = () => { }) => {
  return {
    type: GET_ACTION_LIST_VM_REQUEST,
    callback
  }
}

/**
 * @method getReassignFailedInspectionContactRequest:  To get the reassign failed inspection correction action contacts list
 *
 */
export const getReassignFailedInspectionContactRequest = (payload, callback = () => { }) => {
  return {
    type: GET_REASSIGN_FAILED_INSPECTION_CONTACT_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_TRADE_CONTACT_TO_REASSIGN **/
/****************************************************/

/**
 * @method getTradeContactToReassignRequest:  To get the actions trade contacts list
 *
 */
export const getTradeContactToReassignRequest = (payload, callback = () => { }) => {
  return {
    type: GET_TRADE_CONTACT_TO_REASSIGN,
    payload,
    callback
  }
}

/****************************************************/
/** GET_ACTION_SITE_LIST_DDL_REQUEST **/
/****************************************************/

/**
 * @method getActionSiteListRequest :  To get list of sites for actions
 *
 */
export const getActionSiteListRequest = (payload, callback = () => { }) => {
  return {
    type: GET_ACTION_SITE_LIST_DDL_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_INSPECTION_DETAIL_REQUEST **/
/****************************************************/

/**
 * @method getInspectionDetailRequest :  To get the list of the inspection detal
 *
 */
export const getInspectionDetailRequest = (payload, callback = () => { }) => {
  return {
    type: GET_INSPECTION_DETAIL_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_ACTION_UNIT_LIST_DDL_REQUEST **/
/****************************************************/

/**
 * @method getActionUnitListRequest :  To get list of units for actions
 *
 */
export const getActionUnitListRequest = (payload, callback = () => { }) => {
  return {
    type: GET_ACTION_UNIT_LIST_DDL_REQUEST,
    payload,
    callback
  }
}  

/****************************************************/
/** GET_BUILDER_LIST_DDL_REQUEST **/
/****************************************************/

/**
 * @method getBuilderListRequest :  To get list of builders for actions
 *
 */
export const getBuilderListRequest = (payload, callback = () => { }) => {
  return {
    type: GET_BUILDER_LIST_DDL_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_ENTITY_LIST_DDL_REQUEST **/
/****************************************************/

/**
 * @method getEntityListRequest :  To get list of entity for actions
 *
 */
export const getEntityListRequest = (callback = () => { }) => {
  return {
    type: GET_ENTITY_LIST_DDL_REQUEST,
    callback
  }
}