import cx from "classnames"
import moment from "moment"
import React, { useState, useMemo, useCallback } from "react"
import { Container } from "reactstrap"
import { ChevronUp, ChevronDown } from 'react-feather'
import { useDispatch, useSelector } from "react-redux"

import { LANGUAGE_CONSTANTS } from "shared/language-constants"
import * as actions from "store/actions"
import { getLocaleMessage } from "utility/Utils.js"
import { OverviewSectionTabs } from "views/components"
import localeMessageWrapper from "views/components/locale-message"
import ActionsView from "./ActionsView"
import Details from "../ActionDetails"
import InspectionDetails from "./inspection-details"
import { SECTION_TAB_TYPES, confirmSiteVisitDetailSectionTabs } from '../config'
import { AuditTrial } from "../../components"
import { ACTION_TYPES } from "../../config"
import { getDateTimeFormat, getStartTimeOptions } from "../../../../helpers"

const { ACTIONS_PAGE, ACTIONS_DESCRIPTION } = LANGUAGE_CONSTANTS

const getActionsDetailSectionComponent = (activeSectionTabId) => {
  switch (activeSectionTabId) {
    case SECTION_TAB_TYPES.INSPECTION_DETAILS:
      return InspectionDetails
    case SECTION_TAB_TYPES.AUDIT_TRAIL:
      return AuditTrial
    default:
      return InspectionDetails
  }
}

const ConfirmSiteVisit = ({
  intl,
  item,
  isToggleDetails,
  onGoBack = () => { },
  onToggleDetails = () => { }
}) => {
  const dispatch = useDispatch()
  const { isMobileView } = useSelector((state) => state.appConfig)

  const [selectedActionType, setSelectedActionType] = useState("")
  const [isShowSubmitActionView, setShowSubmitActionView] = useState(false)
  const [confirmSiteReadyDate, setConfirmSiteReadyDate] = useState(null)
  const [confirmSiteReadyTime, setConfirmSiteReadyTime] = useState(null)
  const [activeSectionTabId, setActiveSectionTabId] = useState(SECTION_TAB_TYPES.INSPECTION_DETAILS)

  const handleGoToOnSubmittingAction = useCallback(() => {
    onGoBack()
  }, [onGoBack])

  const timeOptions = useMemo(() => {
    return getStartTimeOptions(confirmSiteReadyDate || new Date())
  }, [])

  const assignedStartTime = useMemo(() => {
    const temp = confirmSiteReadyTime
      ? moment.utc(confirmSiteReadyTime).format("LT")
      : ""
    return temp
      ? timeOptions.find((option) => option.text === temp)?.value || ""
      : ""
  }, [confirmSiteReadyTime])

  const handleResetState = useCallback(() => {
    setSelectedActionType("")
    setShowSubmitActionView(false)
    setConfirmSiteReadyDate(null)
    setConfirmSiteReadyTime(null)
  }, [])

  const handleChangeDetails = useCallback((key, value) => {
    if (key === "confirmSiteDate") {
      setConfirmSiteReadyDate(value)
    } else {
      setConfirmSiteReadyTime(value)
    }
  }, [])

  const handleConfirmAction = useCallback(() => {
    if (selectedActionType === ACTION_TYPES.CONFIRM_SITE_VISIT.NOT_READY) {
      setShowSubmitActionView(true)
    } else {
      const payload = {
        observationTaskId: item.observationTaskId,
        isConfirmSiteVisit: true
      }
      dispatch(actions.createConfirmSiteVisitRequest(payload, (res) => {
        if (res) {
          handleGoToOnSubmittingAction()
          handleResetState()
        }
      }))
    }
  }, [
    item,
    selectedActionType,
    handleGoToOnSubmittingAction,
    handleResetState
  ])

  const handleShowConfirmActionView = useCallback((value) => {
    setSelectedActionType(value || "")
    setShowSubmitActionView(false)
  }, [])

  const handleSubmitAndClose = useCallback(() => {
    const payload = {
      observationTaskId: item.observationTaskId,
      isConfirmSiteVisit: false
    }

    if (confirmSiteReadyDate) {
      payload.confirmSiteReadyDate = getDateTimeFormat(confirmSiteReadyDate)
    }

    if (confirmSiteReadyTime) {
      payload.confirmSiteReadyTime = moment.utc(confirmSiteReadyTime).format()
    }

    dispatch(actions.createConfirmSiteVisitRequest(payload, (res) => {
      if (res) {
        handleGoToOnSubmittingAction()
        handleResetState()
      }
    }))
  }, [
    confirmSiteReadyDate,
    confirmSiteReadyTime,
    item,
    handleGoToOnSubmittingAction,
    handleResetState
  ])

  const handleSelectTab = useCallback((activeTabId) => {
    setActiveSectionTabId(activeTabId)
  }, [])

  const Component = getActionsDetailSectionComponent(activeSectionTabId)

  return (
    <div className="action-detail-outer">
      <Container>
        <div className="details-view">

          <div className="action-detail-list">
            <div
              className={cx("", {
                "action-header": isMobileView
              })}>

              <h6 className="action-detail-title">
                {item.taskOrActivityName}
              </h6>

              {isMobileView && <button
                type="button"
                className="advanced-btn"
                onClick={onToggleDetails}
              >
                {isToggleDetails ? <ChevronUp /> : <ChevronDown />}
              </button>}

            </div>
            <p className="action-detail-sub-title">
              {getLocaleMessage(intl, ACTIONS_PAGE.CONFIRM_SITE_VISIT)}
            </p>
            <p>
              {getLocaleMessage(intl, ACTIONS_DESCRIPTION)}
            </p>
          </div>

          {isMobileView ? isToggleDetails ? <Details item={item} /> : "" : <Details item={item} />}

          <ActionsView
            confirmSiteReadyDate={confirmSiteReadyDate}
            isShowSubmitActionView={isShowSubmitActionView}
            assignedStartTime={assignedStartTime}
            selectedActionType={selectedActionType}
            actionStatus={item.actionStatus}
            timeOptions={timeOptions}
            onChangeDetails={handleChangeDetails}
            onConfirmAction={handleConfirmAction}
            onShowConfirmActionView={handleShowConfirmActionView}
            onSubmitAndClose={handleSubmitAndClose}
          />
        </div>
      </Container>
      <div className="section-tabs-border">
        <Container>
          <div className="mb-1 mt-1">
            <OverviewSectionTabs
              activeTabId={activeSectionTabId}
              tabsList={confirmSiteVisitDetailSectionTabs}
              isBorderBottomRequired={false}
              onChangeTab={handleSelectTab}
            />
          </div>
          {!!Component && <Component
            actionId={item.actionId}
            observationTaskId={item.observationTaskId}
          />}
        </Container>
      </div>
    </div>
  )
}

export default localeMessageWrapper(ConfirmSiteVisit)
