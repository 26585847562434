import React from "react";

import { LANGUAGE_CONSTANTS } from "shared/language-constants";
import { DatePicker } from "views/components";

const { RESPONSES_PLACEHOLDER_TEXT } = LANGUAGE_CONSTANTS;

const DateResponse = ({ value, responseClassName }) => {
  return (
    <DatePicker
      id="dateResponse"
      placeholder={RESPONSES_PLACEHOLDER_TEXT.SELECT_DATE}
      responseClassName={responseClassName}
      isDisabled
      isClearable={!!value}
      value={value}
      onChange={() => {}}
    />
  );
};

export default DateResponse;
