import { getDataApi } from "services/api"
import config from "../config"

export const getAuditTrailModal = async (data) => {
  const { url } = config.getAuditTrailModal

  return await getDataApi(url(data))
}

export default getAuditTrailModal
