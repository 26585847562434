export const GET_AUDIT_TRAIL_MODAL_DATA = "GET_AUDIT_TRAIL_MODAL_DATA"

/****************************************************/
/** GET_AUDIT_TRAIL_MODAL_DATA **/
/****************************************************/

/**
 * @method getAuditTrailModalData : to get audit trail modal data
 */

export const getAuditTrailModalData = (payload, callback) => ({
  type: GET_AUDIT_TRAIL_MODAL_DATA,
  payload,
  callback
})