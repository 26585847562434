import React, { useCallback } from "react"
import { Modal, ModalHeader, ModalBody } from "reactstrap"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import { SLIDER_CONFIG } from "./config"
import SideModalCloseButton from "../side-modal-close-button"

const ImageFullViewModal = ({
  isOpen = false,
  images = [],
  onToggle = () => { }
}) => {
  const handleCloseFullView = useCallback(() => {
    onToggle()
  }, [onToggle])

  return (
    <Modal
      className="image-full-view-modal"
      isOpen={isOpen}
      size="xl"
      centered
      toggle={handleCloseFullView}
    >
      <ModalHeader
        className="image-view-header"
        close={<SideModalCloseButton onClose={handleCloseFullView} />}
        tag="div"
        toggle={handleCloseFullView}
      />
      <ModalBody>
        <div>
          <Slider {...SLIDER_CONFIG}>
            {images.map((item, index) => {
              return (
                <div className="slide-inner" key={`image-item-${index}`}>
                  <img src={item.fileURL ? item.fileURL : item.imageUrl ? item.imageUrl : item} alt="image-view" />
                </div>
              )
            })}
          </Slider>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ImageFullViewModal
