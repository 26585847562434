import { call, takeLatest } from "redux-saga/effects"

import * as apiServices from "services/api/correction-details"
import * as actions from "../../actions"

/** getCorrectionDetails API **/
function* getCorrectionDetailsRequest(action) {
  try {
    const response = yield call(apiServices.getCorrectionDetails, action.payload)
    action.callback(response?.data)
  } catch (error) {
    action.callback()
  }
}

export function* correctionDetailsSagas() {
  yield takeLatest(actions.GET_CORRECTION_DETAILS_REQUEST, getCorrectionDetailsRequest)
}

export default correctionDetailsSagas