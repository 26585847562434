import React, { useMemo, useState, useCallback, useEffect } from "react"
import { Col, Row } from "reactstrap"
import { useDispatch } from "react-redux"

import { DEFAULT_TABLE_PAGE_SIZE } from 'shared/constants'
import { LANGUAGE_CONSTANTS } from "shared/language-constants"
import * as actions from "store/actions"
import { getLocaleMessage } from "utility/Utils"
import { MessageCard } from "views/components"
import localeMessageWrapper from "views/components/locale-message"
import ActivityDetailItem from "./activity-detail-item"
import { activityDetailCardConfig } from "./config"

const {
  LOADER
} = LANGUAGE_CONSTANTS

const ActionsActivityDetails = ({ intl, actionId }) => {
  const [activityDetail, setActivityDetail] = useState({})
  const [isFetching, setFetching] = useState(true)

  const dispatch = useDispatch()

  const configKeys = useMemo(() => {
    return Object.keys(activityDetailCardConfig || {})
  }, [])

  const handleFetchActivityData = useCallback((data) => {
    if (!actionId) {
      setFetching(false)
      return
    }

    const {
      page = 1,
      size = DEFAULT_TABLE_PAGE_SIZE
    } = data || {}

    const payload = {
      actionId,
      onlyFailedActivity: true,
      pageNumber: page,
      pageSize: size
    }

    dispatch(actions.getChecklistChildrenListRequest(payload, (res) => {
      if (res && res.items.length > 0) {
        const firstCheckListChildrenItem = res.items[0];
        const { checkpointId, checkpointVersionId, activityTypeId } = firstCheckListChildrenItem;
        dispatch(
          actions.getCheckpointChildDetailRequest(
            { checkpointId, checkpointVersionId, activityTypeId },
            (data) => {
              if (data) {
                setActivityDetail({
                  riskLevel: data.riskLevel,
                  assembly: data.assembly,
                  assemblyType: data.assemblyTypes?.join(", ") || "",
                  component: data.components?.join(", ") || "",
                  subComponent: data.subComponents?.join(", ") || "",
                  manufacturer: data.manufacturer,
                  brand: data.brand
                })
              }
              setFetching(false)
            }
          )
        )
      } else {
        setFetching(false)
      }
    }))
  }, [activityDetail])

  useEffect(() => {
    handleFetchActivityData({ page: 1 })
  }, [])

  if (!actionId) {
    return null
  }

  if (isFetching) {
    return <MessageCard messageText={getLocaleMessage(intl, LOADER.LOADING)} />
  }

  return (
    <Row className="activity-detail-outer">
      {configKeys.map((x) => {
        const subConfig = activityDetailCardConfig[x]
        if (!subConfig?.columnConfig?.length) {
          return null
        }

        return (
          <Col
            key={`config_${x}`}
            md={subConfig.md}
          >
            <div>
              {(subConfig.columnConfig || []).map((item, index) => {
                return <ActivityDetailItem intl={intl} item={item} data={activityDetail} key={`activity_detail_item_${item.keyName}_${index}`} />
              })}
            </div>
          </Col>
        )
      })}
    </Row>
  )
}

export default localeMessageWrapper(ActionsActivityDetails)