export const GET_CHECKLIST_CHILDREN_LIST_REQUEST = 'GET_CHECKLIST_CHILDREN_LIST_REQUEST'
export const GET_CHECKPOINT_CHILD_DETAIL_REQUEST = 'GET_CHECKPOINT_CHILD_DETAIL_REQUEST'

/****************************************************/
/** GET_CHECKLIST_CHILDREN_LIST_REQUEST **/
/****************************************************/

/**
 * @method getChecklistChildrenListRequest :  To get checklist's children activities list for assign activities block
 *
 */
export const getChecklistChildrenListRequest = (payload, callback = () => { }) => {
  return {
    type: GET_CHECKLIST_CHILDREN_LIST_REQUEST,
    payload,
    callback
  }
}

/****************************************************/
/** GET_CHECKPOINT_CHILD_DETAIL_REQUEST **/
/****************************************************/

/** 
* @method getCheckpointChildDetailRequest :  To get checkpoint child detail work item
*
*/
export const getCheckpointChildDetailRequest = (payload, callback) => {
  return {
      type: GET_CHECKPOINT_CHILD_DETAIL_REQUEST,
      payload,
      callback
  }
}