import React from 'react'

import { LANGUAGE_CONSTANTS } from "shared/language-constants"
import { getFormattedDate } from "utility/Utils"
import { LocaleMessage } from 'views/components/locale-message'

const { ACTIONS_PAGE } = LANGUAGE_CONSTANTS

const CustomNotes = ({ item: {
  note,
  noteInsertedByName,
  noteInsertedDate
}, isShow }) => {

  if (!isShow) {
    return null
  }

  return (
    <div className='primary-notes'>
      <div className='additional-note mb-md-2'>
        <h5 className='title'>
          <LocaleMessage id={ACTIONS_PAGE.ATTACHED_NOTE} />
          <span>{!!noteInsertedDate && `${getFormattedDate(noteInsertedDate)} - `} {!!noteInsertedByName && noteInsertedByName} </span>
        </h5>
        <p className='note-details'>{note}</p>
      </div>
    </div>
  )
}

export default CustomNotes
