export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
]

const now = new Date().getUTCFullYear()
export const years = Array(now - (now - 43)).fill('').map((v, idx) => (now + 10) - idx)