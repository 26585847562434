/**
 * @constant ROUTES : To define app routes.
 */
export const ROUTES = {
  actionsOverview: "/actions",
  actionsDetails: "/actions/:id",
  notAuthorised: "/not-authorized",
}

export const SEARCH_HISTORY_LIST_PAGE_KEYS = {
  ACTIONS: "actions"
}

export const DEFAULT_VM_PAGE_SIZE = 10

export const LANGUAGE_TYPE = {
  ENGLISH: 0,
  SPANISH: 1
}

export const COUNTRY_CODE_TYPE = {
  ENGLISH: 'en',
  SPANISH: 'es'
}

export const DEFAULT_TABLE_PAGE_SIZE = 50

/**
 * @constant ACTIVITY_TYPE : To define value of activity types
 */
export const ACTIVITY_TYPE = {
  OBSERVATION: '1',
  TRAINING: '2'
}

export const ACTIVITY_COLLECTION_RESPONSE_TYPES = {
  BOOLEAN: 1,
  CHOICE_SINGLE_SELECT: 2,
  CHOICE_MULTI_SELECT: 3,
  DATE: 4,
  FREE_TEXT: 5,
  NUMERIC: 6,
  PHOTO: 7,
  DDL_SINGLE_SELECT: 8,
  DDL_MULTI_SELECT: 9,
  FORESITE_STANDARD: 10,
  BUTTON: 13,
  HIDDEN_FIELD: 11
}

export const ACTIVITY_COLLECTION_RESPONSE_KEYS = {
  BOOLEAN: 'optionAnswers',
  CHOICE_SELECT: 'optionAnswers',
  DDL: 'optionAnswers',
  DATE: 'dateSelected',
  FREE_TEXT: 'answerText',
  NUMERIC_SINGLE: 'singleNumber',
  NUMERIC_MIN_RANGE: 'minNumber',
  NUMERIC_MAX_RANGE: 'maxNumber',
  PHOTO: 'responseImages',
  FORESITE_STANDARD: 'optionAnswers',
  BUTTON: 'optionAnswers',
  OTHER: 'otherOption'
}

export const CHECKPOINT_NUMERIC_TYPES = {
  SINGLE_NUMBER: '1',
  NUMERIC_RANGE: '2'
}

export const CHECKPOINT_NUMERIC_VALIDATION_TYPES = {
  WHOLE_NUMBER: 1,
  DECIMAL_NUMBER: 2,
  PERCENTAGE_NUMBER: 3
}

export const GO_TO_LIST_TYPE = {
  SINGLE: [
    ACTIVITY_COLLECTION_RESPONSE_TYPES.BOOLEAN,
    ACTIVITY_COLLECTION_RESPONSE_TYPES.CHOICE_SINGLE_SELECT,
    ACTIVITY_COLLECTION_RESPONSE_TYPES.DATE,
    ACTIVITY_COLLECTION_RESPONSE_TYPES.FREE_TEXT,
    ACTIVITY_COLLECTION_RESPONSE_TYPES.PHOTO,
    ACTIVITY_COLLECTION_RESPONSE_TYPES.DDL_SINGLE_SELECT,
    ACTIVITY_COLLECTION_RESPONSE_TYPES.FORESITE_STANDARD,
    ACTIVITY_COLLECTION_RESPONSE_TYPES.BUTTON
  ],
  MULTIPLE: [
    ACTIVITY_COLLECTION_RESPONSE_TYPES.CHOICE_MULTI_SELECT,
    ACTIVITY_COLLECTION_RESPONSE_TYPES.DDL_MULTI_SELECT,
    ACTIVITY_COLLECTION_RESPONSE_TYPES.NUMERIC
  ]
}
