import cx from "classnames"
import React, { useMemo } from "react"
import { Input } from "reactstrap"

import { LANGUAGE_CONSTANTS } from "shared/language-constants"
import { getLocaleMessage } from "utility/Utils"
import localeMessageWrapper from "views/components/locale-message"
import ActionsTradeContactSelect from "views/pages/actions/actions-trade-contact-select"
import ActionButton from "../../../ActionButton"
import { ACTION_STATUS_VALUE_TYPES, ACTION_TYPES } from "../../../config"

const { ACTIONS_PAGE, BACK } = LANGUAGE_CONSTANTS

const ActionsView = ({
  actionId,
  actionStatus,
  assignedContact,
  intl,
  hasPhotos,
  isShowAddNoteView,
  isShowAssignContactView,
  isShowSubmitActionView,
  noteDetail,
  selectedActionType,
  selectedPhotos,
  onChangeAssignedContact = () => { },
  onChangeNoteDetail = () => { },
  onConfirmAction = () => { },
  onShowConfirmActionView = () => { },
  onSubmitAndClose = () => { },
  onToggleAddNote = () => { },
  onToggleAddPhoto = () => { },
  onViewMore = () => { }
}) => {

  const isDisabledView = useMemo(() => {
    return !((Object.keys(assignedContact).length))
  }, [assignedContact])

  if (!isShowSubmitActionView) {
    return (
      <div className="d-flex align-items-center correct-btns card-action-btn">
        {!selectedActionType ? (
          <>
            {actionStatus !== ACTION_STATUS_VALUE_TYPES.COMPLETED && <>
              <ActionButton
                color="success w-100"
                className="mr-1"
                labelLocaleMessageId={ACTIONS_PAGE.CORRECTED}
                type={ACTION_TYPES.FAILED_INSPECTION_CORRECTION.CORRECTED}
                onClick={onShowConfirmActionView}
              />
              <ActionButton
                color="danger"
                className="mr-1 to-capitalized w-100"
                labelLocaleMessageId={ACTIONS_PAGE.REASSIGN}
                type={ACTION_TYPES.FAILED_INSPECTION_CORRECTION.REASSIGN}
                onClick={onShowConfirmActionView}
              />
            </>}

            <ActionButton
              className="secondary-solid outline-button mr-1 w-100"
              labelLocaleMessageId={ACTIONS_PAGE.VIEW_MORE}
              isViewAction
              onClick={onViewMore}
            />

          </>
        ) : (
          <>
            {selectedActionType === ACTION_TYPES.FAILED_INSPECTION_CORRECTION.CORRECTED ? (
              <ActionButton
                color="success"
                className="mr-1 to-capitalized w-100"
                isConfirmAction
                labelLocaleMessageId={ACTIONS_PAGE.CORRECTED_ACTION}
                onClick={onConfirmAction}
              />
            ) : (
              <ActionButton
                color="danger"
                className="mr-1 to-capitalized w-100"
                isConfirmAction
                labelLocaleMessageId={ACTIONS_PAGE.REASSIGN_ACTION}
                onClick={onConfirmAction}
              />
            )}
            <ActionButton
              className="secondary-solid mr-1 w-100"
              labelLocaleMessageId={BACK}
              onClick={onShowConfirmActionView}
            />
          </>
        )}
      </div>
    )
  }

  if (isShowAssignContactView) {
    return (
      <div>
        <div>
          <ActionsTradeContactSelect actionId={actionId} value={assignedContact.contactId} onSelect={onChangeAssignedContact} />
        </div>
        <div className="card-action-btn mt-1">
          <ActionButton
            isDisabled={isDisabledView}
            isSubmitAction
            className={cx("btn-block w-100", { "grey-solid": isDisabledView, "common-button": !isDisabledView })}
            labelLocaleMessageId={ACTIONS_PAGE.SUBMIT_AND_CLOSE}
            onClick={onSubmitAndClose}
          />
        </div>
      </div>
    )
  }

  return (
    <div>
      {isShowAddNoteView && (
        <div className="note-field">
          <Input
            type="textarea"
            placeholder={getLocaleMessage(
              intl,
              ACTIONS_PAGE.ADD_NOTE_PLACEHOLDER
            )}
            value={noteDetail}
            onChange={onChangeNoteDetail}
          />
        </div>
      )}
      <div className="mb-1 mt-1 d-flex card-action-btn">
        <ActionButton
          className="outline-grey-button mr-1 w-100"
          labelLocaleMessageId={
            isShowAddNoteView ? ACTIONS_PAGE.HIDE_NOTE : ACTIONS_PAGE.ADD_NOTE
          }
          onClick={onToggleAddNote}
        />
        <ActionButton
          className="outline-grey-button  w-100"
          labelLocaleMessageId={
            hasPhotos ? ACTIONS_PAGE.VIEW_PHOTOS : selectedPhotos?.length ? ACTIONS_PAGE.ATTATCHED_PHOTOS : ACTIONS_PAGE.ADD_PHOTO
          }
          localeMessageValues={
            !!selectedPhotos?.length ? { count: selectedPhotos.length } : {}
          }
          onClick={onToggleAddPhoto}
        />
        <div className="w-100 ml-1">
          <ActionButton
            isSubmitAction
            className="block w-100 common-button"
            labelLocaleMessageId={ACTIONS_PAGE.SUBMIT_AND_CLOSE}
            onClick={onSubmitAndClose}
          />
        </div>
      </div>
    </div>
  )
}

export default localeMessageWrapper(ActionsView)
