import { postDataApi } from "services/api"
import config from "../../config"

/**
 * @method createConfirmSiteVisit
 * This api is called to confirm site visit for scheduling workflow
 * [Confirm Site Visit]
 * 
 * @param {object} data 
 * 
 * {
 *  observationTaskId: 0,
 *  isConfirmSiteVisit: bool,
 *  confirmSiteReadyDate: string,
 *  confirmSiteReadyTime: string
 * }
 * 
 */
export const createConfirmSiteVisit = async (data) => {
  const { url } = config.createConfirmSiteVisit
  return await postDataApi(url(data), {})
}

export default createConfirmSiteVisit
