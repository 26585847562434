import React, { useCallback, useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import { DEFAULT_VM_PAGE_SIZE } from "shared/constants"
import * as actions from "store/actions"
import { CustomLabel, CustomReactSelect } from "views/components"

const BuilderSearch = ({
  labelRequired = true,
  label,
  id,
  name,
  placeholder,
  value,
  entityId,
  isClearable = false,
  isMulti = false,
  isDisabled = false,
  valueType = "value",
  labelType = "text",
  getOptionLabel = (option) => option.text,
  getOptionValue = (option) => option.value,
  onSelect = () => { }
}) => {
  const dispatch = useDispatch()

  const [options, setOptions] = useState([])
  const [isResetCache, setResetCache] = useState(false)

  const handleLoadMoreOptions = useCallback((search, page, prevOptions) => {
    return new Promise((resolve, reject) => {
      const handleSearchResultCallback = (response) => {
        if (response) {
          const { items, hasNextPage } = response
          const listOptions = items.map((li) => {
            return {
              text: li.organizationName,
              value: li.organizationId
            }
          })
          setOptions(() =>
            !!prevOptions.length ? [...prevOptions, ...listOptions] : listOptions
          )
          resolve({
            optionList: listOptions,
            hasMore: hasNextPage
          })
        } else {
          resolve({
            optionList: [],
            hasMore: false
          })
        }
      }
      const payload = {
        organizationName: search,
        pageNumber: page,
        pageSize: DEFAULT_VM_PAGE_SIZE,
        entityId
      }

      dispatch(
        actions.getBuilderListRequest(payload, handleSearchResultCallback)
      )
    })
  }, [entityId])

  const handleSelect = useCallback(
    (selected) => {
      if (isMulti) {
        const lastElement = selected.length
          ? selected[selected.length - 1]
          : null
        if (!!lastElement && !lastElement.siteId) {
          onSelect([])
        } else {
          onSelect(selected)
        }
      } else {
        onSelect(selected)
      }
    },
    [onSelect]
  )

  useEffect(() => {
    const fn = () => {
      setTimeout(() => {
        setResetCache((prevState) => !prevState)
      }, [100])
    }
    fn()
  }, [])

  return (
    <>
      {labelRequired && <CustomLabel title={label} />}
      <CustomReactSelect
        additional={{
          page: 1,
        }}
        cacheUniqs={[isResetCache]}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        id={id}
        name={name}
        placeholder={placeholder}
        isClearable={isClearable}
        options={options}
        defaultOptions={options}
        isFormattedValue={!isMulti}
        value={value}
        isDisabled={isDisabled}
        valueType={valueType}
        lableType={labelType}
        hideSelectedOptions={false}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        onLoadOptions={handleLoadMoreOptions}
        onSelect={handleSelect}
      />
    </>
  )
}

export default BuilderSearch
